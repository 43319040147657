export const validateBarcode = (barcode: string, prefixList?: Array<string>) => {
    if (barcode.length === 13) {
        const prefix = barcode.substring(0, 2);
        if (!prefixList || prefixList.indexOf(prefix) !== -1) {
            const checkSum = barcode.split('').reduce((p, v, i) => {
                return i % 2 === 0 ? p + +v : p + 3 * +v;
            }, 0);

            if (checkSum % 10 === 0) {
                return true;
            }
        }
    }

    return false;
};
