import * as React from 'react';
import {connect} from 'react-redux';

import {
    getAreaAllEmployees,
    getAreaAllProdparts,
    getAreaAllPtables,
    getCurrentDump,
} from 'src/app/lanemanager/lane/store/thunks';
import {OCard, WrapCardsToRow, WrapFullContainer} from 'src/shared/style';
import {IsInRuleset} from 'src/global/authorization/grantSet';

import {DumpInfoConnected} from '../DumpInfo';
import {PalletsInfoConnected} from '../PalletsInfo';
import {ProdPartsInfoConnected} from '../ProdPartsInfo';

type DProps = {
    getCurrentDump(): void;
    getProdparts(): void;
    getEmployees(): void;
    getPtables(): void;
};

type Props = {
    lane : any
};

class Overview extends React.Component<DProps & Props> {
    private timer: any;

    componentDidMount() {
        this.props.getCurrentDump();
        this.props.getProdparts();
        this.props.getEmployees();
        this.props.getPtables();
        this.timer = setInterval(() => {
            this.props.getCurrentDump();
            this.props.getProdparts();
            this.props.getEmployees();
            this.props.getPtables();
        }, 30000);
    }

    componentDidUpdate(){
        this.props.getCurrentDump();
        this.props.getProdparts();
        this.props.getEmployees();
        this.props.getPtables();
        /*this.timer = setInterval(() => {
            this.props.getCurrentDump();
            this.props.getProdparts();
            this.props.getEmployees();
            this.props.getPtables();
        }, 30000);*/
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    public render() {
        const VIEW_ACCESS = IsInRuleset([
            'LANEMANAGER_COMMON_VIEW',
            'LANEMANAGER_COMMON_TOTAL',
            'LANEMANAGER_STATE_TOTAL',
            'LANEMANAGER_STATE_VIEW',
            'LANEMANAGER_PRODPARTS_VIEW',
            'LANEMANAGER_PRODPARTS_TOTAL',
            'LANEMANAGER_AREASHIFTS_TOTAL',
            'LANEMANAGER_AREASHIFTS_VIEW',
            'LANEMANAGER_DOWNTIMES_TOTAL',
            'LANEMANAGER_DOWNTIMES_VIEW',
            'LANEMANAGER_PALLETS_VIEW',
            'LANEMANAGER_DUMPS_VIEW',
            'LANEMANAGER_PERSONNEL_TOTAL',
            'LANEMANAGER_PERSONNEL_VIEW',
            'LANEMANAGER_PERFORMANCE_VIEW',
        ]);
        const TOTAL_ACCESS = IsInRuleset([
            'LANEMANAGER_STATE_TOTAL',
            'LANEMANAGER_COMMON_TOTAL',
        ]);
        return (
            (TOTAL_ACCESS || VIEW_ACCESS) && (
                <WrapFullContainer>
                    <WrapCardsToRow height="48%">
                        <OCard
                            height={'99%'}
                            width={'35%'}
                            contentHeight={'95%'}
                            contentPadding={'8px 8px 0 8px'}
                            overflowX={'auto'}>
                            <DumpInfoConnected />
                        </OCard>
                        <OCard
                            height={'99%'}
                            width={'65%'}
                            contentHeight={'100%'}
                            contentPadding={'0px'}
                            overflowX={'auto'}>
                            <PalletsInfoConnected />
                        </OCard>
                    </WrapCardsToRow>
                    <WrapCardsToRow height="52%">
                        <OCard
                            width={'100%'}
                            height={'100%'}
                            contentHeight={'100%'}
                            contentPadding={0}>
                            <ProdPartsInfoConnected />
                        </OCard>
                    </WrapCardsToRow>
                </WrapFullContainer>
            )
        );
    }
}

const mapDispatchToProps = (dispatch: any) => ({
    getCurrentDump: () => dispatch(getCurrentDump()),
    getProdparts: () => dispatch(getAreaAllProdparts()),
    getEmployees: () => dispatch(getAreaAllEmployees()),
    getPtables: () => dispatch(getAreaAllPtables()),
});

export const OverviewConnected = connect(
    null,
    mapDispatchToProps,
)(Overview);
