import React from 'react';
import {connect, MapDispatchToProps, MapStateToProps} from 'react-redux';
import {Action} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import {CardControlActions} from 'src/app/administration/cardControl/store/actions/cardcontrol.actions';
import {CardControlSelectors} from 'src/app/administration/cardControl/store/selectors';
import {CardControlServices} from 'src/app/administration/cardControl/store/services';
import {getDeviceState} from 'src/app/administration/cardControl/store/thunks/getDeviceState';
import * as CardControlTypes from 'src/app/administration/cardControl/store/types';
import {EmployeesControlResponseData} from 'src/app/administration/employeesControl/store/types';
import {OCard, WrapFullContainerNowrap} from 'src/shared/style';
import {IsInRuleset} from 'src/global/authorization/grantSet';
import {AppStoreState} from 'src/store';
import {fetchWithFeedback} from 'src/utils/fetcherValidate';
import {notify} from 'src/utils/Notification';

import {FormCardControl} from '../FormCardControl';
import {RegisteredCardsTable} from '../RegisteredCardsTable';
import {TableCardControl} from '../TableCardControl';
import {ParametersSelectors} from 'src/utils/Parameters/selectors';
import {ParameterCPN} from 'src/types/app_types';

type SProps = {
    formState: ReturnType<typeof CardControlSelectors.getFormState>;
    employeeCodeModeParam?: String;
};
type DProps = {
    setActive(activeSessionCode: string): void;
    setInactive(): void;
    getDeviceState(deviceId: string): void;
};

type State = {
    selectedCard: string | undefined;
    selectedCardCode?: string;
    selectedDevice: string | undefined;
    areas: CardControlTypes.CardControlArea[];
    devices: CardControlTypes.CardcontrolDevice[];
    employees: EmployeesControlResponseData[];
    registeredCards: CardControlTypes.CardControlRegisteredCard[];
    pages: number;
};

const initialState: State = {
    selectedCard: undefined,
    selectedDevice: undefined,
    areas: [],
    devices: [],
    employees: [],
    registeredCards: [],
    pages: 0,
};

// const TOTAL_ACCESS =
//   typeof IsInRuleset === 'function' ? IsInRuleset('CARDS_TOTAL') : false;

export class CardControl extends React.Component<SProps & DProps, State> {
    readonly state = initialState;
    private timeout: any;

    componentDidMount() {
        this.props.setInactive();
        this.updatePreflight();
        this.updateRegisteredCards();
        // this.updateEmployees();
        this.timeout = setInterval(() => {
            this.updateRegisteredCards();
        }, 5000);
    }

    componentWillUnmount() {
        clearInterval(this.timeout);
    }

    componentWillReceiveProps(nextProps: SProps & DProps) {
        const {formState} = nextProps;
        if (formState.id && formState.id !== '0') this.updateRegisteredCards();
    }

    componentDidUpdate(_: any, prevState: State) {
        if (this.state.selectedDevice !== prevState.selectedDevice) {
            this.updateRegisteredCards();
        }
    }

    private selectCard = (cardId: string, cardCode: string) => {
        this.setState({selectedCard: cardId, selectedCardCode: cardCode});
    };

    private createNewAssign = (employeeId: string, fn: () => void) => {
        if (employeeId) {
            this.handleNewAssign(this.state.selectedCard!, employeeId);
            fn();
        }
    };

    private selectDevice = (deviceId: string) => {
        if (deviceId !== this.state.selectedDevice && deviceId !== undefined) this.props.getDeviceState(deviceId);
        this.setState(() => ({selectedDevice: deviceId}));
    };

    public render() {
        const {registeredCards, devices, areas} = this.state;
        const {formState, employeeCodeModeParam} = this.props;
        return (
            IsInRuleset('CARDS_TOTAL') && (
                <WrapFullContainerNowrap>
                    <OCard width={'20%'} height={'100%'} contentHeight={'100%'}>
                        <FormCardControl
                            handleEnable={this.handleEnable}
                            handleDisable={this.handleDisable}
                            handleSelectDevice={this.selectDevice}
                            areas={areas}
                            devices={devices}
                            formState={formState.formState}
                        />
                    </OCard>
                    <OCard width={'20%'} height={'100%'} contentHeight={'100%'} contentPadding={0}>
                        <RegisteredCardsTable registeredCards={registeredCards} selectCard={this.selectCard} unassignCard={this.unassignCard} />
                    </OCard>
                    <div style={{width: '60%', height: 'calc(100% + 3px)'}}>
                        <TableCardControl createNewAssign={this.createNewAssign} unassignCards={this.unassignCards} employeeCodeModeParam={employeeCodeModeParam} />
                    </div>
                </WrapFullContainerNowrap>
            )
        );
    }

    private unassignCard = () => {
        if (this.state.selectedCardCode) {
            fetchWithFeedback(CardControlServices.newUnassign(this.state.selectedCardCode), {
                showMessage: true,
            });
        }
    };

    private unassignCards = (employeeId: string) => {
        if (employeeId) {
            fetchWithFeedback(CardControlServices.unassignAll(employeeId), {
                showMessage: true,
            });
        }
    };

    private handleEnable = async () => {
        const res = await fetchWithFeedback(
            CardControlServices.enableDevice(this.state.selectedDevice || 'NO_HAY_DEVICE_SELECCIONADO'),
            // {showMessage: true},
        );
        if (res && this.state.selectedDevice) this.props.getDeviceState(this.state.selectedDevice);
    };

    private updateRegisteredCards = () => {
        const {formState} = this.props;
        if (this.state.selectedDevice && formState.id && formState.id !== '0')
            this.getRegisteredCards()
                .then(cards => this.setState({registeredCards: cards || []}))
                .catch(_ => console.error(_));
    };

    private updatePreflight = () => {
        this.getPreflight()
            .then(data =>
                this.setState({
                    areas: data!.areas || [],
                    devices: data!.devices || [],
                }),
            )
            .catch(_ => console.error(_));
    };

    private handleDisable = async () => {
        try {
            await CardControlServices.disableDevice(this.state.selectedDevice || '1');
            this.props.getDeviceState(this.state.selectedDevice!);
        } catch (error) {
            console.error('handleDisable>> ', error);
        }
    };

    private handleNewAssign = async (cardCode: string, employeeId: string) => {
        const {employees, registeredCards} = this.state;
        if (cardCode && employeeId) {
            const foundCard = registeredCards.find(c => c.id === cardCode);
            const foundEmployee = employees.find(e => e.id === employeeId);
            await fetchWithFeedback(CardControlServices.newAssign(cardCode, employeeId), {
                successMessage: `Se ha asignado la tarjeta ${foundCard ? foundCard.card_code : ''} al empleado ${
                    foundEmployee ? foundEmployee.name + ' ' + foundEmployee.surname : ''
                }`,
            });
        } else {
            notify({
                message: `Hay que elegir la tarjeta y el empleado`,
                status: 'warning',
            });
        }
    };

    private getPreflight = async () => {
        const {areas, devices} = await fetchWithFeedback(CardControlServices.preflight());
        if (areas && devices) return {areas, devices};
        else return undefined;
    };

    private getRegisteredCards = async () =>
        await fetchWithFeedback(CardControlServices.registeredCards(this.props.formState.id), {
            accessor: 'registered_cards',
            errorMessage: 'Error al obtener tarjetas registradas',
        });
}

const ms2p: MapStateToProps<SProps, null, AppStoreState> = state => ({
    formState: CardControlSelectors.getFormState(state),
    employeeCodeModeParam: ParametersSelectors.getValueOfParameter(state, ParameterCPN.EMPLOYEE_CODE_MODE),
});

const md2p: MapDispatchToProps<DProps, null> = (dispatch: ThunkDispatch<AppStoreState, null, Action>) => ({
    setActive: (activeSessionCode: string) => dispatch(CardControlActions.setActive(activeSessionCode)),
    setInactive: () => dispatch(CardControlActions.setInactive()),
    getDeviceState: (deviceId: string) => dispatch(getDeviceState(deviceId)),
});

export const CardControlConnected = connect(ms2p, md2p)(CardControl);
