import {Reducer} from 'redux';

import {
  LaneSelectActions,
  LaneSelectActionTypes,
} from 'src/app/laneSelect/store/laneSelect.action-types';

export type LaneSelectStoreState = {
  laneId: string;
  laneName: string;
  laneDumpBarcode: string;
  AutoPalletise: boolean;
};
const initialState: LaneSelectStoreState = {
  laneId: '',
  laneName: '',
  laneDumpBarcode: '',
  AutoPalletise: false,
};
export const LaneSelectReducer: Reducer<LaneSelectStoreState, LaneSelectActions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case LaneSelectActionTypes.select:
      return {
        laneId: action.payload.laneId,
        laneName: action.payload.laneName,
        laneDumpBarcode: action.payload.laneDumpBarcode,
        AutoPalletise: action.payload.AutoPalletise,
      };
    case LaneSelectActionTypes.clear:
      return {laneId: '', laneName: '', laneDumpBarcode: '', AutoPalletise: false};
    default:
      return state;
  }
};
