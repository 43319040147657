import * as React from 'react';
import styled from 'styled-components';

import {withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';

export const WrapTables = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
`;

export const Content = styled.div`
    /* padding-top: 5px; */
    color: rgba(0, 0, 0, 0.87);
    font-size: 0.875rem;
    font-weight: 400;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    line-height: 1.46429em;
    /* height: calc(100% - 5px); */
`;

export const Title = styled.div`
    /* padding-bottom: 5px; */
    font-size: 1em;
    font-weight: 500;
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    @media (max-width: 1366px) {
        height: 30px;
    }
    border-bottom: 1px solid #eeeeee;
`;

export const Footer = styled.div`
    /* padding-top: 2px; */
    font-size: 15px;
    text-align: center;
    width: 100%;
    height: 100%;
    display: block;
`;

export const FooterLeft = styled.div`
    font-size: 0.6em;
    padding-left: 3px;
    height: 100%;
    width: 35%;
`;

export const FooterRight = styled('div')<{state?: string}>`
    width: 100%;
    height: 25px;
    font-size: 0.9rem;
    color: #1a1a1a;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 1366px) {
        height: 25px;
    }
    /* border-radius: 3px; */
    padding: 2px;
    background-color: ${({state}) => {
        if (state === 'ACTIVA') return '#00FF57';
        if (state === 'INACTIVA') return '#eeee';
        if (state === 'RESERVADA') return '#b93535';
        if (state === 'ASIGNADA') return 'yellow';
        return '#aaaaaa';
    }};
`;

export const Pallet = styled.div`
    width: 100%;
    height: 39px;
    display: table;
    border-bottom: 1px solid #eeeeee;
`;

export const PalletCell = styled.div`
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    font-size: 1.7rem;
    @media (max-width: 1366px) {
        font-size: 1.35rem;
    }
    font-weight: 500;
`;

const CustomCard = (props: any) => {
    const {classes} = props;

    return <Card className={classes.card}>{props.children}</Card>;
};
export const CustomTable = withStyles(theme => ({
    card: {
        width: 120,
        height: 120,
        backgroundColor: '#aaa',
        margin: 20,
        [theme.breakpoints.down('lg')]: {
            width: 100,
            height: 100,
            backgroundColor: '#aaa',
            margin: 10,
        },
    },
}))(CustomCard);
