import {Reducer} from 'redux';

import {AreaProdPartsRes, LaneActionInterface, LaneProdPartState} from '../types';
import {LaneActionTypes} from '../types/lane.action-types';

export const defaultState: LaneProdPartState[] = [
  // {
  //   packaging: 'caja2 kg',
  //   merchandise: 'tomate LV',
  //   id: 'e1ab1b-aa6172',
  //   order_arrival_at: new Date().toLocaleDateString(),
  //   customer: {id: 1, name: 'Mercadona'},
  //   variety: 'butano tomate',
  //   category: 'GG',
  //   // making_type: 'caja 30kg con envases de 2kg',
  //   dumps: ['545/34234'],
  //   pallets_total: 5,
  //   pallets_count: 1,
  //   ptables: ['1'],
  //   boxes_count: 15,
  //   boxes_total: 240,
  //   order_id: 'PR/144786',
  //   order_reference: '44412334 AUSTRIA 20 PO 99875',
  //   order_state: 'NO SERVIDO',
  //   batches: [{boxes: 1, erp_batch_code: 'testERPCODE'}],
  // },
  // {
  //   packaging: 'caja2 kg',
  //   merchandise: 'tomate LV',
  //   id: 'e1ab1b-dba134',
  //   order_arrival_at: new Date().toLocaleDateString(),
  //   customer: {id: 1, name: 'Mercadona'},
  //   variety: 'butano tomate',
  //   category: 'GG',
  //   // making_type: 'caja 30kg con envases de 4kg',
  //   dumps: ['545/34234'],
  //   pallets_total: 5,
  //   pallets_count: 0,
  //   ptables: ['2'],
  //   boxes_count: 15,
  //   boxes_total: 240,
  //   order_id: 'PR/144786',
  //   order_reference: '44412334 AUSTRIA 20 PO 99875',
  //   order_state: 'NO SERVIDO',
  //   batches: [{boxes: 1, erp_batch_code: 'testERPCODE'}],
  // },
];

// type Payload = ProdPartState | AreaProdPartsRes;
export const laneProdPartsReducer: Reducer<LaneProdPartState[], LaneActionInterface> = (
  state = defaultState,
  {type, payload},
): LaneProdPartState[] => {
  if (!state) return state;
  switch (type) {
    case LaneActionTypes.updateProdPart: {
      const cast: LaneProdPartState = payload;
      const updated = state.map(pp => {
        if (pp.id === cast.id) {
          return {...pp, ...cast};
        } else return pp;
      });
      return updated;
    }
    case LaneActionTypes.setProdParts: {
      const {prodparts} = payload as AreaProdPartsRes;
      const transform = prodparts.map(
        app =>
          ({
            id: app.id,
            packaging: app.packaging,
            order_arrival_at: app.order_arrival_at,
            pallets_count: app.pallets_count,
            pallets_total: app.pallets_total,
            boxes_count: app.boxes_count,
            boxes_total: app.boxes_total,
            order_id: app.order_id,
            order_reference: app.order_reference,
            order_state: app.order_state,
            variety: app.merchandise.name,
            category: app.category,
            erp_prodpart_code: app.erp_prodpart_code,
            // making_type: app.making_type.name,
            // dumps: app.dumps ? app.dumps.map(b => b.id) : [],
            ptables: app.ptables ? app.ptables.map(pt => pt.id) : [],
            customer: app.customer,
          } as LaneProdPartState),
      );
      return [...transform];
    }
    case LaneActionTypes.clearProdParts: {
      return [];
    }
    default:
      return state;
  }
};
