import {
    Button,
    Card,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Input,
    List,
    Typography
} from "@material-ui/core";
import {ListItem} from "@material-ui/core/es";
import React from "react";
import {TransBatchType, TransferPalletType} from "./store/liftPalletsControl.types";


type State  = {
    transfers : Map<string, TransBatchType>,
    limitBoxesToTransfer : number,
    initialBoxesToTransfer : number,
}

const initialState: State = {
    transfers : new Map<string, TransBatchType>(),
    limitBoxesToTransfer : 0,
    initialBoxesToTransfer : 0,
}

type Props = {
    open : boolean,
    pallet: TransferPalletType | null,
    maxBoxes : number,
    transferedBoxes : number,
    handleClose : () => void,
    handleTransfer : (totalBoxesCount : number, palletBarcode : string | undefined, transfer : Map<string, TransBatchType>) => void,
}

export class TransferBoxesDialog extends React.Component<Props, State> {
    readonly state = initialState;

    componentDidUpdate(prevProps : Readonly<Props>) {
        if (prevProps.pallet?.id !== this.props.pallet?.id) this.fillTransfer();
    }

    private fillTransfer = () => {
        if (this.props.pallet !== null) {
            this.props.pallet.batches.forEach(b => {
                if (b.erpCode && b.maxBoxes > 0) {
                    const _batch = Object.assign({}, b);
                    this.state.transfers.set(b.erpCode, _batch);
                }
            })

            const initialBoxesToTransfer = this.getTotalTransferedBoxesCount();
            const limitBoxesToTransfer = this.props.maxBoxes - this.props.transferedBoxes + initialBoxesToTransfer;
            this.setState({limitBoxesToTransfer, initialBoxesToTransfer});
        } else {
            this.state.transfers.clear();
        }
    }

    private handleTransferBoxes = (event : React.ChangeEvent<HTMLTextAreaElement>) => {
        const batchCode = event.target.id;
        let newValue = parseInt(event.target.value);

            const batch = this.state.transfers.get(batchCode);
            if (batch) {
                const oldValue = batch.boxCount;
                if (newValue < 0) newValue = 0;
                if (newValue > batch.maxBoxes) newValue = batch.maxBoxes;

                batch.boxCount = newValue;
                const totalBoxes = this.getTotalTransferedBoxesCount();
                if (totalBoxes > this.state.limitBoxesToTransfer) batch.boxCount = oldValue;

                this.forceUpdate();
            }
    }

    private addAllBoxes = () : void => {
        const currentTransfers = this.state.transfers;
        currentTransfers.forEach(b => {
            if (b.erpCode && b.maxBoxes > 0) {
                const _batch = Object.assign({}, {
                    batchId: b.batchId,
                    boxCount: b.maxBoxes,
                    erpCode: b.erpCode,
                    maxBoxes: b.maxBoxes
                });

                this.state.transfers.set(b.erpCode, _batch);
            }
        })
        this.forceUpdate();
    }

    private getTotalTransferedBoxesCount = () : number => {
        let total = 0;
        this.state.transfers.forEach(v => {
            total += v.boxCount;
        })

        return total;
    }



    render() {
        //this.fillTransfer();
        const batchesList = Array.from(this.state.transfers);
        let transBoxesCount = this.getTotalTransferedBoxesCount();
        if (isNaN(transBoxesCount)) transBoxesCount = 0;
        const boxesTotal = this.props.pallet?.boxes_count ?? 0;
        const boxesCount = boxesTotal - transBoxesCount;
        const currentBoxesCount = this.props.maxBoxes - this.state.limitBoxesToTransfer + transBoxesCount;

        return (
            this.props.open &&
            <Dialog
                open={this.props.open}
                onClose={this.props.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Transferir cajas"}</DialogTitle>
                <DialogContent>
                    <Typography variant='subtitle1'>

                                <Grid container alignItems='center'>
                                            <Grid item xs={8}>
                                                <b>{this.props.pallet?.barcode}</b> ({boxesCount}/{boxesTotal})
                                            </Grid>
                                            <Grid item xs={4}>
                                                {((this.state.limitBoxesToTransfer >= boxesTotal) &&
                                                <Typography component="span"
                                                    style={{
                                                        cursor: 'pointer',
                                                        color: 'blue'
                                                    }}>
                                                    <span
                                                        onClick={this.addAllBoxes}>
                                                        Transferir todo
                                                    </span>
                                                    </Typography>

                                                )}
                                            </Grid>
                                        </Grid>

                    </Typography>
                    <Typography variant='caption'>
                        Total de cajas marcadas para transferir: <b>{currentBoxesCount}</b>/{this.props.maxBoxes}
                    </Typography>
                    <Typography variant='subtitle1'>
                        Cajas a transferir del palé actual: <b>{transBoxesCount}</b>/{this.state.limitBoxesToTransfer}
                    </Typography>
                    <List>
                        {(
                            batchesList.map(v => (
                                <ListItem key={v[0]}>
                                    <Card style={{padding:'8px', width:'100%'}}>
                                        <Typography variant='caption'>
                                            Origen: <b>{v[0]}</b>
                                        </Typography>
                                        <Grid container alignItems='center'>
                                            <Grid item xs={10}>
                                                <Typography variant='subtitle1'>
                                                    Cajas a transferir (<Typography inline={true} variant='caption'>de <b>{v[1].maxBoxes}</b></Typography>):
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Input
                                                    type='number'
                                                    style={{textAlign:'right'}}
                                                    value={v[1].boxCount}
                                                    id={v[0]}
                                                    onChange={this.handleTransferBoxes}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </ListItem>
                            ))
                        )}
                    </List>


                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.handleClose} color="secondary">
                        Cancelar
                    </Button>
                    <Button onClick={() => this.props.handleTransfer(transBoxesCount, this.props.pallet?.barcode, this.state.transfers)}
                            color="primary">
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}
