import {Dump} from 'src/app/lanemanager/lane/store/types';
import {withStyles, MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import format from 'date-fns/format';
import es from 'date-fns/locale/es';
import invoke from 'lodash/invoke';
import React from 'react';
import {List} from 'react-content-loader';
import styled from 'styled-components';
import {customFormatNumber} from 'src/shared/functions/FormatHelpers';
import LinearProgress from '@material-ui/core/LinearProgress';

const stylesTypo = {
    typo: {
        paddingTop: 5,
        fontSize: 12,
        borderBottom: '1px solid #DFDFDF',
        width: '100%',
        paddingLeft: 0,
    },
};

const Heading = styled.div<{fontSize?: number}>`
    display: flex;
    flex-direction: column;
    margin: 0px 4px;
    span {
        text-align: center;
    }
    & > span:nth-child(1) {
        text-transform: uppercase;
        font-weight: bold;
        font-size: ${({fontSize}) => fontSize || '0.6'}rem;
    }
`;

const HeadingGroup = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
`;
const ThemedTypo = withStyles(stylesTypo)((props: any) => (
    <Typography data-name={props.dataName} className={props.classes.typo}>
        {props.children}
    </Typography>
));

type Props = {
    dumpData: Dump;
};

const themeSuccess = createMuiTheme({
    palette: {
        primary: {main: '#3c763d'},
    },
});

const themeWarning = createMuiTheme({
    palette: {
        primary: {main: '#f8c716'},
    },
});

const themeDanger = createMuiTheme({
    palette: {
        primary: {main: '#a94442'},
    },
});

export const DetailedInfo: React.FunctionComponent<Props> = ({dumpData}) => {
    if (!dumpData || (!dumpData.dumped_at && !dumpData.total_kg && !dumpData.merchandise && !dumpData.provider))
        return <div style={{fontSize: 14}}>No hay datos del origen ni del volcado...</div>;

    const {
        dumped_at,
        arrival_at,
        total_kg,
        merchandise,
        dumped_boxes,
        provider,
        allowed_customers,
        quality_protocols,
        batches,
        origin_kg_dumped,
        origin_kg_palletized,
        origin_percentage_kg,
    } = dumpData;

    let colorTheme = themeSuccess;
    if (origin_percentage_kg > 100) {
        colorTheme = themeDanger;
    } else if (origin_percentage_kg > 90) {
        colorTheme = themeWarning;
    }

    let origin_kg_dumped_value = origin_kg_dumped;
    if (origin_kg_dumped === undefined) {
        origin_kg_dumped_value = 0;
    }

    let origin_kg_palletized_value = origin_kg_palletized;
    if (origin_kg_palletized === undefined) {
        origin_kg_palletized_value = 0;
    }

    let origin_percentage_kg_value = origin_percentage_kg;
    if (origin_percentage_kg === undefined) {
        origin_percentage_kg_value = 0;
    }

    return (
        <>
            {!arrival_at && (
                <>
                    <List style={{width: '80%', height: 120, marginTop: 10}} preserveAspectRatio={'none'} />
                    <List style={{width: '80%', height: 120}} preserveAspectRatio={'none'} />
                </>
            )}
            {arrival_at && (
                <>
                    <ThemedTypo>
                        <Heading>
                            <div style={{display: 'flex'}}>
                                <span>Origen: </span>
                                {invoke(batches, 'map', (btch: Dump['batches'][0], idx: number) => (
                                    <Typography key={'batches' + idx} style={{marginLeft: 5, marginRight: 5}}>
                                        {btch.erp_batch_code} ({btch.boxes})
                                    </Typography>
                                ))}
                            </div>
                        </Heading>
                    </ThemedTypo>
                    <ThemedTypo>
                        <Heading fontSize={0.9}>
                            <span>{merchandise && merchandise.name}</span>
                            <span style={{fontSize: '0.9rem'}}>
                                {provider && provider.name} <span style={{fontSize: '0.9rem'}}>({provider && provider.erp_code})</span>
                            </span>
                            <span style={{fontSize: '0.9rem'}}>
                                {customFormatNumber(origin_kg_dumped_value)} kg volcados / {customFormatNumber(origin_kg_palletized_value)} kg paletizados [
                                {origin_percentage_kg_value} %]
                                <MuiThemeProvider theme={colorTheme}>
                                    <LinearProgress variant="determinate" value={origin_percentage_kg_value} className={'linearProgressCustom'} />
                                </MuiThemeProvider>
                            </span>
                        </Heading>
                    </ThemedTypo>
                    <ThemedTypo dataName={'arrival-time'}>
                        <HeadingGroup>
                            <Heading>
                                <span>Volcado</span>
                                <span>
                                    {format(new Date(dumped_at), 'HH:mm', {
                                        locale: es,
                                    })}
                                </span>
                            </Heading>
                            <Heading>
                                <span> Origen llegada</span>
                                <span>
                                    {format(new Date(arrival_at), 'DD/MM/YYYY HH:mm', {
                                        locale: es,
                                    })}
                                </span>
                            </Heading>
                            <Heading>
                                <span>Kilos</span>
                                <span>{customFormatNumber(total_kg)}</span>
                            </Heading>
                            <Heading>
                                <span>Cajas volcadas</span>
                                <span>{customFormatNumber(dumped_boxes)}</span>
                            </Heading>
                        </HeadingGroup>
                    </ThemedTypo>
                    <ThemedTypo>
                        <HeadingGroup>
                            <Heading>
                                <span>Válida para</span>
                                <span>{allowed_customers && allowed_customers.map((ac, i) => <span key={i}> {ac}</span>)}</span>
                            </Heading>
                            <Heading>
                                <span>Calidad</span>
                                <span>{quality_protocols || 'No está definido'}</span>
                            </Heading>
                        </HeadingGroup>
                    </ThemedTypo>
                </>
            )}
        </>
    );
};
