import React from 'react';
import styled from 'styled-components';

import {WrapForm} from 'src/app/administration/export/styled/Wrap';
import {CustomButton} from 'src/shared/style/CustomButton';
import {CustomTextField} from 'src/shared/style/CustomTextField';
//import {fetchWithFeedback} from 'src/utils/fetcherValidate';
import {CustomDropdown} from 'src/shared/style';
import { ShiftsMapService } from 'src/shared/components/services';
import { fetchWithFeedback } from 'src/utils/fetcherValidate';
import {notify} from 'src/utils/Notification';

type State = Readonly<{
    origin_code: string;
    box_count: number;
    shift_name: string;
    shift_id: string;
    shift_date: string;
    shiftsList: Array<{id: string; name: string}>;
}>;

const initialState: Readonly<State> = {
    origin_code: '',
    box_count: 0,
    shift_name: '',
    shift_id: '',
    shift_date: '',
    shiftsList: [],
};

type Props = {
    handleClose(): void;
    update(): void;
    handleCreateLater: (
        originCode : string,
        boxCount : number,
        workshiftDate : string,
        workshiftType : string) => void;
};

export class PostDumpsForm extends React.Component<Props, State> {
    readonly state = initialState;

    componentDidMount() {
        this.fetchShiftsMap();
    }

    private fetchShiftsMap = async () => {
        const shiftsList : Array<{
            id:string;
            name:string;
        }> = await fetchWithFeedback(ShiftsMapService(), {accessor: 'shifts'});
        this.setState({shiftsList});
    }

    render() {
        const {shift_id, shift_date, origin_code, box_count, shiftsList} = this.state;
        return (
            <WrapForm margin={'5px 10px'}>
                <CustomTextField
                    margin={'10px 0 10px 0'}
                    label={'Código del origen'}
                    variant="outlined"
                    width={'500px'}
                    onChange={this.handleInputs('origin_code')}
                    value={origin_code}
                />

                <CustomTextField
                    margin={'10px 0 10px 0'}
                    label={'Nº de cajas'}
                    variant="outlined"
                    width={'500px'}
                    type={'number'}
                    onChange={this.handleInputs('box_count')}
                    value={box_count}
                />

                <DataDiv>
                    <LeftPart>
                        <CustomTextField
                            margin={'15px 5px 10px 0'}
                            width={'100%'}
                            label="Fecha"
                            error={false}
                            value={shift_date}
                            name="workShift"
                            variant={'outlined'}
                            type="date"
                            shrink={true}
                            onChange={this.handleInputs('shift_date')}
                        />
                    </LeftPart>
                    <RightPart>
                        <CustomDropdown
                            title={'Turno'}
                            margin={'0 5px 10px 20px'}
                            data={shiftsList}
                            dropdownName={'center'}
                            value={shift_id}
                            handleChoose={e => this.setState({shift_id: e.target.value})}
                        />
                    </RightPart>
                </DataDiv>
                <div style={{display: 'flex'}}>
                    <CustomButton margin={'10px 0px 0px auto'} onClick={this.handleSend} width={'25%'}>
                        Crear
                    </CustomButton>
                </div>
            </WrapForm>
        );
    }

    private handleSend = async () => {
        if (!this.state.origin_code || this.state.box_count < 1 || !this.state.shift_date || !this.state.shift_id) {
            notify({message: 'Hay que rellenar todos los campos. Por favor, revise el formulario.', status: 'error'});
        }
        else {
            this.props.handleCreateLater(this.state.origin_code, this.state.box_count, this.state.shift_date, this.state.shift_id);
        }
    };

    private handleInputs: HandleNamedChange<State> = name => e => {
        this.setState({[name]: e.target.value, [`${name}Valid`]: true} as Pick<State, keyof State>);
    };
}

export const LeftPart = styled.div`
    width: 48%;
`;
export const RightPart = styled.div`
    width: 48%;
    display: flex;
    flex-direction: column;
`;
export const DataDiv = styled.div`
    display: flex;
`;
