export enum actionTypes {
    FETCH_MAPS = 'FETCH_MAPS',
    FETCH_MAP = 'FETCH_MAP',
    FETCH_KPIS = 'FETCH_KPIS'
}

export type Map = {
    id: string;
    blueprintThumb: string;
    blueprintImg: string;
    label: string;
    centerId: string;
    points: Array<{
        top: number;
        left: number;
        width: number;
        height: number;
        background: string;
        fontSize: number;
        titleSize: number;
        status: string;
        data: {
            title: string;
            areaId: string;
            areaDumpBarcode: string;
            areaAutoPalletise: boolean;
            workShift: number;
            infoProduct?: any[];
            infoBatch?: {
                activeBatch: string;
                countBox: string;
                productName: string;
                kg: any;
            };
            clientsPallets?: Array<{
                name: string;
                pallets_count: number;
                pallets_total: number;
            }>;
            lastStop?: {
                label: string;
                value: string;
            };
            directEmployees?: Array<{
                name: string;
                task: string;
            }>;
        };
        cam: {
            url: string;
            thumb: string;
        };
    }>;
    details: Array<{
        titleGroup: string;
        data: any[];
    }>;
    config: {
        titleLevel: number;
        zoomsOfDetailsLevels: number[];
        minScale: number;
        maxScale: number;
    };
    centerInfo: {
        indirectEmployees?: Array<{
            name: string;
            task: string;
            firstTask: boolean;
        }>;
    };
};

export type BlueprintKPIs = {
    linesCount : string;
    workedKg : string;
    perfomance : string;
    totalCost : string;
    indirectCost : string;
}
