import {ProdPartsServices} from 'src/app/lanemanager/prodparts/store/prodParts.services';
import {guardedFetcher} from 'src/utils/guardedFetcher';

// import {PaginateParams} from 'src/types';

const prefix = 'crud/ptables';

type Options = {id?: string; name?: string; areaId?: string; barcode?: number | null};
export const PtablesControlServices = {
  // FIXME: change to the propietary service
  get: () => ProdPartsServices.getPtables(),
  // (params?: PaginateParams) => guardedFetcher.get(`${prefix}/all`, {params}),
  update: ({id, name, barcode, areaId}: Options) =>
    guardedFetcher.patch(`${prefix}/${id}`, {name, barcode, areaId}),
  create: ({name, areaId, barcode}: Options) =>
    guardedFetcher.post(`${prefix}`, {name, areaId, barcode}),
  delete: ({id}: Options) => guardedFetcher.delete(`${prefix}/${id}`),
};
