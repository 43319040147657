import * as React from 'react';
import {connect} from 'react-redux';

import {EmployeeTaskSelectSelectors, EmployeeTaskServices, EmployeeTask} from 'src/app/presencemanagement/store';

import {AppStoreState} from 'src/store';
import {EmployeeTaskSelectActions} from './store';
import {OCard, CustomButton} from 'src/shared/style';
import styled from 'styled-components';
import {format} from 'date-fns';
import es from 'date-fns/locale/es';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {Tooltip} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import {CustomDialog} from 'src/shared/components/CustomDialog';
import {ActivityModal} from './ActivityModal';
import {fetchWithFeedback} from 'src/utils/fetcherValidate';
import {FullSizeModal} from 'src/shared/components/FullSizeModal';
import profileImage from 'src/assets/blank-profile.png';
import {EmployeesControlServices} from 'src/app/administration/employeesControl/store/employeesControl.services';
import {notify} from 'src/utils/Notification';
import {customFormatNumber} from 'src/shared/functions/FormatHelpers';
import {CenterControlServices} from 'src/app/administration/centerControl/store/centerControl.services';
import {IsInRuleset} from 'src/global/authorization/grantSet';

export interface Props {
    open: boolean;
    hidePresenceManagementModal: () => void;
}

type DProps = {
    selectEmployeeTask(employeeId: string, employeeName: string, employeeSurname: string, workShift: string, centerId: string): void;
};

type SProps = {
    employeeTaskSelect: ReturnType<typeof EmployeeTaskSelectSelectors.getEmployeeTask>;
};

const initialState = {
    activities: [],
    durationInHours: 0,
    centerName: '',
    showModalDelete: false,
    showModalEdit: false,
    employeeTaskIdSelected: '',
    taskIdSelected: '',
    centerIdSelected: '',
    areaIdSelected: '',
    createdAtSelected: '',
    finishedAtSelected: '',
    createdAtDaySelected: '',
    finishedAtDaySelected: '',
    currentEmployeeId: '',
    currentWorkshift: '',
    centersList: [],
    tasksList: [],
    profileUrl: undefined,
    sameCenter: true,
};

// const Table = TableComponent<LaneSelectResponse>();
export class PresenceManagementModalBase extends React.Component<DProps & SProps & Props> {
    state = initialState;

    componentWillReceiveProps(nextProps: any) {
        this.setState({currentEmployeeId: nextProps.employeeTaskSelect.employeeId});
        this.setState({currentWorkshift: nextProps.employeeTaskSelect.workshift});
        this.setState({centerIdSelected: nextProps.employeeTaskSelect.centerId});
        this.showData(nextProps);
        this.fetchProfileUrl(nextProps.employeeTaskSelect.employeeId);
        this.fetchCenters();
        this.fetchTasks(nextProps.employeeTaskSelect.employeeId);
    }

    private hideModalTable = () => {
        this.props.hidePresenceManagementModal();
    };

    private fetchCenters = async () => {
        const centersList = await fetchWithFeedback(CenterControlServices.get());
        this.setState({centersList});
    };

    private fetchTasks = async (employeeId: string) => {
        const tasksList = await fetchWithFeedback(EmployeeTaskServices.getTasksAllowedByEmployee(employeeId), {
            accessor: 'tasks',
        });
        this.setState({
            tasksList: tasksList /*.filter((t: any) => t.direct === true)*/,
        });
    };

    private fetchProfileUrl = async (employeeId: string) => {
        const profileUrl = await fetchWithFeedback(EmployeesControlServices.getProfileUrl(employeeId));
        this.setState({profileUrl});
    };

    showData(props: any) {
        const {employeeTaskSelect} = props;

        EmployeeTaskServices.getByWorkshiftAndEmployee(employeeTaskSelect.workshift, employeeTaskSelect.employeeId).then((res: any) => {
            const activitiesByWorkshiftAndEmployee: EmployeeTask[] = [];
            let durationMinInWorkshift = 0;
            let centerName = '';

            let sameCenter = true;
            let centerIdTemp = undefined;

            if (res && res.data) {
                for (const index in res.data) {
                    if (res.data[index]) {
                        const durationMin = Math.round(res.data[index].duration / 60);
                        durationMinInWorkshift += durationMin;
                        centerName = res.data[index].center_name;

                        if (centerIdTemp !== res.data[index].center_id && centerIdTemp !== undefined) {
                            sameCenter = false;
                        }

                        centerIdTemp = res.data[index].center_id;

                        const dataActivity: EmployeeTask = {
                            employeeTaskId: res.data[index].employee_task_id,
                            employeeId: res.data[index].employee_id,
                            centerName: res.data[index].center_name,
                            centerId: res.data[index].center_id,
                            areaName: res.data[index].area_name,
                            areaId: res.data[index].area_id,
                            taskName: res.data[index].task_name,
                            taskId: res.data[index].task_id,
                            taskDirect: this.getValuesDirect(Number(res.data[index].direct)),
                            createdAt: this.getDateFormat(res.data[index].created_at),
                            finishedAt: this.getDateFormat(res.data[index].finished_at),
                            createdAtDay: '2020-04-13', //res.data[index].created_at,
                            finishedAtDay: '2020-04-13', //res.data[index].finished_at,
                            duration: durationMin,
                            automaticModify: parseInt(res.data[index].automatic_modify, 10),
                            manuallyModify: parseInt(res.data[index].manually_modify, 10),
                        };

                        activitiesByWorkshiftAndEmployee.push(dataActivity);
                    }
                }
            }
            this.setState({activities: activitiesByWorkshiftAndEmployee});
            this.setState({
                durationInHours: Math.round((durationMinInWorkshift / 60 + Number.EPSILON) * 100) / 100,
            });
            this.setState({centerName: centerName});
            this.setState({sameCenter: sameCenter});
        });
    }

    getDateFormat(dateString: string) {
        if (dateString === null) {
            return '';
        }
        return format(new Date(dateString), 'HH:mm:ss', {locale: es});
    }

    getValuesDirect(itemDirect: number) {
        if (itemDirect === 1) {
            return 'Directa';
        }

        if (itemDirect === 0) {
            return 'Indirecta';
        }

        if (itemDirect === 2) {
            return 'Envasado';
        }

        return '';
    }

    private createActivity = async (areaId: string, taskId: string, hourCreatedAt: string, hourFinishedAt: string, dayCreatedAt: string, dayFinishedAt: string) => {
        if (hourCreatedAt.length === 5) {
            hourCreatedAt = hourCreatedAt + ':00';
        }

        if (hourFinishedAt.length === 5) {
            hourFinishedAt = hourFinishedAt + ':00';
        }

        const employeeId = this.state.currentEmployeeId;
        const createdAt = dayCreatedAt + ' ' + hourCreatedAt;
        const finishedAt = dayFinishedAt + ' ' + hourFinishedAt;

        const dataResponse = await EmployeeTaskServices.registerActivity(employeeId, areaId, taskId, createdAt, finishedAt);

        if (dataResponse.status === 200) {
            notify({message: 'Actividad creada', status: 'success'});
        } else {
            this.getErrorsMessages(dataResponse);
        }

        this.showData(this.props);
        this.hidePresenceManagementModal();
    };

    private editActivity = async (employeeTaskId: string, areaId: string, taskId: string, hourCreatedAt: string, hourFinishedAt: string) => {
        const employeeId = this.state.currentEmployeeId;

        const createdAt = this.getDateByHour(hourCreatedAt);
        const finishedAt = this.getDateByHour(hourFinishedAt);

        //const dataResponse = await fetchWithFeedback(
        const dataResponse = await EmployeeTaskServices.editActivity(employeeTaskId, employeeId, areaId, taskId, createdAt, finishedAt);

        if (dataResponse.status === 200) {
            notify({message: 'Actividad editada', status: 'success'});
        } else {
            this.getErrorsMessages(dataResponse);
        }

        this.showData(this.props);
        this.hidePresenceManagementModal();
    };

    private deleteActivity = async (employeeTaskId: string) => {
        await fetchWithFeedback(EmployeeTaskServices.deleteActivity(employeeTaskId), {
            errorMessage: 'Ocurrió un error al eliminar el registro',
            successMessage: 'Actividad eliminada',
        });

        this.showData(this.props);
        this.hidePresenceManagementModal();
    };

    private hidePresenceManagementModal = () => {
        this.setState({
            showModalEdit: false,
            employeeTaskIdSelected: '',
            areaIdSelected: '',
            taskIdSelected: '',
            createdAtSelected: '',
            finishedAtSelected: '',
        });
    };

    private getDateByHour(hourString: string) {
        if (hourString === '') {
            return '';
        }

        const date: Date = new Date(this.state.currentWorkshift + ' ' + hourString);
        if (date.getHours() >= 0 && date.getHours() < 5) {
            date.setDate(date.getDate() + 1);
        }

        const dformat =
            [date.getFullYear(), ('0' + (date.getMonth() + 1)).slice(-2), ('0' + date.getDate()).slice(-2)].join('-') +
            ' ' +
            [('0' + date.getHours()).slice(-2), ('0' + date.getMinutes()).slice(-2), ('0' + date.getSeconds()).slice(-2)].join(':');

        return dformat;
    }

    getErrorsMessages(dataResponse: any) {
        if (dataResponse.data.message === 'THERE_ARE_ALREADY_ACTIVITIES_WITH_THAT_PERIOD_OF_TIME_ASSIGNED') {
            notify({message: 'Error en fechas. Periodos ya asignados', status: 'error'});
        } else if (dataResponse.data.message === 'PERIOD_OF_TIME_OF_THIS_ACTIVITY_NOT_VALID') {
            notify({message: 'Error: fechas no válidas', status: 'error'});
        } else if (dataResponse.data.message === 'TASK_NOT_FOUND') {
            notify({message: 'Error: tarea no encontrada', status: 'error'});
        } else if (dataResponse.data.message === 'AREA_NOT_FOUND') {
            notify({message: 'Error: área no encontrada', status: 'error'});
        } else {
            notify({message: 'Ha ocurrido un error', status: 'error'});
        }
    }

    render() {
        let timeToHHMM = '00:00';

        if (this.state.durationInHours > 0) {
            let totalSeconds = this.state.durationInHours * 3600;
            const hours = Math.floor(totalSeconds / 3600);
            totalSeconds %= 3600;
            const minutes = Math.floor(totalSeconds / 60);
            timeToHHMM = hours + ':' + minutes;
        }

        return (
            <>
                <FullSizeModal title={'Gestión de Marcajes'} open={this.props.open} handleClose={this.hideModalTable}>
                    <CustomDialog
                        title="Confirmación"
                        contentText="¿Está seguro que desea eliminar el registro?"
                        handleClose={() => this.setState({showModalDelete: false})}
                        open={this.state.showModalDelete}
                        handle={() => {
                            this.deleteActivity(this.state.employeeTaskIdSelected);
                            this.setState({showModalDelete: false});
                        }}
                    />
                    <ActivityModal
                        centersList={this.state.centersList}
                        tasksList={this.state.tasksList}
                        employeeTaskId={this.state.employeeTaskIdSelected}
                        taskId={this.state.taskIdSelected}
                        centerId={this.state.centerIdSelected}
                        areaId={this.state.areaIdSelected}
                        createdAt={this.state.createdAtSelected}
                        finishedAt={this.state.finishedAtSelected}
                        createdAtDay={this.state.currentWorkshift}
                        finishedAtDay={this.state.currentWorkshift}
                        open={this.state.showModalEdit}
                        hidePresenceManagementModal={this.hidePresenceManagementModal}
                        createActivity={this.createActivity}
                        editActivity={this.editActivity}
                    />
                    <WrappingDiv>
                        <OCard width={'100%'} contentHeight={'100px'} contentPadding={'5px'} margin={'auto'} marginBottom={'1em'}>
                            <TitleEmployeeStyle>
                                <div
                                    style={{
                                        flexGrow: 1,
                                    }}
                                >
                                    <img
                                        style={{
                                            margin: 'auto',
                                            width: 100,
                                            height: 100,
                                            objectFit: 'scale-down',
                                        }}
                                        src={!!this.state.profileUrl ? this.state.profileUrl : profileImage}
                                        alt={'img'}
                                    />
                                </div>
                                <div
                                    style={{
                                        flexGrow: 6,
                                    }}
                                >
                                    <WrappingP>
                                        <h2>
                                            {this.props.employeeTaskSelect.employeeFullname}
                                            {' ( '}
                                            {format(new Date(this.props.employeeTaskSelect.workshift), 'DD/MM/YYYY', {
                                                locale: es,
                                            })}
                                            {' )'}
                                            {this.state.durationInHours > 0 ? ' - ' + timeToHHMM + ' Horas' : ''}
                                        </h2>
                                    </WrappingP>
                                </div>
                            </TitleEmployeeStyle>
                        </OCard>
                        <OCard width={'100%'} contentHeight={'100%'} contentPadding={'5px'} margin={'auto'} marginBottom={'1em'}>
                            <WrappingP>
                                <b>Listado de fichajes {this.state.centerName !== '' ? (this.state.sameCenter ? ' - ' + this.state.centerName : ' - Varios Centros') : ''}</b>
                            </WrappingP>
                            <hr />
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Tarea</TableCell>
                                        <TableCell align="center">Inicio</TableCell>
                                        <TableCell align="center">Fin</TableCell>
                                        <TableCell align="right">Min</TableCell>
                                        <TableCell>{}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.activities.map((row: EmployeeTask) => (
                                        <TableRow key={row.employeeTaskId}>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                                style={{
                                                    color: row.manuallyModify === 1 ? '#4245FB' : row.automaticModify === 1 ? '#FBBB42' : '',
                                                }}
                                            >
                                                <p>
                                                    <b>{row.taskName}</b> ({row.taskDirect})
                                                </p>
                                                <p>
                                                    {row.areaName} {!this.state.sameCenter ? ' - ' + row.centerName : ''}
                                                </p>
                                            </TableCell>
                                            <TableCell align="center">{row.createdAt}</TableCell>
                                            <TableCell align="center">{row.finishedAt}</TableCell>
                                            <TableCell align="right">{customFormatNumber(row.duration)}</TableCell>
                                            <TableCell>
                                                {IsInRuleset(['PRESENCEMANAGEMENT_TOTAL']) && (
                                                    <CustomButton
                                                        width={'unset'}
                                                        minWidth={'unset'}
                                                        height={'unset'}
                                                        //padding={'0 5px 9px 5px'}
                                                        margin={'2px 2px 2px 2px'}
                                                        bgcolor={'#c44'}
                                                        onClick={() => {
                                                            this.setState({
                                                                employeeTaskIdSelected: row.employeeTaskId,
                                                            });
                                                            this.setState({taskIdSelected: row.taskId});
                                                            this.setState({createdAtSelected: row.createdAt});
                                                            this.setState({finishedAtSelected: row.finishedAt});
                                                            this.setState({
                                                                createdAtDaySelected: row.createdAtDay,
                                                            });
                                                            this.setState({
                                                                finishedAtDaySelected: row.finishedAtDay,
                                                            });
                                                            this.setState({centerIdSelected: row.centerId});
                                                            this.setState({areaIdSelected: row.areaId});
                                                            this.setState({showModalEdit: true});
                                                        }}
                                                    >
                                                        <Tooltip title={'Editar marcaje'} placement="top">
                                                            <EditIcon />
                                                        </Tooltip>
                                                    </CustomButton>
                                                )}
                                                {IsInRuleset(['PRESENCEMANAGEMENT_TOTAL']) && (
                                                    <CustomButton
                                                        width={'unset'}
                                                        minWidth={'unset'}
                                                        height={'unset'}
                                                        //padding={'0 5px 9px 5px'}
                                                        margin={'2px 2px 2px 2px'}
                                                        bgcolor={'#c44'}
                                                        onClick={() => {
                                                            this.setState({showModalDelete: true});
                                                            this.setState({
                                                                employeeTaskIdSelected: row.employeeTaskId,
                                                            });
                                                        }}
                                                    >
                                                        <Tooltip title={'Eliminar marcaje'} placement="top">
                                                            <DeleteIcon />
                                                        </Tooltip>
                                                    </CustomButton>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                                {IsInRuleset(['PRESENCEMANAGEMENT_TOTAL']) && (
                                    <CustomButton
                                        width={'unset'}
                                        minWidth={'unset'}
                                        height={'unset'}
                                        //padding={'0 5px 9px 5px'}
                                        margin={'2px 2px 2px 2px'}
                                        bgcolor={'#c44'}
                                        onClick={() => this.setState({showModalEdit: true})}
                                    >
                                        <Tooltip title={'Crear marcaje'} placement="top">
                                            <AddIcon />
                                        </Tooltip>
                                    </CustomButton>
                                )}
                            </Table>
                        </OCard>
                    </WrappingDiv>
                </FullSizeModal>
            </>
        );
    }
}

const WrappingDiv = styled.div`
    width: calc(99%);
    margin: auto;
    margin-top: 8px;
    height: calc(99%);
    padding: 0px;
    margin-bottom: 5px;
`;

const WrappingP = styled.p`
    text-align: center;
`;

const TitleEmployeeStyle = styled.div`
    display: flex;
    align-items: stretch;
    //background-color: #f1f1f1;
`;

const selectEmployeeTask = (employeeId: string, employeeName: string, employeeSurname: string, workShift: string, centerId: string) =>
    EmployeeTaskSelectActions.select(employeeId, workShift, employeeName + ' ' + employeeSurname, centerId);

// const clearLaneDump = () => ;
export const PresenceManagementModal = connect<SProps, DProps, {}, AppStoreState>(state => ({employeeTaskSelect: EmployeeTaskSelectSelectors.getEmployeeTask(state)}), {
    selectEmployeeTask,
})(PresenceManagementModalBase);
