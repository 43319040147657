import * as React from 'react';
import {connect} from 'react-redux';

import {AreaSelectors} from 'src/app/lanemanager/lane/store/selectors';
import {AppStoreState} from 'src/store/interface';

import {TableModal} from 'src/shared/components/Modal';
import {CustomButton, CustomTextField, CustomDropdown} from 'src/shared/style';
import {Typography} from '@material-ui/core';
import {AreaControlServices} from '../administration/areaControl/store/services';
import {fetchWithFeedback} from 'src/utils/fetcherValidate';

export interface Props {
    employeeTaskId: string;
    taskId: string;
    areaId: string;
    centerId: string;
    createdAt: string;
    finishedAt: string;
    createdAtDay: string;
    finishedAtDay: string;
    open: boolean;
    centersList: Array<{id: string; name: string}>;
    tasksList: Array<{id: string; name: string}>;
    hidePresenceManagementModal: () => void;
    createActivity: (areaId: string, taskId: string, hourCreatedAt: string, hourFinishedAt: string, dayCreatedAt: string, dayFinishedAt: string) => void;
    editActivity: (employeeTaskId: string, areaId: string, taskId: string, hourCreatedAt: string, hourFinishedAt: string) => void;
}

type State = {
    openAlert: boolean;
    employeeTaskId: string;
    taskId: string;
    areaId: string;
    centerId: string;
    createdAt: string;
    finishedAt: string;
    createdAtDay: string;
    finishedAtDay: string;
    areasList: Array<{id: string; name: string}>;
};

const initialState: State = {
    openAlert: false,
    employeeTaskId: '',
    taskId: '',
    areaId: '',
    centerId: '',
    createdAt: '',
    finishedAt: '',
    createdAtDay: '',
    finishedAtDay: '',
    areasList: [],
};

export class ActivityModal extends React.Component<Props, State> {
    readonly state = initialState;

    componentWillReceiveProps(nextProps: any) {
        this.setState({employeeTaskId: nextProps.employeeTaskId});
        this.setState({taskId: nextProps.taskId});
        this.setState({areaId: nextProps.areaId});
        this.setState({centerId: nextProps.centerId});
        this.setState({createdAt: nextProps.createdAt});
        this.setState({finishedAt: nextProps.finishedAt});
        this.setState({createdAtDay: nextProps.createdAtDay});
        this.setState({finishedAtDay: nextProps.finishedAtDay});
        if (nextProps.centerId) {
            this.fetchAreas(nextProps.centerId);
        }
    }

    private hideModalTable = () => {
        this.props.hidePresenceManagementModal();
    };

    private fetchAreas = async (centerId: string) => {
        const areasList = await fetchWithFeedback(AreaControlServices.getAllAreasByCenter(centerId), {
            accessor: 'areas',
        });
        this.setState({areasList});
    };

    private handleChangeCenter: HandleChange = e => {
        this.setState({centerId: e.target.value});
        this.fetchAreas(e.target.value);
    };

    render() {
        const {centersList, tasksList} = this.props;
        const {areasList} = this.state;
        return (
            <>
                <TableModal
                    title={this.state.employeeTaskId !== '' ? 'Editar' : 'Crear'}
                    open={this.props.open}
                    handleClose={this.hideModalTable}
                    draggable={true}
                    maxWidth={false}
                >
                    <Typography
                        component="div"
                        style={{
                            width: 600,
                        }}
                    >
                        <Typography
                            component="div"
                            style={{
                                marginBottom: 10,
                                marginTop: 5,
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                        >
                            <div style={{flexGrow: 5}}>
                                <Typography
                                    component="div"
                                    style={{
                                        width: '90%',
                                    }}
                                >
                                    <CustomDropdown
                                        title={'Centro'}
                                        dropdownName={'centers'}
                                        data={centersList || []}
                                        value={this.state.centerId}
                                        margin={'0'}
                                        handleChoose={this.handleChangeCenter}
                                    />
                                </Typography>
                            </div>
                            <div style={{flexGrow: 7, marginRight: '0px'}}>
                                <Typography
                                    component="div"
                                    style={{
                                        width: '90%',
                                    }}
                                >
                                    <CustomDropdown
                                        title={'Área'}
                                        dropdownName={'areas'}
                                        data={areasList || []}
                                        value={this.state.areaId}
                                        margin={'0'}
                                        handleChoose={e => this.setState({areaId: e.target.value})}
                                    />
                                </Typography>
                            </div>
                        </Typography>
                        <Typography
                            component="div"
                            style={{
                                marginBottom: 10,
                                marginTop: 5,
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                        >
                            <Typography
                                component="div"
                                style={{
                                    width: '95%',
                                    marginBottom: 5,
                                }}
                            >
                                <CustomDropdown
                                    title={'Tarea'}
                                    dropdownName={'tasks'}
                                    data={tasksList || []}
                                    value={this.state.taskId}
                                    margin={'0'}
                                    handleChoose={e => this.setState({taskId: e.target.value})}
                                />
                            </Typography>
                        </Typography>

                        {this.state.employeeTaskId === '' && (
                            <>
                                <Typography
                                    component="div"
                                    style={{
                                        marginBottom: 10,
                                        marginTop: 5,
                                        width: 500,
                                        display: 'flex',
                                        flexDirection: 'row',
                                        marginLeft: 'auto',
                                        marginRight: 'auto',
                                    }}
                                >
                                    <div style={{width: '45%'}}>
                                        <CustomTextField
                                            margin={'0 auto 10px auto'}
                                            width={'100%'}
                                            label="Fecha inicio"
                                            error={false}
                                            value={this.state.createdAtDay}
                                            name="created_at_day"
                                            variant={'outlined'}
                                            type="date"
                                            shrink={true}
                                            onChange={e => this.setState({createdAtDay: e.target.value})}
                                        />
                                    </div>
                                    <div style={{width: '45%'}}>
                                        <CustomTextField
                                            margin={'0 30px 10px 30px'}
                                            width={'100%'}
                                            label="Fecha Fin"
                                            error={false}
                                            value={this.state.finishedAtDay}
                                            name="finished_at_day"
                                            variant={'outlined'}
                                            type="date"
                                            shrink={true}
                                            onChange={e => this.setState({finishedAtDay: e.target.value})}
                                        />
                                    </div>
                                </Typography>
                            </>
                        )}
                        <Typography
                            component="div"
                            style={{
                                marginBottom: 10,
                                marginTop: 5,
                                width: 500,
                                display: 'flex',
                                flexDirection: 'row',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                            }}
                        >
                            <div style={{width: '45%'}}>
                                <CustomTextField
                                    margin={'0 auto 10px auto'}
                                    width={'100%'}
                                    label="Hora inicio"
                                    error={false}
                                    value={this.state.createdAt}
                                    name="created_at"
                                    variant={'outlined'}
                                    type="time"
                                    shrink={true}
                                    onChange={e => this.setState({createdAt: e.target.value})}
                                />
                            </div>
                            <div style={{width: '45%'}}>
                                <CustomTextField
                                    margin={'0 30px 10px 30px'}
                                    width={'100%'}
                                    label="Hora Fin"
                                    error={false}
                                    value={this.state.finishedAt}
                                    name="finished_at"
                                    variant={'outlined'}
                                    type="time"
                                    shrink={true}
                                    onChange={e => this.setState({finishedAt: e.target.value})}
                                />
                            </div>
                        </Typography>
                        <div style={{display: 'flex'}}>
                            <CustomButton
                                width={'25%'}
                                margin={'0px 0px 0px auto'}
                                onClick={() => {
                                    if (this.state.employeeTaskId !== '') {
                                        this.props.editActivity(this.state.employeeTaskId, this.state.areaId, this.state.taskId, this.state.createdAt, this.state.finishedAt);
                                    } else {
                                        this.props.createActivity(
                                            this.state.areaId,
                                            this.state.taskId,
                                            this.state.createdAt,
                                            this.state.finishedAt,
                                            this.state.createdAtDay,
                                            this.state.finishedAtDay,
                                        );
                                    }
                                }}
                                padding={'3px'}
                            >
                                {'Guardar'}
                            </CustomButton>
                        </div>
                    </Typography>
                </TableModal>
            </>
        );
    }
}

const mapStateToProps = (state: AppStoreState) => ({
    ptables: AreaSelectors.getPtables(state),
    auto_palletise: !!state.laneSelect.AutoPalletise,
});

export const ActivityModalConnected = connect(mapStateToProps, null)(ActivityModal);
