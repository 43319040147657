import differenceInMinutes from 'date-fns/difference_in_minutes';
import debounce from 'lodash/debounce';
import * as React from 'react';
import {connect} from 'react-redux';
import Select from 'react-select';
import animated from 'react-select/lib/animated';
import AsyncSelect from 'react-select/lib/Async';
import styled from 'styled-components';
import {AreaControlServices} from 'src/app/administration/areaControl/store/services';
import {WrapSaveButton} from 'src/app/administration/export/styled/Wrap';
import {TasksControlServices} from 'src/app/administration/tasksControl/store/tasksControl.services';
import {TCTaskResponse} from 'src/app/administration/tasksControl/store/tasksControl.types';
import {LaneSelectSelectors} from 'src/app/laneSelect/store';
import {LoginSelectors} from 'src/app/login/store/login.selectors';
import {PersonalServices} from 'src/app/lanemanager/personal/store/personal.services';
import {PersonalUnit} from 'src/app/lanemanager/personal/store/personal.types';
import {withStyles, Badge, Button, Checkbox} from '@material-ui/core';
import ChangeIcon from '@material-ui/icons/ArrowForward';
import CancelIcon from '@material-ui/icons/Cancel';
import StopIcon from '@material-ui/icons/Stop';
import AddIcon from '@material-ui/icons/Add';
import {TableModal} from 'src/shared/components/Modal';
import {TableComponent} from 'src/shared/components/TableComponent';
import {CustomButton} from 'src/shared/style';
import {IsInRuleset} from 'src/global/authorization/grantSet';
import {AppStoreState} from 'src/store';
import {ColumnType} from 'src/types';
import {fetchWithFeedback} from 'src/utils/fetcherValidate';
import {EmployeesControlServices} from 'src/app/administration/employeesControl/store/employeesControl.services';
import {DevicesControlServices} from 'src/app/administration/devicesControl/store/devicesControl.services';
import {CardControlServices} from 'src/app/administration/cardControl/store/services';
import {Spinner} from '../../global/spinner';
import {customFormatNumber} from 'src/shared/functions/FormatHelpers';
import {ParameterCPN} from 'src/types/app_types';
import {ParametersSelectors} from 'src/utils/Parameters/selectors';
import {notify} from "../../../utils/Notification";

//REVIEW: El Select y el AsyncSelect, imprimen el mismo id (se ven 2 elementos con el mismo id)
const MANUFACTURING_COLOR = '#FFFFFF';
const DIRECT_COLOR = '#F9F9F9';
const INDIRECT_COLOR = '#F8C1BB';

const MANUFACTURING_CODE = 2;
const DIRECT_CODE = 1;
const INDIRECT_CODE = 0;

type SProps = {
    lane: ReturnType<typeof LaneSelectSelectors.getLane>;
    userData: ReturnType<typeof LoginSelectors.getUserData>;
    employeeCodeModeParam?: String;
};
type ComponentProps = SProps;

type State = {
    tasks_array: string[];
    selectedEmployees: string[];
    tasksList: TCTaskResponse['tasks'];
    selectedArea?: {value: string; label: string} | null;
    selectedDevice?: {value: string; label: string};
    selectedCard?: {value: string; label: string};
    selectedTask?: {value: string; label: string};
    selectedNewTask?: {value: string; label: string} | null;
    openChange: boolean;
    openStop: boolean;
    openNew: boolean;
    areasList: Array<{id: string; name: string}>;
    devicesList: Array<{id: string; name: string}>;
    cardsList: Array<{id: string; code: string}>;
    massSelect: boolean;
    selectedEmployee?: {label: string; value: string};
    tasksNewList: Array<{id: string; name: string}>;
    isLoadingGetTask: boolean;
    isLoadingCreateTask: boolean;
};

const initialState: Readonly<State> = {
    tasks_array: [],
    selectedEmployees: [],
    tasksList: [],
    openChange: false,
    openStop: false,
    openNew: false,
    areasList: [],
    cardsList: [],
    devicesList: [],
    tasksNewList: [],
    massSelect: false,
    isLoadingGetTask: false,
    isLoadingCreateTask: false,
};

class PersonalBase extends React.Component<ComponentProps, State> {
    readonly state = initialState;
    private data: any = [];
    private debounced = debounce((fn: () => any) => fn(), 400);
    private tableHook: () => void = () => ({});
    private getTableHook = (fn: () => void) => (this.tableHook = fn);
    private useTableHook = () => {
        if (typeof this.tableHook === 'function') this.tableHook();
    };
    private showErpEmployeeCode = this.props.employeeCodeModeParam === 'ERP_MODE';
    private showInternalEmployeeCode = this.props.employeeCodeModeParam === 'INTERNAL_MODE';
    async componentDidMount() {
        await IsInRuleset(['LANEMANAGER_COMMON_TOTAL', 'LANEMANAGER_PERSONNEL_TOTAL']);
        await this.fetchAreas();
        await this.fetchTasks();
        if (this.props.lane)
            this.setState({
                selectedArea: {value: this.props.lane.laneId, label: this.props.lane.laneName},
            });
    }

    private handleOpen = (name: string) => {
        const {selectedEmployees} = this.state;
        switch (name) {
            case 'change':
                if (selectedEmployees.length > 0) this.setState({openChange: true, selectedTask: undefined});
                break;
            case 'new':
                if (this.props.lane)
                    this.setState({
                        selectedArea: {
                            value: this.props.lane.laneId,
                            label: this.props.lane.laneName,
                        },
                        selectedEmployee: undefined,
                        selectedNewTask: null,
                        tasksList: [],
                    });
                this.setState({
                    openNew: true,
                    selectedDevice: undefined,
                    selectedCard: undefined,
                });
                break;
            case 'stop':
                if (selectedEmployees.length > 0)
                    this.setState({
                        openStop: true,
                        selectedTask: undefined,
                        selectedArea: undefined,
                    });
                break;
            default:
                break;
        }
    };

    private handleClose = (name: string) => {
        switch (name) {
            case 'change':
                this.setState({openChange: false, selectedTask: undefined});
                break;
            case 'new':
                this.setState({
                    openNew: false,
                    selectedEmployee: undefined,
                    selectedDevice: undefined,
                    selectedCard: undefined,
                    tasksList: [],
                });
                break;
            case 'stop':
                this.setState({
                    openStop: false,
                    selectedTask: undefined,
                    selectedArea: undefined,
                });
                break;
            default:
                break;
        }
    };

    private fetchTasks = async () => {
        const tasksList = await fetchWithFeedback(TasksControlServices.getAll(), {
            accessor: 'tasks',
        });
        this.setState({tasksList: tasksList || []});
    };

    private fetchNewTasks = () => {
        if (this.state.selectedArea && this.state.selectedEmployee) {
            this.setState({isLoadingGetTask: true});
            fetchWithFeedback(
                TasksControlServices.getTaskAvailables({
                    areaId: this.state.selectedArea.value,
                    employeeId: this.state.selectedEmployee.value,
                }),
                {
                    accessor: 'tasks',
                },
            )
                .then(tasksNewList => {
                    this.setState({tasksNewList: tasksNewList || [], isLoadingGetTask: false});
                })
                .catch(() => this.setState({isLoadingGetTask: false}));
        }
    };

    private fetchAreas = () => {
        fetchWithFeedback(AreaControlServices.getAllAreas(), {accessor: 'areas'}).then(areasList => {
            this.setState({areasList: areasList || []});
        });
    };

    private fetchCards = async (employeeId: string) => {
        const cardsList = await fetchWithFeedback(CardControlServices.getByEmployeeId(employeeId), {
            accessor: undefined,
        });
        this.setState({cardsList: cardsList || []});
    };

    private fetchDevices = async (areaId: string) => {
        const devicesList = await fetchWithFeedback(DevicesControlServices.getByAreaId(areaId), {
            accessor: 'devices',
        });
        this.setState({devicesList: devicesList || []});
    };

    private finishTasks = async (id?: string) => {
        const {selectedEmployees} = this.state;
        if (this.props.userData && (this.state.tasks_array.length || id)) {
            await fetchWithFeedback(
                PersonalServices.finishTasks({
                    employee_id: this.props.userData.employeeId,
                    employees_to_finish: id && selectedEmployees.length === 0 ? [id] : selectedEmployees,
                }),
                {showMessage: true},
            );
            this.setState({
                selectedEmployees: [],
                openStop: false,
                openNew: false,
                openChange: false,
                massSelect: false,
            });
            this.useTableHook();
        }
    };

    private handleSelect = (employee: PersonalUnit) => {
        if (!employee) return;
        const {id} = employee;
        let selectedEmployees = this.state.selectedEmployees;
        const exist = selectedEmployees.find(it => it === id);
        if (exist) {
            selectedEmployees = selectedEmployees.filter(it => it !== id);
        } else {
            selectedEmployees = selectedEmployees.concat(employee.id);
        }
        this.setState({selectedEmployees, massSelect: false, selectedEmployee: undefined});
    };

    private forwardToTask = async () => {
        const {selectedEmployees, selectedTask, selectedArea} = this.state;
        if (selectedEmployees.length && selectedTask) {
            await fetchWithFeedback(
                PersonalServices.changeToTask({
                    employees_to_change: selectedEmployees,
                    task_id: selectedTask.value,
                    area_id: selectedArea ? selectedArea.value : this.props.lane.laneId,
                }),
                {showMessage: true},
            );
            this.setState({
                selectedEmployees: [],
                openStop: false,
                openNew: false,
                openChange: false,
                massSelect: false,
            });
            this.useTableHook();
        }
    };

    private fireSearch = (inputValue: string) => {
        return new Promise(res => {
            this.debounced(async () => {
                const result = await this.searchFunction(inputValue);
                if (result) res(result);
            });
        });
    };

    private searchFunction = async (inputValue: string): Promise<Array<{value: string; label: string}> | void> => {
        let options: any[] = [];
        let result: any[] = [];

        if (!isNaN(+inputValue)) {
            result = await this.searchCall('code', inputValue);
            options = options.concat(result);
        } else {
            result = await this.searchCall('name', inputValue);
            options = options.concat(result);
            result = await this.searchCall('surname', inputValue);
            options = options.concat(result.filter(op => options.findIndex(res => res.value === op.value) < 0));
        }
        return options;
    };

    private searchCall = async (key: string, inputValue: string) => {
        const response = await fetchWithFeedback(
            EmployeesControlServices.getAllEmployees({
                params: {page: 1, 'page-size': 9999, filtered: {[key]: inputValue}, sorted: []},
            }),
        );
        if (response) {
            return response.data.map((dt: any) => ({
                label: `${dt.name} ${dt.surname} ${dt.code}`,
                value: dt.id,
            }));
        }
    };

    private selectAllFiltered = async () => {
        if (!this.state.massSelect) {
            this.setState({
                selectedEmployees: this.data.length ? this.data.map((d: any) => d.id) : [],
                massSelect: true,
            });
            // this.setState({selectedEmployees: mergedArray, massSelect: true});
        } else this.setState({selectedEmployees: [], massSelect: false});
    };

    private liftDataUp = (data: any) => {
        this.data = data;
    };
    //this.fetchDevices(this.props.lane.laneId)
    private onAreaSelect = (e: any) => {
        this.fetchDevices(e.value);
        this.setState({tasksNewList: []});
        this.setState({selectedArea: e});
    };

    private onEmployeeSelect = (e: any) => {
        if (e) this.fetchCards(e.value);
        else this.setState({selectedNewTask: null});
        this.setState({tasksNewList: [], selectedNewTask: null, selectedEmployee: e}, this.fetchNewTasks);
    };

    private handleSending = () => {
        this.setState({isLoadingCreateTask: true});
        const {selectedArea, selectedEmployee, selectedNewTask} = this.state;

        if (selectedArea && selectedEmployee && selectedNewTask) {
            // fetchWithFeedback(
            //     PersonalServices.createTask({
            //         area_id: selectedArea.value,
            //         employee_id: selectedEmployee.value,
            //         task_id: selectedNewTask.value,
            //     }),
            //     {
            //         successMessage: 'Se ha creado la actividad',
            //         errorMessage: 'Error al crear la actividad. Revisar las condiciones.',
            //     },
            // )
            //     .then(() => {
            //         this.useTableHook();
            //         this.setState({openNew: false, isLoadingCreateTask: false});
            //     })
            //     .catch(error => {
            //         this.setState({isLoadingCreateTask: false});
            //         console.error('Error al crear', error);
            //     });
            PersonalServices.createTask({
                area_id: selectedArea.value,
                employee_id: selectedEmployee.value,
                task_id: selectedNewTask.value,
            }).then((r)=> {
                if (r.status === 200) {
                    notify({
                        status: 'success',
                        message: 'Se ha creado la actividad',
                    });
                } else {
                    let message = 'Error al crear la actividad. Revisar las condiciones.';
                    console.log('PERSON', r.data);
                    if (r.data.message === 'EMPLOYEE_HAS_ACTIVE_TASK_IN_ANOTHER_CENTER') message = 'El empleado tiene una tarea activa en otro centro.';

                    notify({
                        status: 'error',
                        message: message,
                    });
                }
                this.useTableHook();
                this.setState({openNew: false, isLoadingCreateTask: false});
            }).catch((e)=>{
                this.setState({isLoadingCreateTask: false});
                console.error('Error al crear', e);
            });
        }
    };

    render() {
        const VIEW_ACCESS = IsInRuleset(['LANEMANAGER_PERSONNEL_VIEW', 'LANEMANAGER_COMMON_VIEW']);
        const TOTAL_ACCESS = IsInRuleset(['LANEMANAGER_PERSONNEL_TOTAL', 'LANEMANAGER_COMMON_TOTAL']);
        const {openChange, openStop, openNew, tasksList, selectedTask, selectedArea, areasList, selectedEmployee, selectedNewTask, tasksNewList} = this.state;
        const {lane} = this.props;
        const optionsAreaList = areasList ? areasList.map(t => ({value: t.id, label: t.name})) : [];
        const canCreateTask = this.state.selectedArea && this.state.selectedEmployee && this.state.selectedNewTask && !this.state.isLoadingCreateTask;
        return (
            (VIEW_ACCESS || TOTAL_ACCESS) && (
                <>
                    <TableModal open={openChange} handleClose={() => this.handleClose('change')} title={'Elegir la tarea del cambio'}>
                        <>
                            <div style={{margin: '3px 0 0 0'}}>
                                <Select
                                    backspaceRemovesValue={false}
                                    placeholder={'Cambiar al área...'}
                                    menuPlacement="bottom"
                                    styles={tasksListStyle}
                                    value={selectedArea}
                                    isMulti={false}
                                    closeMenuOnSelect={true}
                                    isClearable={false}
                                    // @ts-ignore
                                    components={animated()}
                                    options={areasList ? areasList.map(t => ({value: t.id, label: t.name})) : []}
                                    onChange={sel => this.setState({selectedArea: sel as any})}
                                />
                            </div>
                            <div style={{margin: '10px 0 20px 0'}}>
                                <Select
                                    backspaceRemovesValue={false}
                                    placeholder={'Cambiar a la tarea...'}
                                    menuPlacement="bottom"
                                    styles={tasksListStyle}
                                    value={selectedTask}
                                    isMulti={false}
                                    closeMenuOnSelect={true}
                                    isClearable={false}
                                    // @ts-ignore
                                    components={animated()}
                                    options={tasksList ? tasksList.filter(t => !t.is_downtime).map(t => ({value: t.id, label: t.name})) : []}
                                    onChange={sel => this.setState({selectedTask: sel as any})}
                                />
                            </div>
                            <WrapSaveButton>
                                <CustomButton onClick={this.forwardToTask} width={'100%'} margin="unset">
                                    Cambiar
                                </CustomButton>
                            </WrapSaveButton>
                        </>
                    </TableModal>
                    <TableModal open={openStop} handleClose={() => this.handleClose('stop')} title={'Elegir la tarea de la parada'}>
                        <>
                            <div style={{margin: '3px 0 20px 0'}}>
                                <Select
                                    backspaceRemovesValue={false}
                                    placeholder={'Parar a la tarea...'}
                                    menuPlacement="bottom"
                                    styles={tasksListStyle}
                                    value={selectedTask}
                                    isMulti={false}
                                    closeMenuOnSelect={true}
                                    isClearable={false}
                                    // @ts-ignore
                                    components={animated()}
                                    options={tasksList ? tasksList.filter(t => t.is_downtime).map(t => ({value: t.id, label: t.name})) : []}
                                    onChange={sel => this.setState({selectedTask: sel as any})}
                                />
                            </div>
                            <WrapSaveButton>
                                <CustomButton onClick={this.forwardToTask} width={'100%'} margin="unset">
                                    Cambiar
                                </CustomButton>
                            </WrapSaveButton>
                        </>
                    </TableModal>
                    <TableModal open={openNew} handleClose={() => this.handleClose('new')} title={'Crear tarea'} fullWidth={true} maxWidth={'md'}>
                        <div>
                            <div style={{margin: '0px auto 10px auto'}}>
                                <Select
                                    value={selectedArea}
                                    backspaceRemovesValue={false}
                                    placeholder={'Seleccionar área...'}
                                    menuPlacement="bottom"
                                    styles={tasksListStyle}
                                    isMulti={false}
                                    closeMenuOnSelect={true}
                                    isClearable={false}
                                    // @ts-ignore
                                    components={animated()}
                                    options={optionsAreaList}
                                    onChange={this.onAreaSelect}
                                />
                            </div>
                            <div style={{margin: '0px auto 10px auto'}}>
                                <SelectionContainer>
                                    <AsyncSelect
                                        backspaceRemovesValue={false}
                                        closeMenuOnSelect={true}
                                        isClearable={true}
                                        //menuPlacement="top"
                                        // @ts-ignore
                                        components={animated()}
                                        cacheOptions={true}
                                        placeholder={'Escribir nombre de empleado...'}
                                        value={selectedEmployee}
                                        loadOptions={this.fireSearch}
                                        onChange={this.onEmployeeSelect}
                                    />
                                </SelectionContainer>
                            </div>
                            <div style={{margin: '0px auto 40px auto'}}>
                                <Select
                                    placeholder={'Seleccionar tarea...'}
                                    menuPlacement="bottom"
                                    value={selectedNewTask}
                                    isMulti={false}
                                    closeMenuOnSelect={true}
                                    isClearable={true}
                                    // @ts-ignore
                                    components={animated()}
                                    isLoading={this.state.isLoadingGetTask}
                                    options={tasksNewList ? tasksNewList.map(t => ({value: t.id, label: t.name})) : []}
                                    onChange={sel => this.setState({selectedNewTask: sel as any})}
                                />
                            </div>
                            <WrapSaveButton>
                                <CustomButton onClick={this.handleSending} width={'100%'} margin="unset" disabled={!canCreateTask}>
                                    Crear
                                </CustomButton>
                            </WrapSaveButton>
                            <Spinner isLoading={this.state.isLoadingCreateTask} />
                        </div>
                    </TableModal>
                    <TableComponent
                        autorefresh={true}
                        autorefreshDelay={20000}
                        defaultPageSize={20}
                        columnFormat={this.columns}
                        serviceArgs={[lane.laneId]}
                        service={PersonalServices.getByArea}
                        accessor={'employees'}
                        pdf={true}
                        loading={true}
                        getTableFetchHook={this.getTableHook}
                        dataHook={this.liftDataUp}
                        defaultSorted={[{id: 'taskType', desc: true}]}
                        additionalButtons={
                            TOTAL_ACCESS
                                ? [
                                      {
                                          icon: <AddIcon />,
                                          onClick: () => this.handleOpen('new'),
                                          tooltipText: 'Crear una tarea',
                                      },
                                      {
                                          icon: <StopIcon />,
                                          onClick: () => this.handleOpen('stop'),
                                          tooltipText: 'Cambiar a una tarea de parada',
                                      },
                                      {
                                          icon: <ChangeIcon />,
                                          onClick: () => this.handleOpen('change'),
                                          tooltipText: 'Cambiar a una tarea',
                                      },
                                      {
                                          icon: <CancelIcon />,
                                          onClick: this.finishTasks,
                                          tooltipText: 'Finalizar las tareas seleccionadas',
                                      },
                                  ]
                                : undefined
                        }
                        propsToOwnTable={{
                            getTrProps: (_: any, rowInfo: any) => ({
                                onClick: (evt: any) => {
                                    if (evt.target.type !== 'button' && TOTAL_ACCESS) this.handleSelect(rowInfo.original);
                                },
                                style: {
                                    cursor: 'pointer',
                                    background: this.colorRow(rowInfo),
                                },
                            }),
                        }}
                    />
                </>
            )
        );
    }

    private colorRow(rowInfo: any) {
        if (rowInfo) {
            if (rowInfo.original.taskType === INDIRECT_CODE) {
                return INDIRECT_COLOR;
            } else if (rowInfo.original.taskType === DIRECT_CODE) {
                return DIRECT_COLOR;
            } else if (rowInfo.original.taskType === MANUFACTURING_CODE) {
                return MANUFACTURING_COLOR;
            } else {
                return;
            }
        } else return;
    }

    private columns: ColumnType<PersonalUnit> = [
        {
            Header: 'Selección',
            headerClassName: 'header_cell_string',
            filterable: true,
            Filter: () => {
                return (
                    <div style={{textAlign: 'left'}}>
                        <CustomBadge display={true} color="primary" content={'' + this.state.selectedEmployees.length}>
                            <Checkbox
                                value="activeCB"
                                disabled={!IsInRuleset(['LANEMANAGER_PERSONNEL_TOTAL', 'LANEMANAGER_COMMON_TOTAL'])}
                                checked={this.state.massSelect}
                                style={{padding: 2}}
                                onClick={() => this.selectAllFiltered()}
                            />
                        </CustomBadge>
                    </div>
                );
            },
            Cell: info => (
                <>
                    <Checkbox
                        disabled={!IsInRuleset(['LANEMANAGER_PERSONNEL_TOTAL', 'LANEMANAGER_COMMON_TOTAL'])}
                        checked={!!this.state.selectedEmployees.find(it => it === info.original.id)}
                        value="activeCB"
                        style={{padding: 10}}
                    />
                </>
            ),
            sortable: false,
            width: 80,
            centerCell: true,
        },
        {
            Header: 'Código empleado',
            headerClassName: 'header_cell_string',
            accessor: 'code',
            show: this.showInternalEmployeeCode,
            width: 170,
            className: 'cell_string',
            filterable: true,
        },
        {
            Header: 'Código empleado',
            headerClassName: 'header_cell_string',
            accessor: 'erp_employee_code',
            show: this.showErpEmployeeCode,
            width: 170,
            className: 'cell_string',
            filterable: true,
        },
        {
            Header: 'Foto',
            Cell: item =>
                item.original.profileUrl ? (
                    <img
                        style={{
                            height: 100,
                            margin: 0,
                            objectFit: 'scale-down',
                        }}
                        alt={'img'}
                        src={item.original.profileUrl}
                    />
                ) : null,
            centerCell: true,
            sortable: false,
        },
        {
            Header: 'Nombre',
            headerClassName: 'header_cell_string',
            accessor: 'name',
            className: 'cell_string',
            filterable: true,
        },
        {
            Header: 'Apellidos',
            headerClassName: 'header_cell_string',
            accessor: 'surname',
            className: 'cell_string',
            filterable: true,
        },
        {
            Header: 'Tarea',
            headerClassName: 'header_cell_string',
            accessor: 'taskName',
            className: 'cell_string',
            filterable: true,
        },
        {
            Header: 'Tipo Tarea',
            headerClassName: 'header_cell_string',
            accessor: 'taskType',
            Cell: item => <>{this.getValuesDirect(item.original.taskType)}</>,
            className: 'cell_string',
            filterable: false,
        },
        {
            Header: 'Tiempo en tarea',
            headerClassName: 'header_cell_number',
            accessor: 'task_time',
            width: 130,
            Cell: item => {
                const startDate = new Date(item.original.created_at);
                const now = new Date();
                const time = differenceInMinutes(now, startDate);
                return <>{customFormatNumber(time)} m</>;
            },
            sortable: false,
            filterable: false,
            className: 'cell_number',
        },
        {
            Header: 'Cajas',
            headerClassName: 'header_cell_number',
            accessor: 'boxesCount',
            Cell: item => <>{customFormatNumber(item.original.boxesCount)}</>,
            sortable: true,
            width: 80,
            className: 'cell_number',
        },
        {
            Header: 'Finalizar tarea',
            headerClassName: 'header_cell_string',
            sortable: false,
            width: 200,
            Cell: item => (
                <Button
                    disabled={!IsInRuleset(['LANEMANAGER_PERSONNEL_TOTAL', 'LANEMANAGER_COMMON_TOTAL'])}
                    variant="contained"
                    color="primary"
                    onClick={() => this.finishTasks(item.original.id)}
                >
                    Finalizar tarea
                </Button>
            ),
            centerCell: true,
        },
    ];

    getValuesDirect(itemDirect: number) {
        if (itemDirect === DIRECT_CODE) {
            return 'DIRECTA';
        }

        if (itemDirect === INDIRECT_CODE) {
            return 'INDIRECTA';
        }

        if (itemDirect === MANUFACTURING_CODE) {
            return 'ENVASADO';
        }

        return '';
    }
}

export const Personal = connect<SProps, {}, {}, AppStoreState>(state => ({
    lane: LaneSelectSelectors.getLane(state),
    userData: LoginSelectors.getUserData(state),
    employeeCodeModeParam: ParametersSelectors.getValueOfParameter(state, ParameterCPN.EMPLOYEE_CODE_MODE),
}))(PersonalBase);

/****  BADGE */

type BadgeProps = {
    display: boolean;
    content?: string;
    margin?: string;
    classes?: any;
    color?: 'primary' | 'secondary' | 'default' | 'error';
    style?: {[k: string]: any};
};

const styles = {
    badge: {
        top: 14,
        padding: 3,
        right: -16,
        width: 20,
        height: 20,
    },
};

const CustomBadgeBase: React.FunctionComponent<BadgeProps> = props => {
    return (
        <div
            style={{
                margin: props.margin,
            }}
        >
            <Badge component={'div'} classes={{badge: props.classes.badge}} invisible={!props.display} badgeContent={props.content || ''} color={props.color || 'primary'}>
                {props.children}
            </Badge>
        </div>
    );
};

const CustomBadge = withStyles(styles)(CustomBadgeBase);

const tasksListStyle = {
    menu: (provided: any) => ({
        ...provided,
        height: 80,
    }),
    menuList: (provided: any) => ({
        ...provided,
        height: 80,
    }),
};

const SelectionContainer = styled.div`
    height: 40%;
`;

// const TableComponentWrap = styled.div`
//     height: 100%;
// `;
