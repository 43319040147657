import * as React from 'react';

import {LanePtableState} from 'src/app/lanemanager/lane/store/types';

import {
  Content,
  CustomTable,
  Footer,
  FooterRight,
  Pallet,
  PalletCell,
  Title,
} from './Wrap';

export type TableProps = LanePtableState & {
  onHandleClickTable?: (ptableSelected: LanePtableState) => void;
};
export const Table: React.FunctionComponent<TableProps> = props => {
  const {state, name, pallet, onHandleClickTable} = props;
  if (name && state)
    return (
      <CustomTable>
        <Content
          onClick={() => {
            if (!onHandleClickTable) return;
            onHandleClickTable({...props});
          }}
          style={{cursor: 'pointer'}}>
          <Title>
            {name || 'MP X'}
            {/* {assigned_to || reserved_to ? 'asignada' : 'sin asignar'} */}
          </Title>
          <Pallet>
            <PalletCell>
              {(pallet && pallet.boxes_count) || '-'}/
              {(pallet && pallet.boxes_total) || '-'}
            </PalletCell>
          </Pallet>
          <Footer>
            <FooterRight state={state}>
              <span style={{textAlign: 'center'}}>{state}</span>
            </FooterRight>
          </Footer>
        </Content>
      </CustomTable>
    );
  else return <div>Error en los datos.</div>;
};
