import {
  LaneSelectActionClear,
  LaneSelectActionSelect,
  LaneSelectActionTypes,
} from 'src/app/laneSelect/store/laneSelect.action-types';

export const LaneSelectActions = {
  select: (
    lane: string,
    name: string,
    dumpBarcode: string,
    AutoPalletise: boolean,
  ): LaneSelectActionSelect => ({
    type: LaneSelectActionTypes.select,
    payload: {laneId: lane, laneName: name, laneDumpBarcode: dumpBarcode, AutoPalletise},
  }),
  clear: (): LaneSelectActionClear => ({type: LaneSelectActionTypes.clear}),
};
