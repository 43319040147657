import React, {FunctionComponent} from 'react';
import ReactTable, {RowInfo} from 'react-table';
import {PalletsServices} from 'src/app/lanemanager/pallets/store/services';
import {Pallet} from 'src/app/lanemanager/pallets/store/types';
import {PaginatedTableComponent} from 'src/shared/components/PaginatedTableComponent';
import {ColumnType} from 'src/types';
import {connect} from 'react-redux';
import {LaneSelectSelectors} from 'src/app/laneSelect/store';
import {AppStoreState} from 'src/store';
import {customFormatNumber} from 'src/shared/functions/FormatHelpers';

type SProps = {
    lane: ReturnType<typeof LaneSelectSelectors.getLane>;
};

export const PalletsBase: FunctionComponent<SProps> = props => {
    const columns: ColumnType<Pallet> = [
        {
            Header: 'CÓDIGO PALÉ',
            headerClassName: 'header_cell_string',
            accessor: 'palletBarcode',
            filterable: true,
            sortable: false,
            minWidth: 130,
            className: 'cell_string',
        },
        {
            Header: 'PEDIDO',
            headerClassName: 'header_cell_string',
            accessor: 'erp_order_code',
            filterable: true,
            sortable: false,
            className: 'cell_string',
        },
        {
            Header: 'PARTE',
            headerClassName: 'header_cell_string',
            accessor: 'erp_prodpart_code',
            filterable: true,
            sortable: false,
            className: 'cell_string',
        },
        {
            Header: 'Área',
            headerClassName: 'header_cell_string',
            accessor: 'area_name',
            filterable: true,
            sortable: false,
            className: 'cell_string',
        },
        {
            Header: 'Centro',
            headerClassName: 'header_cell_string',
            accessor: 'center_name',
            filterable: true,
            sortable: true,
            className: 'cell_string',
            //style: {textAlign: 'center', whiteSpace: 'normal', alignSelf: 'center'},
        },
        {
            Header: 'MESA',
            headerClassName: 'header_cell_string',
            accessor: 'ptable_name',
            filterable: true,
            sortable: false,
            className: 'cell_string',
        },
        {
            Header: 'CAJAS',
            headerClassName: 'header_cell_number',
            accessor: 'boxes_count',
            Cell: item => (
                <>
                    {customFormatNumber(item.original.boxes_count)}/{customFormatNumber(item.original.boxes_total)}
                </>
            ),
            className: 'cell_number',
        },

        {
            Header: 'H. INICIO',
            headerClassName: 'header_cell_string',
            accessor: 'started_at',
            dateTimeFormat: true,
            //className: 'cell_string',
            className: 'cell_string',
        },
        {
            Header: 'H. FIN',
            headerClassName: 'header_cell_string',
            accessor: 'finished_at',
            dateTimeFormat: true,
            className: 'cell_string',
        },
        {
            Header: 'UL FINAL',
            headerClassName: 'header_cell_string',
            accessor: 'erp_logisticunit_code',
            Cell: item => <>{item.original.erp_logisticunit_code ? item.original.erp_logisticunit_code : 'Sin asignar todavía'}</>,
            filterable: true,
            sortable: false,
            className: 'cell_string',
        },
        {
            Header: 'ORIGENES',
            headerClassName: 'header_cell_string',
            accessor: 'batches',
            minWidth: 70,
            maxWidth: 70,
            width: 70,
            expander: true,
            style: {
                cursor: 'pointer',
                fontSize: 25,
                padding: '0',
                textAlign: 'center',
                userSelect: 'none',
            },
        },
    ];

    return (
        <PaginatedTableComponent
            service={PalletsServices.getAllPaginated}
            serviceArgs={props.lane.laneId}
            columnFormat={columns}
            defaultPageSize={20}
            loading={true}
            propsToOwnTable={{
                SubComponent: (info: RowInfo) => {
                    const data = info.original;
                    return (
                        <ReactTable
                            data={data.batches}
                            showPagination={false}
                            minRows={0}
                            pageSize={data.batches.length}
                            resizable={false}
                            getNoDataProps={() => {
                                if (!data.batches.length) return {style: {display: 'none'}};
                                return {};
                            }}
                            sortable={false}
                            columns={[
                                {
                                    Header: '',
                                    headerClassName: 'subheader_cell_string',
                                    accessor: 'white-space',
                                    className: 'subcell_string',
                                    width: 800,
                                },
                                {
                                    Header: 'Código ERP del origen',
                                    headerClassName: 'subheader_cell_string',
                                    accessor: 'erp_batch_code',
                                    className: 'subcell_string',
                                },
                                {
                                    Header: 'Cajas del origen',
                                    headerClassName: 'subheader_cell_number',
                                    accessor: 'boxes',
                                    Cell: item => <>{customFormatNumber(item.original.boxes)}</>,
                                    className: 'subcell_number',
                                },
                            ]}
                        />
                    );
                },
            }}
        />
    );
};

export const Pallets = connect<SProps, {}, {}, AppStoreState>(state => ({
    lane: LaneSelectSelectors.getLane(state),
}))(PalletsBase);
