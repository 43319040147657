import React from "react";
import {
    BatchTransType,
    TransBatchType,
    TransferBoxesType,
    TransferOriginPalletType,
    TransferPalletType
} from "./store/liftPalletsControl.types";
import {
    Button,
    Card,
    CardContent, CircularProgress,
    createStyles,
    Grid,
    List,
    //ListItemIcon, ListItemText,
    Tooltip,
    Typography,
    withStyles
} from "@material-ui/core";
import ArrowIcon from "@material-ui/icons/ArrowForward";
import AddButtom from "@material-ui/icons/AddCircle";
import ClearButton from "@material-ui/icons/ClearAll";
import IconButton from "@material-ui/core/IconButton";
import RemoveButton from "@material-ui/icons/Clear";
import {ListItem} from "@material-ui/core/es";
import {GetPalletModal} from "../../lanemanager/lane/components/PalletsInfo/GetPalletModal";
import {CreatePalletModal} from "./CreatePalletModal";
import {NotifyActionType} from "../../../utils/Notification/action-types";
import {Advertisement} from "../../../shared/components/Advertisement";
import {PalletInformationServices} from "../../lanemanager/palletInformation/store/palletInformation.services";
import {PalletsServices} from 'src/app/lanemanager/pallets/store/services';
import {PalletInfoUnit} from "../../lanemanager/palletInformation/store/palletInformation.types";
import {TransferBoxesDialog} from "./TransferBoxesDialog";
import {LiftPalletsControlService} from "./store/liftPalletsControl.service";
import {notify} from "../../../utils/Notification";


type Props = {
    classes : any
};


type State = {
    requestPalletId: 'origin' | 'dest' | '';
    originPalletList: Map<string, TransferPalletType>;
    showAd: boolean;
    destPallet: PalletInfoUnit | null;
    isLoading: boolean;
    loaderMessage : string;
    tempOriginPallet: TransferPalletType | null;
    maxBoxToTransfer: number;
    showCreatePallet: boolean;
};

const initialState: State = {
    requestPalletId: '',
    originPalletList: new Map<string, TransferPalletType>(),
    showAd: false,
    destPallet: null,
    isLoading: false,
    loaderMessage : '',
    tempOriginPallet: null,
    maxBoxToTransfer: 0,
    showCreatePallet: false
};

export class LiftPalletsControlBase extends React.Component<Props, State> {
    readonly GET_PALLET_MSG = 'Obteniendo información del palé...';
    readonly CREATE_PALLET_MSG = 'Creando palé...';
    readonly TRANSFER_BOXES_MSG = 'Transfiriendo cajas...';

    readonly state = initialState;
    private adMessage = '';

    componentWillUnmount() {
        this.state.originPalletList.clear();
        this.setState(initialState);
    }

    private setLoading = (msg : string) => {
        this.setState({
            isLoading : true,
            loaderMessage : msg
        })
    }

    private unsetLoading = () => {
        this.setState({
            isLoading : false,
            loaderMessage : ''
        })
    }

    private handleSelectDestPallet = () => {
        this.setState({
            requestPalletId : 'dest'
        })
    }

    private handleCreatePallet = () => {
        this.setState({
            showCreatePallet : true
        })
    }

    private handleAddOriginPallet = () => {
        this.setState({
            requestPalletId : 'origin'
        })
    }

    private handleClearOriginPallets = () => {
        this.state.originPalletList.clear();
        this.forceUpdate();
    }

    private updateTransferBoxes = () => {
        const transferedBoxes = this.totalBoxesToTransfer();
        const destPallet = this.state.destPallet;
        if (destPallet) {
            const destPalletBoxes = (destPallet.boxes_count ?? 0) + transferedBoxes;
            if (this.state.destPallet) destPallet.boxes_count = destPalletBoxes;
        }

        const maxBoxToTransfer = this.state.maxBoxToTransfer - transferedBoxes;

        this.setState({maxBoxToTransfer});
        this.state.originPalletList.forEach(v => {
            v.boxes_count -= v.transferedBoxes;
            v.transferedBoxes = 0;

            v.batches.forEach(b => {
                b.maxBoxes -= b.boxCount;
                b.boxCount = 0;
            });
        });
    }

    private resetTransferBoxes = () => {
        this.state.originPalletList.forEach(v => {
            let boxCount = 0;

            v.batches.forEach(b => {
                boxCount += b.maxBoxes;
                b.boxCount = 0;
            });
            v.transferedBoxes = 0;
            v.boxes_count = boxCount;
        });
    }

    private handleTransferBoxes = () => {
        this.setLoading(this.TRANSFER_BOXES_MSG);

        let transfer: TransferBoxesType = {
            origin : [],
            dest : this.state.destPallet?.id ?? ''
        };

        this.state.originPalletList.forEach(v => {
            const batches : BatchTransType[] = [];
            if (v.transferedBoxes > 0) {
                v.batches.forEach(b => {

                    if (b.boxCount > 0) {
                        const batch = {
                            batchId : b.batchId,
                            boxesToTrans : b.boxCount
                        }

                        batches.push(batch);
                    }
                })

                const pallet : TransferOriginPalletType = {
                    palletId : v.id,
                    batches : batches
                };

                transfer.origin.push(pallet);
            }
        });


        LiftPalletsControlService.transferBoxes(transfer).then((r)=> {
            if (r.status === 200) {
                notify({
                    status: 'success',
                    message: 'La operación se ha realizado con éxito',
                });
                this.updateTransferBoxes();
            } else {
                notify({
                    status: 'error',
                    message: 'No se pudieron transferir las cajas',
                });
            }
            this.unsetLoading();
        }).catch((e)=>{
            console.log(e);
            this.unsetLoading();
            notify({
                status: 'error',
                message: 'No se pudieron transferir las cajas',
            });
        });
    }

    private hideVinculatePallet = () => {
        this.setState({requestPalletId : ''});
    };

    private hideCreatePallet = () => {
        this.setState({showCreatePallet : false});
    };

    private handleSelectOriginPallet = (barcode : string) => {
        this.setState({
            tempOriginPallet : (this.state.originPalletList.get(barcode) ?? null)
        });
    }

    private showAd = (message: string) => {
        this.adMessage = message;
        this.setState({
            showAd: true,
        });
    };

    private hideAd = () => {
        this.setState({
            showAd: false,
        });
    };

    private handlePalletBarcodeReader = (barcode : string, requestId : string) => {

        if (requestId === 'origin') {
            this.addOriginPallet(barcode);
        }
        else if (requestId === 'dest') {
            this.setDestPallet(barcode);
        }

        this.setState({
            requestPalletId : '',
        })
    }

    private handleCreatePalletBarcodeReader = (barcode : string, prodpart : string, areaId : string) => {
       
       this.setLoading(this.CREATE_PALLET_MSG);

        PalletsServices.vinculatePalletFromProdpart(barcode, prodpart, areaId)
            .then(data => {
                const palletInfo = data.data.pallet;
                if (palletInfo) {
                    const maxBoxes = palletInfo.boxes_total - palletInfo.boxes_count;
                    this.setState({destPallet : palletInfo, maxBoxToTransfer : maxBoxes});
                    this.resetTransferBoxes();

                    if (maxBoxes === 0) this.showAd('Al palé seleccionado no se le pueden añadir más cajas');
                } else {
                    this.showAd('No se pudo crear el palé pallet');
                }
                this.unsetLoading();
            })
            .catch(() => {
                this.showAd('No se pudo crear el palé pallet');
                this.unsetLoading();
            });

        this.setState({
            showCreatePallet: false,
        });
    }


    private addOriginPallet = (barcode : string) => {
        if (barcode === this.state.destPallet?.palletBarcode) {
            this.showAd('El palé de origen no puede ser igual al de destino');
            return;
        }

        if (this.state.originPalletList.has(barcode)) {
            this.showAd('El palé seleccionado ya existe en el listado');
            return;
        }

        this.setLoading(this.GET_PALLET_MSG);

        PalletInformationServices.getInfo(barcode)
            .then(data => {
                const palletInfo = data.data.pallet;
                if (palletInfo) {
                    const batches : TransBatchType[] = [];
                    palletInfo.batches.forEach(v => {
                        const batch : TransBatchType = {
                            erpCode : v.erp_batch_code,
                            batchId : v.id,
                            boxCount : 0,
                            maxBoxes : parseInt(v.boxes) ?? 0
                        };
                        batches.push(batch);
                    });

                    const transferPallet : TransferPalletType = {
                        id: palletInfo.id,
                        barcode: palletInfo.palletBarcode,
                        transferedBoxes: 0,
                        boxes_count: palletInfo.boxes_count,
                        boxes_total: palletInfo.boxes_total,
                        batches: batches,
                    };
                    this.state.originPalletList.set(barcode, transferPallet);

                } else {
                    this.showAd('No existe ningún palé con el código de barras introducido');
                }
                this.unsetLoading();
            })
            .catch(() => {
                this.unsetLoading();
                this.showAd('No se pudo obtener información del pallet');
            });
    }

    private handleRemoveOriginPallet = (barcode : string) => {
        this.state.originPalletList.delete(barcode);
        this.forceUpdate();
    }

    private totalBoxesToTransfer = () : number => {
        const values = this.state.originPalletList.values();
        let counter = 0;
        let res = values.next();
        while (!res.done) {
            counter += res.value.transferedBoxes;
            res = values.next();
        }
        return counter;
    }

    private handleOriginTransferBoxes = (transferedBoxes : number, palletBarcode : string | undefined, transfer : Map<string, TransBatchType>) => {

        this.setState({tempOriginPallet : null});

        if (palletBarcode) {
            const pallet = this.state.originPalletList.get(palletBarcode);

            if (pallet) {
                pallet.transferedBoxes = transferedBoxes;
                //pallet.boxes_count = pallet.boxes_count - transferedBoxes;
                const batches : TransBatchType[] = [];
                transfer.forEach(v => {
                    const _batch = Object.assign({}, v);
                    batches.push(_batch);
                });
                pallet.batches = batches;
            }
        }
    }

    private setDestPallet = (barcode : string) => {

        if (this.state.destPallet?.palletBarcode === barcode) {
            this.showAd('Ha seleccionado el palé actual');
            return;
        }
        if (this.state.originPalletList.has(barcode)) {
            this.showAd('El palé seleccionado ya existe en la lista de origen');
            return;
        }

        this.setLoading(this.GET_PALLET_MSG);

        PalletInformationServices.getInfo(barcode)
            .then(data => {
                const palletInfo = data.data.pallet;
                if (palletInfo) {
                    const maxBoxes = palletInfo.boxes_total - palletInfo.boxes_count;
                    this.setState({destPallet : palletInfo, maxBoxToTransfer : maxBoxes});
                    this.resetTransferBoxes();

                    if (maxBoxes === 0) this.showAd('Al palé seleccionado no se le pueden añadir más cajas');
                } else {
                    this.showAd('No existe ningún palé con el código de barras  introducido');
                }
                this.unsetLoading();
            })
            .catch(() => {
                this.showAd('No se pudo obtener información del pallet');
                this.unsetLoading();
            });
    }



    render() {
        const {classes} = this.props;
        const originPalletList = Array.from(this.state.originPalletList);
        const totalBoxesToTransfer = this.totalBoxesToTransfer();

        return (
            <>

                <Grid container className={classes.fitH} alignItems='center'>
                    <Grid item xs={2}/>

                    <Grid item xs={3} className={classes.gridContainer}>
                        <Typography color='inherit' variant='h6'>
                            Origen
                        </Typography>
                        <Card className={classes.cardContainer}>
                            <CardContent className={classes.cardContent}>
                                <Typography variant='body1' className={classes.cardHeader}>
                                    Total de cajas a transferir: <b>{totalBoxesToTransfer}</b>/{this.state.maxBoxToTransfer}
                                </Typography>
                                <Card className={classes.palletListContainer}>
                                    <List className={classes.palletList}>
                                        <>
                                            {
                                                originPalletList.map(
                                                    (value) => {
                                                        return (
                                                            <PalletElement
                                                                pallet={value[1]}
                                                                handleClick={this.handleSelectOriginPallet}
                                                                handleRemove={this.handleRemoveOriginPallet}
                                                            />
                                                        )
                                                    }
                                                )
                                            }
                                        </>
                                    </List>
                                </Card>

                                <Grid container className={classes.cardButtons} justify='flex-end'>
                                    <Grid item >
                                        <Tooltip title="Limpiar palés de origen">
                                            <IconButton onClick={this.handleClearOriginPallets}>
                                                <ClearButton color='secondary' fontSize="large"/>
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item >
                                        <Tooltip title="Añadir palé de origen">
                                            <IconButton onClick={this.handleAddOriginPallet}>
                                                <AddButtom color='primary' fontSize="large"/>
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </CardContent>

                        </Card>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography align='center'>
                            <ArrowIcon className={classes.arrow}/>
                        </Typography>

                    </Grid>
                    <Grid item xs={3} className={classes.gridContainer}>
                        <Typography color='inherit' variant='h6'>
                            Destino
                        </Typography>
                        <Card className={classes.cardContainer}>
                            <CardContent className={classes.cardContent}>
                                <Card className={classes.palletContainer}>
                                    {this.state.destPallet ?
                                        (
                                            <Typography variant='subtitle1' align='center'>
                                                <b>{this.state.destPallet.palletBarcode}</b>&nbsp;
                                                ({this.state.destPallet.boxes_count}/{this.state.destPallet.boxes_total})
                                            </Typography>
                                        )
                                        :
                                        (
                                            <Typography variant='h5' align='center'>
                                                - No hay selección -
                                            </Typography>
                                        )
                                    }
                                </Card>
                                <Button className={classes.selectButton} variant="contained" color="primary" onClick={this.handleSelectDestPallet}>
                                    Seleccionar palé
                                </Button>
                                <Button className={classes.selectButton} variant="contained" color="primary" onClick={this.handleCreatePallet}>
                                    Crear nuevo palé
                                </Button>
                                <div className={classes.gap}/>
                                <Grid container className={classes.cardButtons} justify='flex-end'>
                                    <Grid item>
                                        <Button
                                            className={classes.transButton}
                                            variant="contained"
                                            color="primary"

                                            onClick={this.handleTransferBoxes}
                                            disabled={this.state.destPallet === null || totalBoxesToTransfer === 0}
                                        >
                                            Transferir
                                        </Button>
                                    </Grid>
                                </Grid>

                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>

                <GetPalletModal open={this.state.requestPalletId !== ''}
                                hide={this.hideVinculatePallet}
                                requestId={this.state.requestPalletId}
                                handleBarcodeReaded={this.handlePalletBarcodeReader}/>
                <CreatePalletModal open={this.state.showCreatePallet}
                                hide={this.hideCreatePallet}
                                requestId={this.state.requestPalletId}
                                handleBarcodeReaded={this.handleCreatePalletBarcodeReader}/>
                <Advertisement open={this.state.showAd} hide={this.hideAd} message={this.adMessage} type={NotifyActionType.error} />
                <TransferBoxesDialog
                    open={this.state.tempOriginPallet !== null}
                    pallet={this.state.tempOriginPallet}
                    transferedBoxes={totalBoxesToTransfer}
                    maxBoxes={this.state.maxBoxToTransfer}
                    handleClose={()=> this.setState({tempOriginPallet : null})}
                    handleTransfer={this.handleOriginTransferBoxes}
                />
                {this.state.isLoading && (
                    <div className={classes.loaderContainer}>
                        <CircularProgress className={classes.loader}/>
                        <Typography>{this.state.loaderMessage}</Typography>
                    </div>)
                }
            </>
        )
    }
}

const styles = createStyles({
    fitH: {
        height: '100%'
    },
    gridContainer: {
        height: '75%',
        display: 'flex',
        flexDirection: 'column'
    },
    cardContainer: {
        height: '100%!important',
    },
    cardContent: {
        height: '100%!important',
        display: 'flex',
        flex: 'max-content',
        flexDirection: 'column',
        alignContent: 'flex-end',
        padding: 0
    },
    cardHeader: {
        margin: '8px',
    },
    palletListContainer: {
        height: '100%!important',
        margin: '8px',
        backgroundColor: '#eeeeee',
        border: "gray",
        flex: 'max-content',
        overflow: "auto"
    },
    palletListElement: {
        padding: '8px',
        cursor: 'pointer'
    },
    palletContainer: {
        margin: '8px',
        backgroundColor: '#eeeeee',
        border: "gray",
    },
    transferBoxesCard: {
        padding: '8px',
    },
    cardButtons: {
    },
    fitH75: {
        height: '75%'
    },
    no_margin: {
        height: '200px',
        margin: 0,
        backgroundColor: "blue"
    },
    gap: {
        flexGrow: 10,
        flex: 'max-content'
    },
    badge: {
        top: 14,
        padding: 3,
        right: -15,
        width: 20,
        height: 20,
    },
    arrow: {
        color: "gray",
        height: '50%',
        width: '50%'
    },
    selectButton: {
        margin: '8px',
    },
    transButton: {
        margin: '12px',
        color: 'white'
    },
    loaderContainer: {
        position: "absolute",
        backgroundColor: "rgba(255,255,255,0.85)",
        top: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    loader: {
        color: "blue",
        margin: "16px"
    }
});

type PEProps = {
    classes : any,
    pallet : TransferPalletType,
    handleClick : (barcode: string) => void;
    handleRemove : (barcode: string) => void;
}

const PalletElement = withStyles(styles)((props : PEProps) => {
        const {classes} = props;

        return (
            <ListItem key={props.pallet.id}>
                <Grid container spacing={8} alignItems='center'>
                    <Grid item xs={10}>
                        <Card className = {classes.palletListElement} onClick={() => props.handleClick(props.pallet.barcode)}>
                            <Typography variant='subtitle1'>
                                <b>{props.pallet.barcode}</b> ({props.pallet.boxes_count - props.pallet.transferedBoxes}/{props.pallet.boxes_total})
                            </Typography>
                            <Typography variant='subtitle1'>
                                Cajas a transferir: <b>{props.pallet.transferedBoxes}</b>
                            </Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={2}>
                        <IconButton onClick={() => props.handleRemove(props.pallet.barcode)}>
                            <RemoveButton color='secondary'/>
                        </IconButton>
                    </Grid>
                </Grid>
            </ListItem>
        )
    }
);

export const LiftPalletsControl = withStyles(styles)(LiftPalletsControlBase);


