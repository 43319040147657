import format from 'date-fns/format';
import es from 'date-fns/locale/es';
import debounce from 'lodash/debounce';
import React from 'react';
import {DatePickerProps} from 'react-date-picker';
import isEqual from 'react-fast-compare';
import {connect} from 'react-redux';
import ReactTable, {FilterRender, TableProps} from 'react-table';
import {Dispatch} from 'redux';
import styled from 'styled-components';

import {LaneSelectSelectors} from 'src/app/laneSelect/store';
import {ChipTablesConnected} from 'src/app/lanemanager/lane/components/ProdPartsInfo/ChipTables';
import {transformPtable} from 'src/app/lanemanager/lane/store/reducers/ptables.reducer';
import {ProdPartsServices} from 'src/app/lanemanager/prodparts/store/prodParts.services';
import {
    Button,
    Dialog,
    DialogTitle,
    FormControlLabel, FormGroup, Switch,
    Typography
} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import ClearAll from '@material-ui/icons/ClearAll';
import FilterList from '@material-ui/icons/FilterList';
import {OCard, StyledDatePicker, WrapFullContainerNowrap} from 'src/shared/style';
import {ptablesSchemeResValidate} from 'src/app/lanemanager/prodparts/store/validators/PtablesSchemeResponse';
import {IsInRuleset} from 'src/global/authorization/grantSet';
import {AppStoreState} from 'src/store/interface';
import {fetchAndValidate, fetchPaginateHelper} from 'src/utils/fetcherValidate';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import {customFormatNumber} from 'src/shared/functions/FormatHelpers';
import {currentDumpInstance} from "../lane/store/storage";
import {Merchandise} from "../lane/store/types";
import {findInitialParameter} from "../../../global/initalParameters/initialParameters";

type DProps = {dispatch: Dispatch};
type SProps = {lane: ReturnType<typeof LaneSelectSelectors.getLane>};
type ComponentProps = DProps & SProps;


const FAMILY_FILTER_ID = 'family_id';
const CAMPAIGN_FILTER_ID = 'current_campaign';
const CURRENT_DAY_FILTER_ID = 'current_day';
// const LAST_SEVEN_DAYS_FILTER_ID = 'last_seven_days';

type State = {
    charged: boolean;
    entries: any[];
    pages: number;
    filtered: Array<{id: string; value: string | boolean}>;
    date: {[k: string]: Date | Date[] | undefined};
    dropdownFilter: {[k: string]: any};
    transformedTables: any[];
    loading: boolean;
    openFiltersDialog: boolean;
    currentDumpProductId: string | null;
    filterStringify: string;
    [FAMILY_FILTER_ID]: boolean;
    [CAMPAIGN_FILTER_ID]: boolean;
    [CURRENT_DAY_FILTER_ID]: boolean;
    // [LAST_SEVEN_DAYS_FILTER_ID]: boolean;
    tempDateFilterValue: string | null;
};

const initialState: State = {
    charged: false,
    entries: [],
    pages: 0,
    filtered: [],
    dropdownFilter: {},
    date: {},
    transformedTables: [],
    loading: false,
    openFiltersDialog: false,
    currentDumpProductId: null,
    filterStringify: '',
    [FAMILY_FILTER_ID]: true,
    [CAMPAIGN_FILTER_ID]: true,
    [CURRENT_DAY_FILTER_ID]: true,
    // [LAST_SEVEN_DAYS_FILTER_ID]: false,
    tempDateFilterValue: null
};

export class ProdPartsBase extends React.Component<ComponentProps, State> {
    readonly state = initialState;
    private paginateTemp: any;
    private debounced = debounce((fn: () => any) => fn(), 400);
    private timer: any;


    constructor(props: ComponentProps, context: any) {
        super(props, context);
        if (this.paginateTemp) this.paginateTemp.filtered = [];
        console.log('CONSTRUCTOR');
        this.setState(initialState);
        this.setCurrentDumpProductId();
    }

    private fillFamily = () => {
        this.setState({filtered:[]});
        this.setAdditionalFilters();
        this.setState({charged:true});
    }

    componentDidMount() {
        //this.paginateTemp = undefined;
        this.handleUpdate();
        // this.timer = setInterval(() => {
        //     this.reloadData();
        // }, 60000);
    }

    private async setCurrentDumpProductId() {
        const merca = await currentDumpInstance.getItem('merchandise') as Merchandise;
        if (merca) {
            this.setState({currentDumpProductId : merca.family_id})
        }
        this.fillFamily();
    }

    componentWillUnmount() {
        this.paginateTemp = undefined;
        clearInterval(this.timer);
        const filtered = this.state.filtered;
        filtered.length = 0;
        this.setState({filtered:[]})
        this.setState(initialState);
    }

    private getArrayFilterStringify = () => {
        this.state.filtered.sort((a,b) => {
            if (a.id < b.id) return -1;
            if (a.id > b.id) return 1;
            return 0;
        });

        return JSON.stringify(this.state.filtered);
    }

    private handleOpenFilterDialog = () => {
        this.setState({
            filterStringify : this.getArrayFilterStringify()
        });
    }

    private handleCloseFilterDialog = () => {
        // console.log('C_FAMILY:', this.state[FAMILY_FILTER_ID] && this.state.currentDumpProductId !== null);
        // console.log('C_CAMPAIGN:', this.state[CAMPAIGN_FILTER_ID]);
        // console.log('C_CURRENT_DAY:', this.state[CURRENT_DAY_FILTER_ID]);
        // console.log('C_FILTER', this.state.filterStringify);

        if (this.state[CAMPAIGN_FILTER_ID] || this.state[CURRENT_DAY_FILTER_ID] /*|| this.state[LAST_SEVEN_DAYS_FILTER_ID]*/) {
            const tempDateFilterValue = this.removeFilter('erp_shipping_date');
            if (typeof tempDateFilterValue === 'string') this.setState({tempDateFilterValue});
        } else {
            const tempDateFilterValue = this.state.tempDateFilterValue;
            if (tempDateFilterValue !== null) this.state.filtered.push({id : 'erp_shipping_date',  value: tempDateFilterValue});
        }


        this.setState({
            openFiltersDialog : false
        });

        const filterStringify = this.getArrayFilterStringify();
        if (filterStringify !== this.state.filterStringify) {
            if (this.paginateTemp) this.paginateTemp.filtered = this.state.filtered;

            // console.log('CLAN_PAG', this.paginateTemp.filtered);
            this.fetchData(this.paginateTemp , true);
        }
    }

    private handleFiltersChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = event.target.checked;
        const filterName = event.target.name;

        this.setState({
            ...this.state,
            [filterName]: isChecked
        });

        if (!isChecked) {
            console.log("REMOVE FILTERS");
            this.removeFilter(filterName);
        }
        else {
            switch (filterName) {
                case FAMILY_FILTER_ID:
                    if (this.state.currentDumpProductId) this.state.filtered.push({id : FAMILY_FILTER_ID,  value: this.state.currentDumpProductId});
                    break;

                case CAMPAIGN_FILTER_ID:
                    this.state.filtered.push({id : CAMPAIGN_FILTER_ID,  value: true});
                    break;

                case CURRENT_DAY_FILTER_ID:
                    this.state.filtered.push({id : CURRENT_DAY_FILTER_ID,  value: true});
                    break;

                // case LAST_SEVEN_DAYS_FILTER_ID:
                //     this.state.filtered.push({id : LAST_SEVEN_DAYS_FILTER_ID,  value: true});
                //     this.removeFilter(CURRENT_DAY_FILTER_ID);
                //     this.removeFilter(CAMPAIGN_FILTER_ID);
                //     this.setState({[CURRENT_DAY_FILTER_ID] : false, [CAMPAIGN_FILTER_ID] : false});
                //     break;
            }
        }
    }

    private removeFilter(id : string) : string | boolean | null {
        const index = this.state.filtered.findIndex((item)=> {
            return item.id === id;
        });

        if (index !== -1) {
            const value = this.state.filtered[index].value;
            this.state.filtered.splice(index, 1);
            return value;
        }

        return null;
    }

    private setAdditionalFilters = () => {
        if (this.state[FAMILY_FILTER_ID] && this.state.currentDumpProductId) {
            this.state.filtered.push({id : FAMILY_FILTER_ID,  value: this.state.currentDumpProductId});
        }
        if (this.state[CAMPAIGN_FILTER_ID]) {
            this.state.filtered.push({id : CAMPAIGN_FILTER_ID, value: true});
        }
        if (this.state[CURRENT_DAY_FILTER_ID]) {
            this.state.filtered.push({id : CURRENT_DAY_FILTER_ID, value: true});
        }
    }

    private cleanFilters = () => {
        this.setState({filtered: [], date: {}, dropdownFilter: {}, tempDateFilterValue: null}, () => {
            this.setAdditionalFilters();
            if (this.paginateTemp) this.paginateTemp.filtered = this.state.filtered;
            // this.fetchData([], true);
            this.fetchData(this.paginateTemp);
        });

    }



    render() {
        const VIEW_ACCESS = IsInRuleset(['LANEMANAGER_PRODPARTS_VIEW', 'LANEMANAGER_COMMON_VIEW', 'LANEMANAGER_COMMON_TOTAL', 'LANEMANAGER_PRODPARTS_TOTAL']);
        const {filtered, entries, pages, transformedTables, charged} = this.state;
        return (
            VIEW_ACCESS && (
                <WrapFullContainerNowrap>
                    <OCard width={'100%'} height={'100%'} contentHeight={'100%'} contentPadding={0}>
                        <Typography component="div" style={{height: '100%', margin: 'auto'}}>
                            <OptionsDiv>
                                <Tooltip title="Limpiar filtros" placement="bottom">
                                    <Button
                                        color="secondary"
                                        style={{zIndex: 20, cursor: 'pointer', padding: 0}}
                                        onClick={this.cleanFilters}
                                    >
                                        <ClearAll />
                                    </Button>
                                </Tooltip>
                                <Tooltip title="Filtros adicionales" placement="bottom">
                                    <Button
                                        color="secondary"
                                        style={{zIndex: 20, cursor: 'pointer', padding: 0}}
                                        onClick={() => {
                                            this.setState({
                                                openFiltersDialog : true
                                            })
                                        }}
                                    >
                                        <FilterList />
                                    </Button>
                                </Tooltip>
                            </OptionsDiv>

                            <Dialog onClose={this.handleCloseFilterDialog}
                                    onEnter={this.handleOpenFilterDialog}
                                    aria-labelledby="simple-dialog-title"
                                    open={this.state.openFiltersDialog}
                            >
                                <DialogTitle id="simple-dialog-title"
                                             style={{backgroundColor:`#${findInitialParameter('FRONT_APP_COLOR')?.value || ''}`,}}
                                >
                                    <span style={{color: 'white'}}>Filtros adicionales</span>
                                </DialogTitle>
                                <FormGroup
                                    style={{padding:'16px'}}>
                                    <FormControlLabel
                                        control={<Switch
                                            disabled={this.state.currentDumpProductId === null}
                                            name={FAMILY_FILTER_ID}
                                            checked={this.state[FAMILY_FILTER_ID]}
                                            onChange={this.handleFiltersChange}
                                        />}
                                        label="Familia de productos del volcado activo"
                                    />
                                    <FormControlLabel
                                        control={<Switch
                                            name={CAMPAIGN_FILTER_ID}
                                            checked={this.state[CAMPAIGN_FILTER_ID]}
                                            onChange={this.handleFiltersChange}
                                        />}
                                        label="Campaña actual"
                                    />
                                    <FormControlLabel
                                        control={<Switch
                                            checked={this.state[CURRENT_DAY_FILTER_ID]}
                                            name={CURRENT_DAY_FILTER_ID}
                                            onChange={this.handleFiltersChange}
                                        />}
                                        label="Día en curso y 7 siguientes"
                                    />

                                    {/*<FormControlLabel*/}
                                    {/*    control={<Switch*/}
                                    {/*        checked={this.state[LAST_SEVEN_DAYS_FILTER_ID]}*/}
                                    {/*        name={LAST_SEVEN_DAYS_FILTER_ID}*/}
                                    {/*        onChange={this.handleFiltersChange}*/}
                                    {/*    />}*/}
                                    {/*    label="Últimos 7 días"*/}
                                    {/*/>*/}
                                </FormGroup>
                            </Dialog>


                            {charged && (<ReactTable
                                filtered={filtered}
                                style={{
                                    height: 'calc(100% - 36px)',
                                    width: '100%',
                                }}
                                manual={true}
                                data={entries || []}
                                onFetchData={this.controlForPaginate}
                                resizable={false}
                                sortable={true}
                                className="-striped -highlight"
                                columns={[
                                    {
                                        Header: 'FECHA SAL. PREV.',
                                        headerClassName: 'header_cell_string',
                                        Filter: this.datePickerFilter('erp_shipping_date', DateRangePicker),
                                        filterable: true,
                                        sortable: true,
                                        accessor: 'erp_shipping_date',
                                        Cell: item => (
                                            <>
                                                {format(new Date(item.original.erp_shipping_date), 'DD/MM/YYYY', {
                                                    locale: es,
                                                })}
                                            </>
                                        ),
                                        width: 250,
                                        className: 'cell_string',
                                    },
                                    {
                                        Header: 'PEDIDO',
                                        headerClassName: 'header_cell_string',
                                        accessor: 'order_id',
                                        className: 'cell_string',
                                        filterable: true,
                                        sortable: true,
                                    },
                                    {
                                        Header: 'MESAS',
                                        headerClassName: 'header_cell_string',
                                        id: 'assigned',
                                        sortable: false,
                                        Cell: items => {
                                            return <ChipTablesConnected ptables={transformedTables} prodpart={items.original} callback={this.handleCallback} />;
                                        },
                                        minWidth: 265,
                                        style: {whiteSpace: 'normal', display: 'flex'},
                                    },
                                    {
                                        Header: 'PARTE',
                                        headerClassName: 'header_cell_string',
                                        accessor: 'erp_prodpart_code',
                                        className: 'cell_string',
                                        filterable: true,
                                        sortable: true,
                                    },
                                    {
                                        Header: 'CLIENTE',
                                        headerClassName: 'header_cell_string',
                                        id: 'customer',
                                        accessor: b => b.customer.name,
                                        className: 'cell_string',
                                        filterable: true,
                                        sortable: false,
                                    },
                                    {
                                        Header: 'PALES',
                                        headerClassName: 'header_cell_number',
                                        width: 60,
                                        id: 'pallets',
                                        sortable: false,
                                        className: 'cell_number',
                                        Cell: items => <>{`${customFormatNumber(items.original.pallets_count)}/${customFormatNumber(items.original.pallets_total)}`}</>,
                                    },
                                    {
                                        Header: 'PEND.',
                                        headerClassName: 'header_cell_number',
                                        id: 'pending',
                                        sortable: false,
                                        className: 'cell_number',
                                        width: 80,
                                        Cell: items => <>{`${customFormatNumber(items.original.pallets_total - items.original.pallets_count)}`}</>,
                                    },
                                    {
                                        id: 'merchandise',
                                        accessor: b => b.merchandise.name,
                                        Header: 'PRODUCTO',
                                        headerClassName: 'header_cell_string',
                                        className: 'cell_string',
                                        filterable: true,
                                        sortable: false,
                                    },
                                    {
                                        accessor: 'pallet_name', // b => b.making_type.name,
                                        Header: 'PALÉ',
                                        headerClassName: 'header_cell_string',
                                        className: 'cell_string',
                                        filterable: true,
                                        sortable: true,
                                    },
                                    {
                                        Header: 'CAJAS',
                                        headerClassName: 'header_cell_number',
                                        id: 'boxes',
                                        sortable: false,
                                        Cell: items => <>{`${customFormatNumber(items.original.boxes_count || 0)}/${customFormatNumber(items.original.boxes_total || 0)}`}</>,
                                        className: 'cell_number',
                                    },
                                    {
                                        Header: 'REFERENCIA',
                                        headerClassName: 'header_cell_string',
                                        accessor: 'order_reference',
                                        className: 'cell_string',
                                        filterable: true,
                                    },
                                    {
                                        accessor: 'packaging', // b => b.making_type.name,
                                        Header: 'ENVASE',
                                        headerClassName: 'header_cell_string',
                                        className: 'cell_string',
                                        filterable: true,
                                        sortable: true,
                                    },
                                    {
                                        accessor: 'boxes_per_pallet', // b => b.making_type.name,
                                        Header: 'ENV.X PALE',
                                        headerClassName: 'header_cell_number',
                                        className: 'cell_number',
                                        filterable: false,
                                        sortable: true,
                                    },
                                ]}
                                previousText={'Anterior'}
                                nextText={'Siguiente'}
                                loadingText={'Obteniendo datos...'}
                                noDataText={'No hay entradas'}
                                pageText={'Página'}
                                ofText={'de'}
                                rowsText={'líneas'}
                                showPageSizeOptions={true}
                                defaultPageSize={10}
                                pages={pages}
                                loading={this.state.loading}
                                getTheadFilterThProps={() => ({
                                    style: {overflow: 'inherit'},
                                })}
                            />)}
                        </Typography>
                    </OCard>
                </WrapFullContainerNowrap>
            )
        );
    }

    private handleUpdate = async () => {
        const ptables = await fetchAndValidate(ProdPartsServices.getPtablesByArea(this.props.lane.laneId), 'ptables', ptablesSchemeResValidate);
        if (ptables && ptables.length)
            this.setState({
                transformedTables: [...transformPtable(ptables, this.props.lane.laneId)],
            });
        else this.setState({transformedTables: []});
    };
    private handleCallback = async () => {

        await this.handleUpdate();
        this.reloadData();
    };

    private controlForPaginate = (state?: any, instance?: any) => {
        console.log('BUSCANDO');
        const writeCache = () => {
            this.paginateTemp = state;
            this.paginateTemp.filtered = instance.state.filtered;
            stateTemp = state;
            stateTemp.filtered = instance.state.filtered;
        };
        let stateTemp: any;
        let fetched = false;
        if (state && instance && !isEqual(this.state.filtered, instance.state.filtered)) {
            if (state) {
                writeCache();
            } else stateTemp = this.paginateTemp;
            this.debounced(() => this.fetchData(stateTemp));
            this.setState({filtered: instance.state.filtered});
            fetched = true;
        } else if (!this.paginateTemp && !fetched) {
            writeCache();
            this.fetchData(stateTemp);
        } else if (!isEqual(state.sorted, this.paginateTemp.sorted)) {
            writeCache();
            this.fetchData(stateTemp);
        } else if (state && this.paginateTemp.page !== state.page) {
            writeCache();
            this.fetchData(stateTemp);
        } else if (state && this.paginateTemp.pageSize !== state.pageSize) {
            writeCache();
            this.fetchData(stateTemp);
        }
    };

    private transformToPaginatingParams = ({pageSize, page, sorted, filtered}: TableProps) => {
        const params = {
            'page-size': pageSize || 20,
            page: page ? page + 1 : 1,
            sorted,
            filtered:
                (filtered && filtered.length)
                    ? filtered.reduce(
                          (acc: any, now: any) => ({
                              ...acc,
                              [now.id]: now.value,
                          }),
                          {},
                      )
                    : undefined,
        };
        return params;
    };

    private fetchData = async (state?: any, isLoading: boolean = true) => {
        // console.log('FETCH');
        if (!state) return;

        const transformed = this.transformToPaginatingParams(state);
        if (isLoading) {
            this.setState({
                loading: true,
            });
        }

        const res = await fetchPaginateHelper(
            ProdPartsServices.getProdPartsPaginate({
                params: transformed,
                args: this.props.lane.laneId,
            }),
        );
        if (isLoading) {
            this.setState({
                loading: false,
            });
        }

        if (res && res.data && Array.isArray(res.data.data)) {
            this.setState({
                pages: Math.ceil(res.data.total / res.data.per_page),
                entries: [...res.data.data],
            });
        }
    };

    private reloadData = () => {
        if (this.paginateTemp) this.paginateTemp.filtered = this.state.filtered;
        this.fetchData(this.paginateTemp ? this.paginateTemp : {filtered: this.state.filtered}, false);
    };

    private datePickerFilter = (name: string, Picker: (props: DatePickerProps) => JSX.Element): FilterRender => () => (
        <Tooltip title={
            (this.state[CAMPAIGN_FILTER_ID] || this.state[CURRENT_DAY_FILTER_ID]) ? 'Desactivado: hay filtros adicionales de fechas activos' : ''
        }>
            <StyledDatePicker>
                <Picker
                    disabled={(this.state[CAMPAIGN_FILTER_ID] || this.state[CURRENT_DAY_FILTER_ID] /*|| this.state[LAST_SEVEN_DAYS_FILTER_ID]*/)}
                    value={this.state.date[name]}
                    onChange={d => {
                        let transformed = '';
                        const data = d !== null ? d : undefined;
                        this.setState({
                            date: {...this.state.date, [name]: data},
                        });
                        // we determine if the date is a range<Array> or
                        // a single date and transform for sending
                        if (data && !Array.isArray(data)) transformed = format(data, 'DD/MM/YYYY', {locale: es});
                        if (data && Array.isArray(data))
                            // we create the date range `${date}-${date}`
                            transformed = data.reduce((acc, curr) => (acc ? acc + '-' + format(curr, 'DD/MM/YYYY', {locale: es}) : format(curr, 'DD/MM/YYYY', {locale: es})), '');

                        this.removeFilter(name);
                        if (transformed) {
                            this.state.filtered.push({id:name, value:transformed});
                        }
                        if (this.paginateTemp) this.paginateTemp.filtered = this.state.filtered;
                        this.fetchData(this.paginateTemp ?? {filtered: this.state.filtered});
                    }}
                />
            </StyledDatePicker>
        </Tooltip>

    );
}
export const ProdParts = connect<SProps, DProps, {}, AppStoreState>(state => ({
    lane: LaneSelectSelectors.getLane(state),
}))(ProdPartsBase);

const OptionsDiv = styled.div`
    height: 36px;
    flex-direction: row-reverse;
    display: flex;
`;
