import * as React from 'react';
import styled from 'styled-components';

import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Check from '@material-ui/icons/Check';
import ErrorOutline from '@material-ui/icons/ErrorOutline';

/*** THEMED */
export const Wrap = styled.div`
  margin: 0px;
`;
export const WrapDumpID = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;
export const WrapOldBatches = styled.div`
  display: block;
  min-width: 100px;
  max-height: 96px;
`;
export const WrapDumpData = styled.div`
  display: block;
  font-size: 0.7em;
  /* margin-top: 15px; */
  margin-left: 5px;
`;
const styleIcon = {
  icon: {
    marginLeft: 19,
    marginTop: 'auto',
    marginBottom: 'auto',
  },
};
const styleTypo = {
  typo: {
    marginLeft: 25,
    marginTop: 'auto',
    marginBottom: 'auto',
    color: '#01ad0a',
  },
};
export const ThemedErrorIcon = withStyles(styleIcon)((props: any) => (
  <ErrorOutline data-name={'error'} className={props.classes.icon} color="error" />
));
export const ThemedSucessIcon = withStyles(styleIcon)((props: any) => (
  <Check
    data-name={'success'}
    className={props.classes.icon}
    color="primary"
    style={{color: '#01ad0a'}}
  />
));
export const ThemedStatus = withStyles(styleTypo)((props: any) => (
  <Typography data-name={props.dataName} className={props.classes.typo}>
    {props.children}
  </Typography>
));
