import * as React from 'react';
import {connect} from 'react-redux';

import {LanePtableState, LaneBatchesWithBoxesState} from 'src/app/lanemanager/lane/store/types';
import {AreaSelectors} from 'src/app/lanemanager/lane/store/selectors';
import {AppStoreState} from 'src/store/interface';

import {Table} from './Table';
import {WrapTables, Pallet, PalletCell} from './Wrap';
import {TableModal} from 'src/shared/components/Modal';
import {CustomButton, CustomTextField} from 'src/shared/style';
import {Typography} from '@material-ui/core';
import {fetchWithFeedback} from 'src/utils/fetcherValidate';
import {PalletsServices} from 'src/app/lanemanager/pallets/store/services';
import {notify} from 'src/utils/Notification';
import {getAreaAllPtables} from 'src/app/lanemanager/lane/store/thunks';
import {CustomDialog} from 'src/shared/components/CustomDialog';
import {TransferBoxes} from './TransferBoxes';
import {PtableWithoutPalletActions} from './PtableWithoutPalletActions';
import { EnqueuePalletsControl } from './EnqueuePalletsControl';
import {IsInRuleset} from 'src/global/authorization/grantSet';

export interface Props {
    ptables: LanePtableState[];
    auto_palletise: boolean;
    getPtables(): void;
}

type State = {
    open: boolean;
    openAlert: boolean;
    openTransfer: boolean;
    openPtableWithoutPalletActions: boolean;
    showConfirmationRemoveBoxes: boolean;
    ptableSelected: LanePtableState | null;
    newBoxCount?: number;
    batchWithBoxes?: LaneBatchesWithBoxesState[] | null;
    palletSelected: string | undefined;
    batchSelected: string | undefined;
    numBoxBatch?: number;
    showPalletsQueueModal: boolean;
};

const initialState: State = {
    open: false,
    openAlert: false,
    openTransfer: false,
    openPtableWithoutPalletActions: false,
    showConfirmationRemoveBoxes: false,
    ptableSelected: null,
    newBoxCount: undefined,
    palletSelected: undefined,
    batchSelected: undefined,
    showPalletsQueueModal: false
};

export class PalletsInfo extends React.Component<Props, State> {
    readonly state = initialState;

    private onHandleClickTable = (ptableSelected: LanePtableState) => {
        if (ptableSelected && ptableSelected.pallet) {
            this.setState({
                open: true,
                ptableSelected,
                newBoxCount: ptableSelected.pallet.boxes_count,
                batchWithBoxes: ptableSelected.pallet.batches,
            });
        } else if (ptableSelected) {
            this.setState({
                openPtableWithoutPalletActions: true,
                ptableSelected,
            });
        }
    };

    private hideModalTable = () => {
        this.setState({open: false, ptableSelected: null});
    };

    private showPalletsQueue = () => {
        this.setState({showPalletsQueueModal:true});
    }

    private hidePalletsQueue = () => {
        this.setState({showPalletsQueueModal:false});
    }

    private handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (e.target.name === 'boxes_count') {
            const ptableSelected = this.state.ptableSelected;
            if (ptableSelected && ptableSelected.pallet) {
                const newValue = (e.target.value as unknown) as number;
                this.setState({newBoxCount: newValue});
            }
        }
    };

    private removeBoxesToPallet = async () => {
        let res;
        if (this.state.newBoxCount !== undefined && this.state.ptableSelected && this.state.ptableSelected.pallet) {

            const numberBoxesToRemove = (this.state.ptableSelected.pallet.boxes_count - this.state.newBoxCount);
            const palletId = this.state.ptableSelected.pallet.id;
            const ptableId = this.state.ptableSelected.ptable_id;
            const currentdate = new Date();
            const datetime = currentdate.getFullYear() + "-"
                + (currentdate.getMonth()+1)  + "-"
                + currentdate.getDate() + "_"
                + currentdate.getHours() + ":"
                + currentdate.getMinutes() + ":"
                + currentdate.getSeconds();
            this.hideModalTable();
            this.props.getPtables();
            res = await fetchWithFeedback(PalletsServices.remove(ptableId, palletId, numberBoxesToRemove, datetime), {
                returnConfirmation: true,
                errorMessage: 'No se ha podido actualizar el valor',
                successMessage: 'Valor actualizado correctamente',
            });

            if (res) {
                return res;
            }
        }

    }


    private updatePallet = async () => {
        let res;
        if (this.state.newBoxCount !== undefined && this.state.ptableSelected && this.state.ptableSelected.pallet) {
            const newValue = this.state.newBoxCount;
            const palletId = this.state.ptableSelected.pallet.id;
            const ptableId = this.state.ptableSelected.ptable_id;

            //Comprobar si el valor está entre 0 y el valor actual
            if(this.checkBoxesAmountToRemove()){
                this.setState({showConfirmationRemoveBoxes: true});
            }else if (this.checkBoxesAmount()) {
                this.hideModalTable();
                const currentdate = new Date();
                const datetime = currentdate.getFullYear() + "-"
                    + (currentdate.getMonth()+1)  + "-"
                    + currentdate.getDate() + "_"
                    + currentdate.getHours() + ":"
                    + currentdate.getMinutes() + ":"
                    + currentdate.getSeconds();

                res = await fetchWithFeedback(PalletsServices.update(ptableId, palletId, newValue, datetime), {
                    returnConfirmation: true,
                    errorMessage: 'No se ha podido actualizar el valor',
                    successMessage: 'Valor actualizado correctamente',
                });
                this.props.getPtables();

                this.hideModalTable();
                if (res) {
                    return res;
                }
            }
        }
    };

    getTextConfirmationRemoveBoxes(){
        let textConfirmationRemoveBoxes = '';

        if(this.state.ptableSelected && this.state.ptableSelected.pallet && this.state.newBoxCount){
            textConfirmationRemoveBoxes = '¿Está seguro que quiere desvincular '+ (this.state.ptableSelected.pallet.boxes_count - this.state.newBoxCount) +' cajas del palé?';
        }
        return textConfirmationRemoveBoxes;
    }

    private finishPallet = async () => {
        if (this.state.ptableSelected && this.state.ptableSelected.pallet) {
            const palletId = this.state.ptableSelected.pallet.id;
            if (this.checkBoxesAmount()) {
                this.hideModalTable();
                const res = await this.updatePallet();
                if (res) {
                    await fetchWithFeedback(PalletsServices.finish(palletId), {
                        errorMessage: 'Error al finalizar pale',
                        successMessage: 'Pale finalizado con exito',
                    });
                } else {
                    notify({
                        message: 'No se puede finalizar el pale',
                        status: 'error',
                    });
                }
            }

            this.props.getPtables();
            this.hideModalTable();
            this.toggleAlert();
        }
    };

    private sendTransferBoxes = async (batchId: string | undefined, nextPalletId: string | undefined, previusPalletId: string | undefined, boxes: number | undefined) => {
        // await fetchWithFeedback(PalletsServices.transferBoxes(batchId, previusPalletId, nextPalletId, boxes), {
        //     errorMessage: 'Ocurrió un error al transferir las cajas',
        //     successMessage: 'Cajas transferidas',
        // });

        await PalletsServices.transferBoxes(batchId, previusPalletId, nextPalletId, boxes).then((r)=> {
            if (r.status === 200) {
                notify({
                    status: 'success',
                    message: 'Cajas transferidas',
                });
            } else {
                let message = 'No se pudieron transferir las cajas';
                if (r.data.message === 'BOXES_EXCESS_LOGISTIC_UNIT_AMOUNT') message = 'Se ha intentado transferir más cajas de las que contiene el palé de origen';
                else if (r.data.message === 'BOXES_EXCESS_LOGISTIC_UNIT_CAPACITY') message = 'Se ha intentado transferir cajas a un palé sin capacidad disponible';

                notify({
                    status: 'error',
                    message: message,
                });
            }
        }).catch((e)=>{
            console.log(e);
            notify({
                status: 'error',
                message: 'No se pudieron transferir las cajas',
            });
        }).finally(()=>{
            this.hideTransferBoxes();
            this.props.getPtables();
            this.hideModalTable();
        });
    };

    private checkBoxesAmount(): boolean {
        let isValid = false;

        if (this.state.newBoxCount !== undefined && this.state.ptableSelected && this.state.ptableSelected.pallet) {
            const newValue = this.state.newBoxCount;
            const maxValue = this.state.ptableSelected.pallet.boxes_total;
            const minValue = this.state.ptableSelected.pallet.boxes_count;

            if (newValue <= maxValue && newValue >= minValue) {
                isValid = true;
            } else {
                notify({
                    message: `El valor ha de estar comprendido entre ${minValue} y ${maxValue}`,
                    status: 'error',
                });
            }
        }

        return isValid;
    }

    private checkBoxesAmountToRemove(): boolean {
        let isRemove = false;
        if (this.state.newBoxCount !== undefined && this.state.ptableSelected && this.state.ptableSelected.pallet) {
            const newValue = this.state.newBoxCount;
            const maxValue = this.state.ptableSelected.pallet.boxes_count;
            const minValue = 0;

            if (newValue < maxValue && newValue >= minValue) {
                isRemove = true;
            }
        }

        return isRemove;
    }

    private transferBoxes(palletId: string, batchId: string, numBoxBatch: number) {
        this.setState({
            openTransfer: true,
            palletSelected: palletId,
            batchSelected: batchId,
            numBoxBatch: numBoxBatch,
        });
    }

    private hideTransferBoxes = () => {
        this.setState({
            openTransfer: false,
            palletSelected: undefined,
            batchSelected: undefined,
        });
    };

    private hidePtableWithoutPalletActions = () => {
        this.setState({
            openPtableWithoutPalletActions: false,
            palletSelected: undefined,
        });
    };

    private toggleAlert = () => {
        this.setState({openAlert: !this.state.openAlert});
    };

    render() {
        const {ptables} = this.props;
        if (ptables === undefined || ptables.length === 0) return <div style={{padding: 10, paddingLeft: 20, fontSize: 14}}>No hay datos sobre las mesas...</div>;
        return (
            <>
                <CustomDialog
                        title="Confirmación"
                        contentText={this.getTextConfirmationRemoveBoxes()}
                        handleClose={() => this.setState({showConfirmationRemoveBoxes: false})}
                        open={this.state.showConfirmationRemoveBoxes}
                        handle={() => {
                            this.removeBoxesToPallet();
                            this.setState({showConfirmationRemoveBoxes: false});
                        }}
                    />
                <TableModal title={(this.state.ptableSelected && this.state.ptableSelected.name) || 'Cerrando'} open={this.state.open} handleClose={this.hideModalTable}>
                    <Typography
                        component="div"
                        style={{
                            marginBottom: 10,
                            marginTop: 5,
                            minHeight: 200,
                            width: 500,
                        }}
                    >
                        <Pallet>
                            {this.props.auto_palletise && (
                                <CustomTextField
                                    margin={'0 auto'}
                                    width={'100%'}
                                    label="Cajas actuales"
                                    error={false}
                                    value={this.state.newBoxCount}
                                    name="boxes_count"
                                    variant={'outlined'}
                                    type="number"
                                    onChange={this.handleChange}
                                />
                            )}
                            <PalletCell>
                                {(this.state.ptableSelected && this.state.ptableSelected.pallet && this.state.ptableSelected.pallet.boxes_count) || '-'}/
                                {(this.state.ptableSelected && this.state.ptableSelected.pallet && this.state.ptableSelected.pallet.boxes_total) || '-'}
                            </PalletCell>
                        </Pallet>
                        {this.props.auto_palletise ? (
                            <>
                                <CustomButton onClick={this.updatePallet} width={'50%'} bgcolor="grey">
                                    Actualizar
                                </CustomButton>
                                <CustomButton onClick={this.toggleAlert} width={'50%'}>
                                    Finalizar
                                </CustomButton>
                                {(this.state.ptableSelected &&
                                    this.state.ptableSelected.pallet &&
                                    this.state.ptableSelected.pallet.batches &&
                                    this.state.ptableSelected.pallet.batches.map(bwb => {
                                        if (bwb && this.state && this.state.ptableSelected && this.state.ptableSelected.pallet) {
                                            const palletId = this.state.ptableSelected.pallet.id;
                                            return (
                                                <Typography
                                                    component="div"
                                                    style={{
                                                        marginTop: 10,
                                                    }}
                                                >
                                                    {bwb.date_batch} Origen {bwb.erp_batch_code} {'->'} {bwb.number_boxes}
                                                    <CustomButton
                                                        onClick={() => this.transferBoxes(palletId, bwb.batch_id, bwb.number_boxes)}
                                                        width={'25%'}
                                                        height={'20px'}
                                                        padding={'0px'}
                                                        margin={'3px'}
                                                    >
                                                        Transferir
                                                    </CustomButton>
                                                </Typography>
                                            );
                                        } else return undefined;
                                    })) ||
                                    'No tiene cajas de orígenes'}
                                <TransferBoxes
                                    ptables={this.props.ptables}
                                    ptablePrevius={this.state.ptableSelected}
                                    batchSelected={this.state.batchSelected}
                                    boxCount={this.state.numBoxBatch}
                                    open={this.state.openTransfer}
                                    hideTransferBoxes={this.hideTransferBoxes}
                                    sendTransferBoxes={this.sendTransferBoxes}
                                />
                                <div style={{height:'16px'}}/>
                                {
                                    IsInRuleset(['LANEMANAGER_COMMON_TOTAL']) && this.state.ptableSelected && (
                                        <CustomButton onClick={this.showPalletsQueue} width={'100%'} bgcolor="green">
                                            Palés en cola ({this.state.ptableSelected.pallets_queue.length})
                                        </CustomButton>
                                    )
                                }
                            </>
                        ) : (
                            <>
                                <CustomButton onClick={this.toggleAlert} width={'100%'}>
                                    Finalizar
                                </CustomButton>
                            </>
                        )}
                    </Typography>
                </TableModal>
                {
                    <WrapTables>
                        {ptables.map(pt => (
                            <Table {...pt} key={pt.ptable_id} onHandleClickTable={this.onHandleClickTable} />
                        ))}
                    </WrapTables>
                }
                <PtableWithoutPalletActions
                    open={this.state.openPtableWithoutPalletActions}
                    hide={this.hidePtableWithoutPalletActions}
                    ptableSelected={this.state.ptableSelected}
                />
                <CustomDialog
                    open={this.state.openAlert}
                    title="Alerta"
                    contentText={`¿Desea finalizar el palé con  ${this.state.newBoxCount} cajas?`}
                    handle={() => this.finishPallet()}
                    handleClose={() => this.toggleAlert()}
                />
                {
                    IsInRuleset(['LANEMANAGER_COMMON_TOTAL']) && this.state.ptableSelected &&
                    (
                        <EnqueuePalletsControl
                            palletsQueue={this.state.ptableSelected.pallets_queue}
                            currentPalletBarcode={this.state.ptableSelected.pallet?.barcode ?? ''}
                            ptableId={this.state.ptableSelected.ptable_id}
                            prodpartId={this.state.ptableSelected.assigned_to}
                            open={this.state.showPalletsQueueModal}
                            handleClose={this.hidePalletsQueue}
                        />
                    )
                }

            </>
        );
    }
}

const mapStateToProps = (state: AppStoreState) => ({
    ptables: AreaSelectors.getPtables(state),
    auto_palletise: !!state.laneSelect.AutoPalletise,
});

const mapDispatchToProps = (dispatch: any) => ({
    getPtables: () => dispatch(getAreaAllPtables()),
});

export const PalletsInfoConnected = connect(mapStateToProps, mapDispatchToProps)(PalletsInfo);
