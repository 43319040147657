import invoke from 'lodash/invoke';
import * as React from 'react';
import styled from 'styled-components';
import {format} from 'date-fns';
import es from 'date-fns/locale/es';

import {StatusBar} from 'src/app/statusbar/StatusBar';
import {PalletInformationServices} from 'src/app/lanemanager/palletInformation/store/palletInformation.services';
import {Button, CircularProgress, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography} from '@material-ui/core';
import {OCard, CustomButton, CustomTextField} from 'src/shared/style';
import {fetchWithFeedback} from 'src/utils/fetcherValidate';

type State = {
    palletBarcodeInput: string;
    palletBarcode: string;
    erpProdPartCode: string;
    palletId: string;
    boxes_count: string;
    logisticunit_barcode: string;
    boxes_total: string;
    started_at: string;
    finished_at?: string;
    real_weight_value: number | undefined;
    batches: Array<{
        erp_batch_code: string;
        boxes: string;
    }>;
    palletDefinitionCstmFields: Array<{
        id: string;
        name: string;
        description: string;
    }>;
    palletCstmFields: Array<{
        name: string;
        value: string;
    }>;
    cstmFields: Map<string, string>;
    real_weight: number | undefined;
    isLoading: boolean;
    loaderMessage: string;
};
const initialState: Readonly<State> = {
    palletBarcodeInput: '',
    palletId: '',
    palletBarcode: '',
    logisticunit_barcode: '',
    boxes_count: '',
    boxes_total: '',
    started_at: '',
    finished_at: '',
    real_weight_value: undefined,
    erpProdPartCode: '',
    batches: [],
    palletDefinitionCstmFields: [],
    palletCstmFields: [],
    cstmFields: new Map(),
    real_weight: undefined,
    isLoading: false,
    loaderMessage: ''
};

export class PalletInformationErp extends React.Component<{}, State> {
    readonly state = initialState;
    private inputPalletBCRef = React.createRef<HTMLInputElement>();

    private fetchInformation = async () => {

        this.setLoading('Obteniendo información del palé...');

        const {palletBarcodeInput} = this.state;
        if (palletBarcodeInput) {
            const info = await fetchWithFeedback(PalletInformationServices.getInfo(palletBarcodeInput), {
                errorMessage: 'No se han podido retirar los datos del palé indicado',
            }).finally(() => {
                this.unsetLoading();
            });

            this.state.cstmFields.clear();
            info.palletDefinitionCstmFields.forEach((v : any) => {
                const ctmValues = info.palletCstmFields.find((e : any) => e.name === v.name);
                if (ctmValues) this.state.cstmFields.set(v.id, ctmValues.value);
            });

            // console.log(this.state.cstmFields);
            // console.log(info);
            if (info) {
                this.setState({
                    palletBarcode: info.pallet.palletBarcode,
                    boxes_count: '' + info.pallet.boxes_count,
                    boxes_total: '' + info.pallet.boxes_total,
                    started_at: info.pallet.started_at,
                    finished_at: info.pallet.finished_at,
                    batches: info.pallet.batches,
                    palletBarcodeInput: '',
                    palletId: info.pallet.id,
                    erpProdPartCode: info.pallet.erp_prodpart_code,
                    palletDefinitionCstmFields: info.palletDefinitionCstmFields,
                    palletCstmFields: info.palletCstmFields,
                    real_weight_value: info.pallet.real_weight,
                    real_weight: info.pallet.real_weight
                }, () => {
                    //console.log('ctmo', this.state.palletCstmFields);
                    //console.log('STATE', this.state)
                });
            }
        }
    };

    private sendInformationToErp = async () => {
        this.setLoading('Enviando información del palé...');
        const {finished_at, palletId, logisticunit_barcode} = this.state;
        // console.log(this.state.cstmFields);
        // console.log('R_W;', this.state.real_weight);

        if (!finished_at) {
            alert('Palé no finalizado. Finalizar para poder enviar a ERP');
        } else if (logisticunit_barcode) {
            alert('Palé ya registrado en ERP. No se puede volver a registrar');
        } else {
            await fetchWithFeedback(
                PalletInformationServices.sendInfoPallet(
                    palletId,
                    this.state.cstmFields,
                    this.state.real_weight
                ),
                {showMessage: true},
            ).then(() => {
                this.setState({
                    real_weight_value : this.state.real_weight,
                    palletCstmFields : []
                });

                this.state.cstmFields.forEach((value:string, key:string) => {
                    const cstmDef = this.state.palletDefinitionCstmFields.find((c : any) => c.id === key);
                    if (cstmDef) this.state.palletCstmFields.push({name: cstmDef.name, value: value});
                });

            }).finally(() => {
                this.unsetLoading();
            });
        }
    };

    private setLoading = (msg : string) => {
        this.setState({
            isLoading : true,
            loaderMessage : msg
        })
    }

    private unsetLoading = () => {
        this.setState({
            isLoading : false,
            loaderMessage : ''
        })
    }

    render() {
        const {
            palletBarcodeInput,
            palletBarcode,
            logisticunit_barcode,
            erpProdPartCode,
            boxes_count,
            boxes_total,
            started_at,
            palletId,
            finished_at,
            real_weight_value,
            batches,
            palletDefinitionCstmFields,
            palletCstmFields,
            real_weight
        } = this.state;

        return (
            <>
                <StatusBar />
                <WrappingDiv>
                    <OCard width={'100%'} contentHeight={'100%'} contentPadding={'5px'} margin={'auto'}>
                        <Typography component="div">
                            <form
                                onSubmit={e => {
                                    e.preventDefault();
                                    this.fetchInformation();
                                }}
                                style={{
                                    margin: '0px',
                                    display: 'flex',
                                    height: 35,
                                    marginBottom: 10,
                                    marginTop: 5,
                                }}
                                noValidate={true}
                                autoComplete="off"
                            >
                                <TextField
                                    placeholder="Código de barras del palé"
                                    inputRef={this.inputPalletBCRef}
                                    autoFocus={true}
                                    style={{marginRight: 5, width: '100%'}}
                                    InputProps={{style: {height: 35}}}
                                    variant="outlined"
                                    value={palletBarcodeInput}
                                    onChange={e => {
                                        const {value} = e.target;
                                        if (/^[1-9][0-9]{0,12}$/.test(value) || value === '') {
                                            this.setState({palletBarcodeInput: value})
                                        }
                                    }}
                                />
                                <Button
                                    //variant="text"
                                    color="primary"
                                    onClick={e => {
                                        e.preventDefault();
                                        this.fetchInformation();
                                    }}
                                    style={{width: 235}}
                                >
                                    Info
                                </Button>
                            </form>
                            {palletId && (
                                <>
                                    <WrapInfo>
                                        <h2>
                                            Información de palé
                                        </h2>
                                        <hr></hr>
                                        <DivElement>
                                                <WrapLabelInfo>Código de barras del palé:</WrapLabelInfo>
                                                <WrapValueInfo>{palletBarcode}</WrapValueInfo>
                                        </DivElement>
                                        <DivElement>
                                                <WrapLabelInfo>Código de barras del palé en ERP:</WrapLabelInfo>
                                                <WrapValueInfo>{logisticunit_barcode}</WrapValueInfo>
                                        </DivElement>
                                        <DivElement>
                                                <WrapLabelInfo>Código PI Parte Producción:</WrapLabelInfo>
                                                <WrapValueInfo>{erpProdPartCode}</WrapValueInfo>
                                        </DivElement>
                                        <DivElement>
                                                <WrapLabelInfo>Número de cajas totales:</WrapLabelInfo>
                                                <WrapValueInfo>{boxes_total}</WrapValueInfo>
                                        </DivElement>
                                        <DivElement>
                                                <WrapLabelInfo>Empezado:</WrapLabelInfo>
                                                <WrapValueInfo>{format(new Date(started_at), 'DD/MM/YYYY HH:mm:ss', {
                                                locale: es,
                                            })}</WrapValueInfo>
                                        </DivElement>
                                        <DivElement>
                                                <WrapLabelInfo>Finalizado:</WrapLabelInfo>
                                                <WrapValueInfo>{finished_at && format(new Date(finished_at), 'DD/MM/YYYY HH:mm:ss', {
                                                    locale: es,
                                                })}
                                                {!finished_at && 'No está finalizado'}
                                                </WrapValueInfo>
                                        </DivElement>
                                        <DivElement>
                                                <WrapLabelInfo>Peso real:</WrapLabelInfo>
                                                <WrapValueInfo>{real_weight_value} kg</WrapValueInfo>
                                        </DivElement>
                                        {invoke(palletCstmFields, 'map', (pcf: {name: string; value: string}) => (
                                            <DivElement>
                                                <WrapLabelInfo>{pcf.name}:</WrapLabelInfo>
                                                <WrapValueInfo>{pcf.value}</WrapValueInfo>
                                            </DivElement>
                                        ))}


                                    </WrapInfo>
                                    <WrapInfo>
                                        <h2>
                                                Desglose de cajas
                                        </h2>
                                        <hr></hr>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Código ERP del origen</TableCell>
                                                    <TableCell>Cajas del origen</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {invoke(batches, 'map', (b: {erp_batch_code: string; boxes: string}, id: number) => (
                                                    <TableRow key={id}>
                                                        <TableCell>{b.erp_batch_code}</TableCell>
                                                        <TableCell>{b.boxes}</TableCell>
                                                    </TableRow>
                                                ))}
                                                <TableRow key={'boxes_count'}>
                                                    <TableCell>{'Número de cajas contadas'}</TableCell>
                                                    <TableCell>
                                                        <span style={{fontWeight: 'bold'}}>{boxes_count}</span>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </WrapInfo>

                                    <WrapInfo>
                                        <h2>
                                            Información adicional
                                        </h2>
                                        <hr></hr>
                                        <CustomTextField
                                            margin={'10px auto 10px auto'}
                                            type={'number'}
                                            width={'25%'}
                                            label={'Peso real'}
                                            value={real_weight}
                                            name={'Peso real'}
                                            variant={'outlined'}
                                            onChange={e => {
                                                this.setState({real_weight : e.target.value});
                                            }}
                                        />
                                        {invoke(palletDefinitionCstmFields, 'map', (pdcf: {id: string; name: string; description: string}) => (
                                            <div key={pdcf.id}>
                                                <CustomTextField
                                                    margin={'0 auto 10px auto'}
                                                    width={'60%'}
                                                    label={pdcf.name}
                                                    value={this.state.cstmFields.get(pdcf.id)}
                                                    name={pdcf.id}
                                                    variant={'outlined'}
                                                    onChange={e => {
                                                        const {name, value} = e.target;
                                                        this.state.cstmFields.set(name, value);
                                                        console.log('N,V' , name , value);
                                                        this.forceUpdate();

                                                        // this.setState(prevState => ({
                                                        //     cstmFields: {
                                                        //         // object that we want to update
                                                        //         ...prevState.cstmFields, // keep all other key-value pairs
                                                        //         [name]: value, // update the value of specific key
                                                        //     },
                                                        // }));
                                                        //
                                                        // if (/^[1-9][0-9]{0,12}$/.test(value) || value === '') this.setState({dump_modify_barcode: value});
                                                        }}
                                                />
                                            </div>
                                        ))}
                                    </WrapInfo>
                                    <br />
                                    <div style={{display: 'flex', padding:'5px'}}>
                                        <CustomButton margin={'0px 0px 0px auto'}
                                        onClick={e => {
                                            e.preventDefault();
                                            this.sendInformationToErp();

                                            //this.fetchInformation();
                                        }}
                                        width={'25%'}>
                                            Enviar a ERP
                                        </CustomButton>
                                    </div>
                                </>
                            )}
                        </Typography>
                    </OCard>
                </WrappingDiv>

                {this.state.isLoading && (
                    <WrapLoaderDiv>
                        <CircularProgress style={{color: "blue", margin: "16px"}} />
                        <Typography>{this.state.loaderMessage}</Typography>
                    </WrapLoaderDiv>
                    )
                }
            </>
        );
    }
}

const WrapLoaderDiv = styled.div `
    position: fixed !important;
    background-color: rgba(255,255,255,0.85);
    top: 0;
    width: 100% !important;
    height: 100% !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    flex-direction: column !important;
`;

const WrappingDiv = styled.div`
    width: calc(75%);
    margin: auto;
    margin-top: 8px;
    height: calc(100% - 65px);
    background-color: #efefef;
    padding: 5px;
    margin-bottom: 5px;
`;

const WrapInfo = styled.div`
    padding: 10px;
    ul {
        list-style-type: none;
    }
`;

const DivElement = styled.div`
    border-bottom: 2px solid gray;
`;
/*
const WrapFields = styled.div`
    padding: 10px;
`;*/

const WrapLabelInfo = styled.span`
    width: 300px;
    display: inline-block;
`;

const WrapValueInfo = styled.span`
    margin-left: 5px;
`;

