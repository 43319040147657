import {push} from 'connected-react-router';
import React from 'react';
import {connect} from 'react-redux';

import {LaneSelectSelectors} from 'src/app/laneSelect/store';
import {StatusBar} from 'src/app/statusbar/StatusBar';
import {AreaShifts} from 'src/app/lanemanager/areashift/AreaShift';
import {Downtimes} from 'src/app/lanemanager/downtime/Downtimes';
import {OverviewConnected} from 'src/app/lanemanager/lane/components/Overview';
import {Personal} from 'src/app/lanemanager/personal/Personal';
import {createStyles, withStyles, Theme} from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import {IsInRuleset} from 'src/global/authorization/grantSet';
import {AppStoreState} from 'src/store';
import {RouteHelpers} from 'src/utils/routerHelpers';

import {Charts} from './charts/Charts';
import {Dumps} from './dumps/Dumps';
import {Pallets} from './pallets/Pallets';
import {ProdParts} from './prodparts/ProdParts';
import {CustomAppBar, TabContainer} from './Wrap';
import {fetchWithFeedback} from 'src/utils/fetcherValidate';
import {DumpsServices} from './dumps/store/services';
import {Batches} from './batches/Batches';
import {LoginSelectors} from '../login/store/login.selectors';
import {AlertExcessiveInactiveTask} from './alerts/AlertExcessiveInactiveTask';
import {AlertExceededKgBatch} from './alerts/AlertExceededKgBatch';
import {AlertTaskRegisteredBetweenTimeInterval} from "./alerts/AlertTaskRegisteredBetweenTimeInterval";

type Props = {
    classes: {
        root: string;
    };
    modalType?: boolean;
};
type SProps = {
    pathname: string;
    lane: ReturnType<typeof LaneSelectSelectors.getLane>;
    userData: ReturnType<typeof LoginSelectors.getUserData>;
};
type DProps = {push(path: string): void};
type ComponentProps = Props & SProps & DProps;

type State = {
    tab: number;
    foundRoute: boolean;
};
const initialState: Readonly<State> = {tab: 0, foundRoute: false};

const styles = ({palette}: Theme) =>
    createStyles({
        root: {
            backgroundColor: palette.background.paper,
            padding: 0,
            height: 'calc(100% - 40px)',
            width: '100%',
        },
    });

export class LaneIndexPageBase extends React.Component<ComponentProps, State> {
    readonly state = initialState;

    componentDidMount() {
        this.findRoute();
    }

    componentWillReceiveProps(nextProps: ComponentProps) {
        if (!this.props.modalType) {
            this.findRoute(nextProps.pathname);
        }
    }

    render() {
        const {classes, lane} = this.props;
        const {tab, foundRoute} = this.state;
        if (foundRoute)
            return (
                <>
                    {!this.props.modalType && (
                        <>
                            <StatusBar title={'Gestión de la ' + (lane.laneName || 'línea desconocida')} />
                            <AlertExcessiveInactiveTask employeeId={this.props.userData.employeeId} laneId={this.props.lane.laneId} />
                            <AlertTaskRegisteredBetweenTimeInterval employeeId={this.props.userData.employeeId} laneId={this.props.lane.laneId} />
                            <AlertExceededKgBatch employeeId={this.props.userData.employeeId} laneId={this.props.lane.laneId} />
                        </>
                    )}

                    <div className={classes.root}>
                        <CustomAppBar>
                            <Tabs
                                // scrollButtons={'auto'}
                                scrollable={true}
                                fullWidth={true}
                                style={{width: '100%'}}
                                value={tab}
                                onChange={this.handleChange}
                            >
                                {IsInRuleset([
                                    'LANEMANAGER_COMMON_VIEW',
                                    'LANEMANAGER_COMMON_TOTAL',
                                    'LANEMANAGER_STATE_TOTAL',
                                    'LANEMANAGER_STATE_VIEW',
                                    'LANEMANAGER_PRODPARTS_VIEW',
                                    'LANEMANAGER_PRODPARTS_TOTAL',
                                    'LANEMANAGER_AREASHIFTS_TOTAL',
                                    'LANEMANAGER_AREASHIFTS_VIEW',
                                    'LANEMANAGER_DOWNTIMES_TOTAL',
                                    'LANEMANAGER_DOWNTIMES_VIEW',
                                    'LANEMANAGER_PALLETS_VIEW',
                                    'LANEMANAGER_DUMPS_VIEW',
                                    'LANEMANAGER_PERSONNEL_TOTAL',
                                    'LANEMANAGER_PERSONNEL_VIEW',
                                    'LANEMANAGER_PERFORMANCE_VIEW',
                                ]) && (
                                    <Tab
                                        value={0}
                                        component="a"
                                        href="/lanemanager/lane"
                                        onClick={e => {
                                            // this.props.push(`/lanemanager/lane`);
                                            e.preventDefault();
                                        }}
                                        style={{textDecoration: 'inherit', color: 'inherit'}}
                                        label="Estado"
                                    />
                                )}
                                {IsInRuleset(['LANEMANAGER_COMMON_TOTAL', 'LANEMANAGER_COMMON_VIEW', 'LANEMANAGER_PRODPARTS_TOTAL', 'LANEMANAGER_PRODPARTS_VIEW']) && (
                                    <Tab
                                        value={1}
                                        component="a"
                                        href="/lanemanager/prodparts"
                                        onClick={e => {
                                            // this.props.push(`/lanemanager/prodparts`);
                                            e.preventDefault();
                                        }}
                                        style={{textDecoration: 'inherit', color: 'inherit'}}
                                        label="Partes"
                                    />
                                )}
                                {IsInRuleset(['LANEMANAGER_COMMON_TOTAL', 'LANEMANAGER_COMMON_VIEW', 'LANEMANAGER_AREASHIFTS_TOTAL', 'LANEMANAGER_AREASHIFTS_VIEW']) && (
                                    <Tab
                                        value={2}
                                        component="a"
                                        href="/lanemanager/workshifts"
                                        onClick={e => {
                                            // this.props.push(`/lanemanager/workshifts`);
                                            e.preventDefault();
                                        }}
                                        style={{textDecoration: 'inherit', color: 'inherit'}}
                                        label="Turnos"
                                    />
                                )}
                                {IsInRuleset(['LANEMANAGER_COMMON_TOTAL', 'LANEMANAGER_COMMON_VIEW', 'LANEMANAGER_PALLETS_VIEW']) && (
                                    <Tab
                                        value={3}
                                        component="a"
                                        href="/lanemanager/pallets"
                                        onClick={e => {
                                            // this.props.push(`/lanemanager/pallets`);
                                            e.preventDefault();
                                        }}
                                        style={{textDecoration: 'inherit', color: 'inherit'}}
                                        label="Palés"
                                    />
                                )}
                                {IsInRuleset(['LANEMANAGER_COMMON_TOTAL', 'LANEMANAGER_COMMON_VIEW', 'LANEMANAGER_DUMPS_VIEW']) && (
                                    <Tab
                                        value={4}
                                        component="a"
                                        href="/lanemanager/dumps"
                                        onClick={e => {
                                            // this.props.push(`/lanemanager/dumps`);
                                            e.preventDefault();
                                        }}
                                        style={{textDecoration: 'inherit', color: 'inherit'}}
                                        label="Volcados"
                                    />
                                )}
                                {IsInRuleset(['LANEMANAGER_COMMON_TOTAL', 'LANEMANAGER_COMMON_VIEW', 'LANEMANAGER_DOWNTIMES_TOTAL', 'LANEMANAGER_DOWNTIMES_VIEW']) && (
                                    <Tab
                                        value={5}
                                        component="a"
                                        href="/lanemanager/downtimes"
                                        onClick={e => {
                                            this.findRoute(`/lanemanager/downtimes`);
                                            e.preventDefault();
                                        }}
                                        style={{textDecoration: 'inherit', color: 'inherit'}}
                                        label="Paradas"
                                    />
                                )}
                                {IsInRuleset(['LANEMANAGER_COMMON_TOTAL', 'LANEMANAGER_COMMON_VIEW', 'LANEMANAGER_PERFORMANCE_VIEW']) && (
                                    <Tab
                                        value={6}
                                        component="a"
                                        href="/lanemanager/performance"
                                        onClick={e => {
                                            // this.props.push(`/lanemanager/performance`);
                                            e.preventDefault();
                                        }}
                                        style={{textDecoration: 'inherit', color: 'inherit'}}
                                        label="Rendimiento"
                                    />
                                )}
                                {IsInRuleset(['LANEMANAGER_COMMON_TOTAL', 'LANEMANAGER_COMMON_VIEW', 'LANEMANAGER_PERSONNEL_TOTAL', 'LANEMANAGER_PERSONNEL_VIEW']) && (
                                    <Tab
                                        value={7}
                                        component="a"
                                        href="/lanemanager/personal"
                                        onClick={e => {
                                            // this.props.push(`/lanemanager/personal`);
                                            e.preventDefault();
                                        }}
                                        style={{textDecoration: 'inherit', color: 'inherit'}}
                                        label="Personal"
                                    />
                                )}
                                {IsInRuleset(['LANEMANAGER_COMMON_TOTAL', 'LANEMANAGER_COMMON_VIEW', 'LANEMANAGER_PERSONNEL_TOTAL', 'LANEMANAGER_PERSONNEL_VIEW']) && (
                                    <Tab
                                        value={8}
                                        component="a"
                                        href="/lanemanager/personal"
                                        onClick={e => {
                                            e.preventDefault();
                                        }}
                                        style={{textDecoration: 'inherit', color: 'inherit'}}
                                        label="Orígenes"
                                    />
                                )}
                            </Tabs>
                        </CustomAppBar>
                        {tab === 0 && (
                            <TabContainer>
                                <OverviewConnected lane={lane}/>
                            </TabContainer>
                        )}
                        {tab === 1 && (
                            <TabContainer>
                                <ProdParts />
                            </TabContainer>
                        )}
                        {tab === 2 && (
                            <TabContainer>
                                <AreaShifts />
                            </TabContainer>
                        )}
                        {tab === 3 && (
                            <TabContainer>
                                <Pallets />
                            </TabContainer>
                        )}
                        {tab === 4 && (
                            <TabContainer>
                                <Dumps cancelDump={this.cancelDump} />
                            </TabContainer>
                        )}
                        {tab === 5 && (
                            <TabContainer>
                                <Downtimes />
                            </TabContainer>
                        )}
                        {tab === 6 && (
                            <TabContainer>
                                <Charts />
                            </TabContainer>
                        )}
                        {tab === 7 && (
                            <TabContainer>
                                <Personal />
                            </TabContainer>
                        )}
                        {tab === 8 && (
                            <TabContainer>
                                <Batches />
                            </TabContainer>
                        )}
                    </div>
                </>
            );
        else return null;
    }

    private findRoute = (pathname: string = this.props.pathname) => {
        const path = pathname.split('/')[2];
        let tab = 0;
        if (path === 'lane') tab = 0;
        else if (path === 'prodparts') tab = 1;
        else if (path === 'workshifts') tab = 2;
        else if (path === 'pallets') tab = 3;
        else if (path === 'dumps') tab = 4;
        else if (path === 'downtimes') tab = 5;
        else if (path === 'performance') tab = 6;
        else if (path === 'personal') tab = 7;
        else if (path === 'batches') tab = 8;
        this.setState({tab, foundRoute: true});
    };

    private handleChange = (_: any, tab: number) => {
        let location = '';
        if (tab === 0) location = 'lane';
        else if (tab === 1) location = 'prodparts';
        else if (tab === 2) location = 'workshifts';
        else if (tab === 3) location = 'pallets';
        else if (tab === 4) location = 'dumps';
        else if (tab === 5) location = 'downtimes';
        else if (tab === 6) location = 'performance';
        else if (tab === 7) location = 'personal';
        else if (tab === 8) location = 'batches';
        if (!this.props.modalType) {
            this.props.push(`/lanemanager/${location}`);
        }

        this.setState({tab});
    };

    private cancelDump = (dumpId: string) => {
        if (dumpId) {
            fetchWithFeedback(DumpsServices.cancelDump(dumpId), {
                showMessage: true,
            });
        }
    };
}

export const LaneIndexPage = connect<SProps, DProps, Props, AppStoreState>(
    state => ({
        pathname: RouteHelpers.getLocation(state),
        lane: LaneSelectSelectors.getLane(state),
        userData: LoginSelectors.getUserData(state),
    }),
    {push},
)(withStyles(styles)(LaneIndexPageBase));
