import * as React from 'react';
import {connect} from 'react-redux';

import {LanePtableState} from 'src/app/lanemanager/lane/store/types';
import {AreaSelectors} from 'src/app/lanemanager/lane/store/selectors';
import {AppStoreState} from 'src/store/interface';

import {TableModal} from 'src/shared/components/Modal';
import {getAreaAllPtables} from 'src/app/lanemanager/lane/store/thunks';
import {Typography} from '@material-ui/core';
import {CustomButton, CustomTextField} from 'src/shared/style';
import {CustomDialog} from 'src/shared/components/CustomDialog';
import {fetchWithFeedback} from 'src/utils/fetcherValidate';
import {PalletsServices} from 'src/app/lanemanager/pallets/store/services';
import {BarcodeScanner} from '../../../../../shared/components/BarcodeScanner';
import {Advertisement} from '../../../../../shared/components/Advertisement';
import {validateBarcode} from '../../../../../shared/functions/BarcodeValidator';
import {NotifyActionType} from '../../../../../utils/Notification/action-types';
import {findSystemParameter} from 'src/global/systemParameters/systemParameters';
import {ParameterCPN} from 'src/types/app_types';

export interface Props {
    open: boolean;
    hide: () => void;
    ptableSelected: LanePtableState | null;
    getPtables(): void;
}

type State = {
    open: boolean;
    ptableSelected: LanePtableState | null;
    barcodePallet: string;
    barcodePalletValid: boolean;
    openBarcodeScanner: boolean;
    showAd: boolean;
};

const initialState: State = {
    open: false,
    ptableSelected: null,
    barcodePallet: '',
    barcodePalletValid: false,
    openBarcodeScanner: false,
    showAd: false,
};

const ERRORS_MAP = new Map<string,string>([
    ['PALLET_ISNT_FINISHED', 'El palé está actualmente en curso.'],
    ['PRODPART_DOESNT_MATCH','El palé ya está asignado a un parte que no coincide con el actual.'],
    ['PTABLE_ISNT_ASSIGNED','La mesa no tiene parte asignado.'],
    ['PRODPART_IS_FULL','Al parte actual no se le pueden agregar más cajas.'],
    ['PALLET_ALREADY_CREATED','El palé ya existe.'],
]);

export class VinculatePalletModal extends React.Component<Props, State> {
    readonly state = initialState;
    private barcodeScanner = React.createRef<BarcodeScanner>();
    private adMessage = '';

    private showAd = (message: string) => {
        this.adMessage = message;
        this.setState({
            showAd: true,
        });
    };

    private hideAd = () => {
        this.setState({
            showAd: false,
        });
    };

    private hideModalTable = () => {
        this.props.hide();
        this.setState({
            barcodePallet: '',
        });
    };

    private hideBarcodeScanner = () => {
        this.setState({
            openBarcodeScanner: false,
        });
    };

    private handleChangeBarcodePallet = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (e.target.name === 'barcode_pallet') {
            const newValue = (e.target.value as unknown) as string;

            if (newValue.length > 13) return;

            this.setState({
                barcodePallet: newValue,
            });

            if (newValue.length === 13) {
                //const barcodePrefixes = [`${findSystemParameter(ParameterCPN.PALLET_BARCODE_PREFIX)?.value || ''}`];
                const isValid = validateBarcode(newValue);

                if (isValid) {
                    this.setState({
                        barcodePalletValid: true,
                    });
                } else {
                    this.showAd('El código de barras introducido no tiene el formato correcto');
                }
            }
        }
    };

    private sendVinculatePallet = async (barcodePallet: string, barcodePtable: string | undefined) => {
        await fetchWithFeedback(PalletsServices.vinculatePallet(barcodePallet, barcodePtable), {
            errorMessage: 'Ocurrió un error al vincular palé',
            successMessage: 'Palé vinculado',
            errorAccessor: 'message',
            errorMessageMap: ERRORS_MAP
        });

        this.props.getPtables();
        this.hideModalTable();
        this.setState({barcodePalletValid: false, barcodePallet: ''});
    };

    /*
    private toggleModalTable = () => {
        this.setState({open: !this.state.open});
    };

*/
    handleCodeReaded = (code: string) => {
        this.setState({
            barcodePallet: code,
        });

        const barcodePrefixes = [`${findSystemParameter(ParameterCPN.PALLET_BARCODE_PREFIX)?.value || ''}`];
        const isValid = validateBarcode(code, barcodePrefixes);

        if (isValid) {
            this.setState({
                barcodePalletValid: true,
            });
        } else {
            this.showAd('El código de barras escaneado no tiene el formato correcto');
        }
    };

    scanBarcode = () => {
        if (navigator.mediaDevices) {
            if (this.barcodeScanner.current) {
                this.barcodeScanner.current.init();
                this.setState({openBarcodeScanner: true});
            }
        } else {
            this.showAd('No se puede acceder a la cámara. Se necesita entrar por https y permitir el acceso a la cámara. Gracias.');
        }
    };

    render() {
        return (
            <>
                <TableModal title={'Mesa ' + this.props.ptableSelected?.name} open={this.props.open} handleClose={this.hideModalTable}>
                    <Typography
                        component="div"
                        style={{
                            marginTop: 10,
                        }}
                    >
                        <>
                            <CustomTextField
                                margin={'0 auto'}
                                width={'100%'}
                                label="Código de barras palé"
                                //placeholder={`${findSystemParameter(ParameterCPN.PALLET_BARCODE_PREFIX)?.value || ''}...`}
                                error={false}
                                value={this.state.barcodePallet}
                                name="barcode_pallet"
                                variant={'outlined'}
                                type="string"
                                onChange={this.handleChangeBarcodePallet}
                                autoFocus={true}
                            />
                        </>
                    </Typography>

                    <Typography
                        component="div"
                        style={{
                            marginTop: 10,
                        }}
                    >
                        <>
                            <CustomButton onClick={this.scanBarcode} padding={'3px'}>
                                {'Escanear código'}
                            </CustomButton>
                            <br />
                        </>
                    </Typography>

                    {this.state && this.state.barcodePalletValid && (
                        <CustomDialog
                            open={this.state.barcodePalletValid}
                            title="Confirmación"
                            contentText={
                                '¿Está seguro que quiere vincular el palé ' +
                                this.state.barcodePallet +
                                ' a la mesa ' +
                                (this.props.ptableSelected && this.props.ptableSelected.name) +
                                '?'
                            }
                            handle={() => this.sendVinculatePallet(this.state.barcodePallet, this.props.ptableSelected?.barcode)}
                            handleClose={() => this.setState({barcodePalletValid: false})}
                        />
                    )}
                </TableModal>

                <BarcodeScanner open={this.state.openBarcodeScanner} hide={this.hideBarcodeScanner} onReadedCode={this.handleCodeReaded} ref={this.barcodeScanner} />

                <Advertisement open={this.state.showAd} hide={this.hideAd} message={this.adMessage} type={NotifyActionType.error} />
            </>
        );
    }
}

const mapStateToProps = (state: AppStoreState) => ({
    ptables: AreaSelectors.getPtables(state),
    auto_palletise: !!state.laneSelect.AutoPalletise,
});

const mapDispatchToProps = (dispatch: any) => ({
    getPtables: () => dispatch(getAreaAllPtables()),
});

export const VinculatePalletModalConnected = connect(mapStateToProps, mapDispatchToProps)(VinculatePalletModal);
