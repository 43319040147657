import invoke from 'lodash/invoke';
import * as React from 'react';
import styled from 'styled-components';

import {StatusBar} from 'src/app/statusbar/StatusBar';
import {PalletInformationServices} from 'src/app/lanemanager/palletInformation/store/palletInformation.services';
import {Button, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography} from '@material-ui/core';
import {OCard} from 'src/shared/style';
import {fetchWithFeedback} from 'src/utils/fetcherValidate';

type State = {
    palletBarcodeInput: string;
    palletBarcode: string;
    erpProdPartCode: string;
    palletId: string;
    boxes_count: string;
    logisticunit_barcode: string;
    boxes_total: string;
    started_at: string;
    finished_at?: string;
    batches: Array<{
        erp_batch_code: string;
        boxes: string;
    }>;
};
const initialState: Readonly<State> = {
    palletBarcodeInput: '',
    palletId: '',
    palletBarcode: '',
    logisticunit_barcode: '',
    boxes_count: '',
    boxes_total: '',
    started_at: '',
    finished_at: '',
    erpProdPartCode: '',
    batches: [],
};

export class PalletInformation extends React.Component<{}, State> {
    readonly state = initialState;
    private inputPalletBCRef = React.createRef<HTMLInputElement>();
    private inputLogisticUnitBCRef = React.createRef<HTMLInputElement>();

    private fetchInformation = async () => {
        const {palletBarcodeInput} = this.state;
        if (palletBarcodeInput) {
            const info = await fetchWithFeedback(PalletInformationServices.getInfo(palletBarcodeInput), {
                errorMessage: 'No se han podido retirar los datos del palé indicado',
                accessor: 'pallet',
            });
            if (info) {
                this.setState({
                    palletBarcode: info.palletBarcode,
                    boxes_count: '' + info.boxes_count,
                    boxes_total: '' + info.boxes_total,
                    started_at: info.started_at,
                    finished_at: info.finished_at,
                    batches: info.batches,
                    palletBarcodeInput: '',
                    palletId: info.id,
                    erpProdPartCode: info.erp_prodpart_code,
                });
                if (this.inputLogisticUnitBCRef && this.inputLogisticUnitBCRef.current) this.inputLogisticUnitBCRef.current.focus();
            }
        }
    };

    private addLogisticUnitForPallet = async () => {
        const {logisticunit_barcode, palletId} = this.state;
        if (logisticunit_barcode.length === 13) {
            await fetchWithFeedback(
                PalletInformationServices.addLogisticUnitToPallet({
                    logisticunit_barcode,
                    palletId,
                }),
                {showMessage: true},
            );
            this.setState({logisticunit_barcode: ''});
            if (this.inputPalletBCRef && this.inputPalletBCRef.current) this.inputPalletBCRef.current.focus();
        }
    };

    render() {
        const {palletBarcodeInput, palletBarcode, erpProdPartCode, boxes_count, boxes_total, logisticunit_barcode, started_at, palletId, finished_at, batches} = this.state;
        return (
            <>
                <StatusBar />
                <WrappingDiv>
                    <OCard width={'100%'} contentHeight={'100%'} contentPadding={'5px'} margin={'auto'}>
                        <Typography component="div">
                            <form
                                onSubmit={e => {
                                    e.preventDefault();
                                    this.fetchInformation();
                                }}
                                style={{
                                    margin: '0px',
                                    display: 'flex',
                                    height: 35,
                                    marginBottom: 10,
                                    marginTop: 5,
                                }}
                                noValidate={true}
                                autoComplete="off">
                                <TextField
                                    placeholder="Código de barras del palé"
                                    inputRef={this.inputPalletBCRef}
                                    autoFocus={true}
                                    style={{marginRight: 5, width: '100%'}}
                                    InputProps={{style: {height: 35}}}
                                    variant="outlined"
                                    value={palletBarcodeInput}
                                    onChange={e => {
                                        const {value} = e.target;
                                        if (/^[1-9][0-9]{0,12}$/.test(value) || value === '') {
                                            this.setState({palletBarcodeInput: value}, () => {
                                                if (value.length === 13) {
                                                    this.fetchInformation();
                                                }
                                            });
                                        }
                                    }}
                                />
                                <Button
                                    variant="text"
                                    color="primary"
                                    onClick={e => {
                                        e.preventDefault();
                                        this.fetchInformation();
                                    }}
                                    style={{width: 235}}>
                                    Info
                                </Button>
                            </form>
                            <form
                                onSubmit={e => {
                                    e.preventDefault();
                                    this.addLogisticUnitForPallet();
                                }}>
                                <TextField
                                    placeholder="Código de la unidad logística a añadir"
                                    inputRef={this.inputLogisticUnitBCRef}
                                    disabled={!palletId}
                                    style={{marginRight: 5, width: '100%'}}
                                    InputProps={{style: {height: 35}}}
                                    variant="outlined"
                                    value={logisticunit_barcode}
                                    onChange={e => {
                                        const {value} = e.target;
                                        if (/^[1-9][0-9]{0,12}$/.test(value) || value === '') {
                                            this.setState({logisticunit_barcode: value}, async () => {
                                                this.addLogisticUnitForPallet();
                                            });
                                        }
                                    }}
                                />
                            </form>
                            <div>
                                <WrapTextField>
                                    <TextField style={{marginRight: 5, width: '100%'}} variant="outlined" InputProps={{style: {height: 35}}} value={palletBarcode} />
                                    <span style={{alignSelf: 'center', width: 240}}>Código de barras del palé</span>
                                </WrapTextField>
                                <WrapTextField>
                                    <TextField style={{marginRight: 5, width: '100%'}} variant="outlined" InputProps={{style: {height: 35}}} value={erpProdPartCode} />
                                    <span style={{alignSelf: 'center', width: 240}}>Código PI Parte Producción</span>
                                </WrapTextField>
                                <WrapTextField>
                                    <TextField style={{marginRight: 5, width: '100%'}} variant="outlined" InputProps={{style: {height: 35}}} value={boxes_count} />
                                    <span style={{alignSelf: 'center', width: 240}}>Número de cajas contadas</span>
                                </WrapTextField>
                                <WrapTextField>
                                    <TextField style={{marginRight: 5, width: '100%'}} variant="outlined" InputProps={{style: {height: 35}}} value={boxes_total} />
                                    <span style={{alignSelf: 'center', width: 240}}>Número de cajas totales</span>
                                </WrapTextField>
                                <WrapTextField>
                                    <TextField style={{marginRight: 5, width: '100%'}} InputProps={{style: {height: 35}}} variant="outlined" value={started_at} />
                                    <span style={{alignSelf: 'center', width: 240}}>Empezado</span>
                                </WrapTextField>
                                <WrapTextField>
                                    <TextField
                                        InputProps={{style: {height: 35}}}
                                        style={{marginRight: 5, width: '100%', alignContent: 'center'}}
                                        variant="outlined"
                                        value={finished_at || 'No está finalizado'}
                                    />
                                    <span style={{alignSelf: 'center', width: 240}}>Finalizado</span>
                                </WrapTextField>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Código ERP del Origen</TableCell>
                                            <TableCell>Cajas del origen</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {invoke(batches, 'map', (b: {erp_batch_code: string; boxes: string}, id: number) => (
                                            <TableRow key={id}>
                                                <TableCell>{b.erp_batch_code}</TableCell>
                                                <TableCell>{b.boxes}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </div>
                        </Typography>
                    </OCard>
                </WrappingDiv>
            </>
        );
    }
}

const WrappingDiv = styled.div`
    width: calc(75%);
    margin: auto;
    margin-top: 8px;
    height: calc(100% - 65px);
    background-color: #efefef;
    padding: 5px;
    margin-bottom: 5px;
`;

const WrapTextField = styled.div`
    height: 35px;
    display: flex;
    margin: 10px 0;
`;
