import {push, ConnectedRouterProps, RouterAction} from 'connected-react-router';
import * as React from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';

import {colorSelected} from 'src/app/administration/export/utils';
import {
  LaneSelectActions,
  LaneSelectSelectors,
  LaneSelectUniqe,
} from 'src/app/laneSelect/store';
import {LaneSelectServices} from 'src/app/laneSelect/store/laneSelect.services';
import {StatusBar} from 'src/app/statusbar/StatusBar';
// import {LaneDumpActions} from 'src/app/lanemanager/lane/store/actions';
import {Typography} from '@material-ui/core';
import {TableComponent} from 'src/shared/components/TableComponent';
import {AppStoreState} from 'src/store';
import {ColumnType} from 'src/types';

type DProps = {
  push(path: string, state?: any): RouterAction;
  selectLane(
    lane: string,
    name: string,
    dumpBarcode: string,
    autoPalletise: boolean,
  ): void;
};
type SProps = {
  lane: ReturnType<typeof LaneSelectSelectors.getLane>;
};

// const Table = TableComponent<LaneSelectResponse>();
export class LaneSelectBase extends React.Component<
  ConnectedRouterProps & DProps & SProps
> {
  private column: ColumnType = [
    {
      Header: 'Línea',
      accessor: 'name',
      filterable: true,
    },
    {
      Header: 'Centro',
      accessor: 'center_name',
      filterable: true,
    },
  ];

  render() {
    return (
      <>
        <StatusBar />
        <TableWrapLaneSelect>
          <Typography component={'div'} style={{height: '100%'}}>
            <TableComponent
              columnFormat={this.column}
              defaultPageSize={20}
              service={LaneSelectServices.get}
              accessor={'areas'}
              options={false}
              customFilterFunction={customFilterFunction}
              propsToOwnTable={{
                getTrProps: (_: any, rowInfo: any) => ({
                  onClick: () => {
                    this.props.selectLane(
                      rowInfo.original.id,
                      rowInfo.original.name,
                      rowInfo.original.dump_barcode,
                      rowInfo.original.auto_palletise,
                    );
                    this.props.push('/lanemanager');
                  },
                  style: {
                    cursor: 'pointer',
                    background: colorSelected(rowInfo, this.props.lane.laneId),
                  },
                }),
              }}
            />
          </Typography>
        </TableWrapLaneSelect>
      </>
    );
  }
}

const customFilterFunction = (data: LaneSelectUniqe[]) => {
  /*if (process.env.APP_SHOW_ALL_AREAS) {
    return data;
  }*/

  return data.filter((lane: LaneSelectUniqe) => {
    return !!+lane.manufacturing;
  });
};

const selectLane = (
  lane: string,
  name: string,
  dumpBarcode: string,
  autoPalletise: boolean,
) => LaneSelectActions.select(lane, name, dumpBarcode, autoPalletise);

// const clearLaneDump = () => ;
export const LaneSelect = connect<SProps, DProps, {}, AppStoreState>(
  state => ({lane: LaneSelectSelectors.getLane(state)}),
  {push, selectLane},
)(LaneSelectBase);

const TableWrapLaneSelect = styled.div`
  padding-top: 10px;
  padding-bottom: 8px;
  height: calc(100% - 58px);
  width: 50%;
  margin: auto;
`;
