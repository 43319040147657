import * as React from 'react';
import styled from 'styled-components';

import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import Draggable from 'react-draggable';

const modalStyles = {
    root: {
        backgroundColor: '#3333',
        display: 'table',
        width: '100%',
        height: '100%',
    },
    backdrop: {
        backgroundColor: 'transparent',
    },
};

type Props = {
    classes: {[k: string]: string};
    dialogClasses?: any;
    open: boolean | string | number;
    title?: string;
    handleClose(...args: any[]): void;
    buttons?: any;
    children?: any;
    fullWidth?: boolean;
    maxWidth?: any;
    draggable?: boolean;
};

export const TableModal = withStyles(modalStyles)((props: Props) => {
    const {classes, dialogClasses, open, title, buttons, children, handleClose, fullWidth, draggable, maxWidth} = props;

    if (draggable) {
        //
        return (
            <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
                <Dialog
                    open={getOpen(open)}
                    onClose={handleClose}
                    aria-labelledby="draggable-dialog-title"
                    BackdropProps={{
                        className: classes.backdrop,
                    }}
                    disableBackdropClick={true}
                    maxWidth={maxWidth}
                >
                    <DialogTitle style={{cursor: 'move'}} id="draggable-dialog-title">
                        <TitleWrapper>
                            <span style={{alignSelf: 'center'}}>{title}</span>
                            <Button color="secondary" onClick={handleClose}>
                                <CloseIcon />
                            </Button>
                        </TitleWrapper>
                    </DialogTitle>
                    <DialogContent>{children}</DialogContent>
                    {buttons && <DialogActions>{buttons}</DialogActions>}
                </Dialog>
            </Draggable>
        );
    } else {
        return (
            <Dialog classes={dialogClasses} fullWidth={fullWidth} maxWidth={maxWidth} open={getOpen(open)} onClose={handleClose} scroll='paper'>
                <DialogTitle>
                    <TitleWrapper>
                        <span style={{alignSelf: 'center'}}>{title}</span>
                        <Button color="secondary" onClick={handleClose}>
                            <CloseIcon />
                        </Button>
                    </TitleWrapper>
                </DialogTitle>
                <DialogContent>{children}</DialogContent>
                {buttons && <DialogActions>{buttons}</DialogActions>}
            </Dialog>
        );
    }
});

const getOpen = (open: boolean | string | number) => {
    if (typeof open === 'string') return !!open;
    if (typeof open === 'number') {
        if (open <= 0) return false;
        else return true;
    }
    if (typeof open === 'boolean') return open;
    return false;
};

const TitleWrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-grow: 1;
    justify-content: space-between;
`;
