import {DumpData, LaneDump} from './types/index';
import {Batch} from 'src/app/lanemanager/lane/store/types/index';
import {PaginatedServiceArgs} from 'src/types';
import {guardedFetcher} from 'src/utils/guardedFetcher';

const prefix = 'production/info';
const prefixDump = 'production/control/dump';

export const DumpsServices = {
    getAllPaginated: ({params, args}: PaginatedServiceArgs) => {
        return guardedFetcher.get<PaginateResponse<Batch>>(`${prefix}/area/${args || 'NO_EXISTE_LINEA'}/dumps`, {params});
    },
    cancelDump: (dumpId: string) => guardedFetcher.get(`${prefix}/dump/cancel/${dumpId}`),
    new: (laneDumpBarcode: string, barcodeLogisticunit: string) =>
        guardedFetcher.post<LaneDump>(`${prefixDump}/create/${laneDumpBarcode}/logisticunit/${barcodeLogisticunit.replace('/', '-')}`, {}),
    reactivateBatchCheck: (dumpId: string) => guardedFetcher.get(`${prefix}/dump/reactivate-batch-check/${dumpId}`),
    reactivateBatchExecute: (dumpId: string) => guardedFetcher.patch(`${prefix}/dump/reactivate-batch/${dumpId}`),
    modifyDump: (dumpId: string, numBoxes: number) => guardedFetcher.post(`${prefix}/dump/modify-manually/${dumpId}/boxes/${numBoxes}`),
    createDumpLater: (data: DumpData) => guardedFetcher.post<LaneDump>(`${prefixDump}/create-later`, data),
};
