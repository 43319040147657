import {ConnectedRouter} from 'connected-react-router';
import * as React from 'react';
import {connect} from 'react-redux';
import {Redirect, Route, Switch} from 'react-router-dom';
import {AdministrationIndexPage} from 'src/app/administration/AdministrationIndexPage';
import {GrantsIndexPage} from 'src/app/grants/GrantsIndexPage';
import {Graphics} from 'src/app/graphics/Graphics';
import {InformationIndexPage} from 'src/app/information/InformationIndexPage';
import {LaneSelectSelectors} from 'src/app/laneSelect/store';
import {LaneSelect} from 'src/app/laneSelect/LaneSelect';
import {LoggingIndexPage} from 'src/app/logging/LoggingIndexPage';
import {LoginSelectors} from 'src/app/login/store/login.selectors';
import {Login} from 'src/app/login/Login';
import {MessagesIndexPage} from 'src/app/messages/MessagesIndexPage';
import {PalletInformation} from 'src/app/lanemanager/palletInformation/PalletInformation';
import {PalletInformationErp} from 'src/app/lanemanager/palletInformation/PalletInformationErp';
import {LaneIndexPage} from 'src/app/lanemanager/LaneIndexPage';
import {DrawerMenu} from 'src/app/menu/Menu';
import {GuardAuthorizationRoute, GuardSingleAccessRoute} from 'src/global/authorization/GuardAuthorizationRoute';
import {NotFound} from 'src/global/dummy/NotFound';
import {AppStoreState} from 'src/store';
import {AccessCard, EmployeesStats, UserDataCard} from 'src/terminal/containers';
import {getAuthorizedUser} from 'src/terminal/store/selectors';
import TerminalStats from 'src/terminal/terminal-stats';
import {Maps} from './app/map/Maps';
import {SystemIndexPage} from "./app/system/SystemIndexPage";

interface RoutesProps {
    history: any;
    authorizedUser: string;
    lane: ReturnType<typeof LaneSelectSelectors.getLane>;
    userData: ReturnType<typeof LoginSelectors.getUserData>;
}

interface RedirectRouteProps {
    [key: string]: any;
}

const RedirectRoute: React.FunctionComponent<RedirectRouteProps> = props => {
    const computedId = props.computedMatch.params.id;
    const authUser = props.authorized;
    if (computedId === authUser) return <Route {...props} />;
    if (authUser > 0) return <Redirect to={`/user/${authUser}`} />;
    return <Redirect to="/acceso" />;
};

const RoutesBase: React.FunctionComponent<RoutesProps> = props => {
    return (
        <>
            <ConnectedRouter history={props.history}>
                <Switch>
                    <Route path="/graphics" component={Graphics} exact={false} />
                    <GuardAuthorizationRoute userData={props.userData} path="/" exact={true} render={() => <DrawerMenu static={true} />} />
                    <Route path="/login" exact={true} component={Login} />
                    <Route path="/acceso" exact={true} component={AccessCard} />
                    <Route path="/graphic/:laneId" exact={true} component={Graphics} />
                    <RedirectRoute path="/user/:id" exact={true} component={UserDataCard} authorized={props.authorizedUser} />
                    <Route path="/users/stats" exact={true} component={EmployeesStats} />
                    <Route path="/users/detailed-stats" exact={true} component={TerminalStats} />
                    <GuardAuthorizationRoute userData={props.userData} path="/system" component={SystemIndexPage} />
                    <GuardAuthorizationRoute userData={props.userData} path="/administration" component={AdministrationIndexPage} />
                    <GuardAuthorizationRoute userData={props.userData} path="/messages" component={MessagesIndexPage} />
                    <GuardAuthorizationRoute userData={props.userData} path="/information" component={InformationIndexPage} />
                    <GuardAuthorizationRoute userData={props.userData} path="/lanemanager" component={LaneIndexPage} lane={props.lane} />
                    <GuardAuthorizationRoute userData={props.userData} path="/selectlane" exact={true} component={LaneSelect} />
                    <GuardAuthorizationRoute userData={props.userData} path="/grants" component={GrantsIndexPage} />
                    <GuardAuthorizationRoute userData={props.userData} path="/logging" component={LoggingIndexPage} />
                    <GuardSingleAccessRoute
                        accessSet={['PALLETS_INFORMATION_AND_REGISTRY']}
                        // userData={props.userData}
                        path="/pallet-info"
                        component={PalletInformation}
                    />
                    <GuardSingleAccessRoute
                        accessSet={['PALLETS_INFORMATION_AND_REGISTRY']}
                        // userData={props.userData}
                        path="/pallet-info-erp"
                        component={PalletInformationErp}
                    />
                    <GuardSingleAccessRoute
                        accessSet={['BLUEPRINT_VIEW']}
                        // userData={props.userData}
                        path="/blueprints/:blueprint?"
                        component={Maps}
                    />
                    <Route component={NotFound} />
                </Switch>
            </ConnectedRouter>
        </>
    );
};

const mapStateToProps = (state: AppStoreState) => ({
    authorizedUser: getAuthorizedUser(state),
    userData: LoginSelectors.getUserData(state),
    lane: LaneSelectSelectors.getLane(state),
});

export const Routes = connect(mapStateToProps)(RoutesBase);
