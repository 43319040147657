import localForage from 'localforage';

import {SharedServices} from 'src/shared/shared.services';
import {secondNormalizationGetKeys} from 'src/global/authorization/normalizeGrants';
import {GrantGroupsRanks, GrantSameNames} from 'src/global/authorization/ranking';
import {fetchWithFeedback} from 'src/utils/fetcherValidate';
import {fillStorage, getObjectFromStorage} from 'src/utils/localforage';

export type Ruleset = {
    grants: Grant[];
    // rulegroups: Rulegroup[];
    grantsets: Array<keyof typeof GrantGroupsRanks>;
    isAdmin: boolean;
};
export type Grant = {
    id: string;
    url: string;
    same_name: keyof typeof GrantSameNames;
    same_category: string;
    method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE' | '*';
    name: string;
    category: string;
};

export type Grantset = {
    id: string;
    same_name: keyof typeof GrantGroupsRanks;
    same_category: string;
    name: string;
    category: string;
};

// export type Rulegroup = {name: string};

export let RULESET: Ruleset = {
    grants: [],
    grantsets: [],
    isAdmin: false,
};


export const resetRuleset = () => {
    RULESET = {
        grants: [],
        grantsets: [],
        isAdmin: false,
    };
};
export const GetGrantSet = async (loginGrants?: Omit<Ruleset, 'isAdmin'>) => {
    let set;
    if (loginGrants) set = loginGrants;
    if (RULESET.grants && RULESET.grantsets && !RULESET.grants.length && !RULESET.grantsets.length) {
        if (!set) {
            const length = await rulesetInstance.length();
            if (length <= 1) {
                set = await fetchWithFeedback(SharedServices.getGrants());
                await rulesetInstance.clear();
                await fillStorage(rulesetInstance, set);
            } else set = await getObjectFromStorage(rulesetInstance);
        }
        RULESET.grants = set.grants;
        const normalized = secondNormalizationGetKeys(set.grantsets);
        RULESET.grantsets = normalized;
        RULESET.isAdmin = normalized.indexOf('ADMIN') > -1 || normalized.indexOf('SYSTEM') > -1 ? true : false;
    }

    return Array.isArray(set) ? false : true;
};


export const rulesetInstance = localForage.createInstance({
    name: 'ruleset-store',
    version: 1.0,
    storeName: 'ruleset-store',
});

export const IsInRuleset = (grantsetSameName?: keyof typeof GrantGroupsRanks | Array<keyof typeof GrantGroupsRanks>) => {
    if (grantsetSameName === 'SYSTEM') {
        const isSystem = RULESET.grantsets.includes(grantsetSameName);
        if (isSystem) return true;
        if (RULESET.isAdmin && !isSystem) return false;
    }
    if (grantsetSameName === 'SHOW_MENU') {
        return RULESET.isAdmin || !RULESET.grantsets.includes('HIDE_MENU');
    }
    if (!RULESET.grantsets.length) return false;
    if (RULESET.isAdmin) return true;
    if (!grantsetSameName) return false;
    if (Array.isArray(grantsetSameName)) {
        const results: boolean[] = [];
        for (const acc of grantsetSameName) {
            results.push(RULESET.grantsets.indexOf(acc) === -1 ? false : true);
        }
        return results.indexOf(true) === -1 ? false : true;
    } else return RULESET.grantsets.indexOf(grantsetSameName) === -1 ? false : true;
};

export const GroupedGrantsets = {
    laneControl: () =>
        IsInRuleset([
            'LANEMANAGER_COMMON_VIEW',
            'LANEMANAGER_COMMON_TOTAL',
            'LANEMANAGER_STATE_TOTAL',
            'LANEMANAGER_STATE_VIEW',
            'LANEMANAGER_PRODPARTS_VIEW',
            'LANEMANAGER_PRODPARTS_TOTAL',
            'LANEMANAGER_AREASHIFTS_TOTAL',
            'LANEMANAGER_AREASHIFTS_VIEW',
            'LANEMANAGER_DOWNTIMES_TOTAL',
            'LANEMANAGER_DOWNTIMES_VIEW',
            'LANEMANAGER_PALLETS_VIEW',
            'LANEMANAGER_DUMPS_VIEW',
            'LANEMANAGER_PERSONNEL_TOTAL',
            'LANEMANAGER_PERSONNEL_VIEW',
            'LANEMANAGER_PERFORMANCE_VIEW',
        ]),
    administrationPresence: () =>
        IsInRuleset([
            'AREAS_TOTAL',
            'AREAS_VIEW',
            'EMPLOYEES_TOTAL',
            'EMPLOYEES_VIEW',
            'EMPLOYEES_VIEW_EXCEL',
            'LIFT_PALLETS',
            'CARDS_TOTAL',
            'ADMIN',
            'SYSTEM',
            'TASKS_TOTAL',
            'TASKS_VIEW',
            'AREAS_TOTAL',
            'AREAS_VIEW',
            'CENTERS_TOTAL',
            'CENTERS_VIEW',
            'AREASETS_TOTAL',
            'AREASETS_VIEW',
            'DOWNTIMECAUSES_TOTAL',
            'DOWNTIMECATEGORIES_VIEW',
            'DOWNTIMECATEGORIES_TOTAL',
            'DOWNTIMECATEGORIES_VIEW',
            'AREAS_TOTAL',
            'AREAS_VIEW',
            'JOBTYPES_TOTAL',
            'JOBTYPES_VIEW',
            'NON_PRODUCTIVE_TIMES_TOTAL',
            'NON_PRODUCTIVE_TIMES_VIEW',
            'RATES_TOTAL',
            'RATES_VIEW',
            'EMPLOYEE_COSTS_TOTAL',
            'EMPLOYEE_COSTS_VIEW',
            'FESTIVES_TOTAL',
            'FESTIVES_VIEW',
            'CALCULATE_COST_EXECUTIONS_TOTAL',
            'CALCULATE_COST_EXECUTIONS_VIEW'
        ]),
    administrationDevices: () =>
        IsInRuleset(['DEVICES_TOTAL', 'DEVICES_VIEW', 'BARGUNBASES_TOTAL', 'BARGUNBASES_VIEW', 'BARREADERS_TOTAL', 'BARREADERS_VIEW', 'PTABLES_TOTAL', 'PTABLES_VIEW']),
    reports: () =>
        IsInRuleset([
            'REPORTS_EMPLOYEES_WORKING_VIEW',
            'REPORTS_REGISTERED_TASKS_VIEW',
            'REPORTS_REGISTERED_TASKS_EXCEL',
            'REPORTS_TASKS_BY_EMPLOYEE_VIEW',
            'REPORTS_TASKS_BY_EMPLOYEE_EXCEL',
            'REPORTS_WORKSHIFT_TIMES_VIEW',
            'REPORTS_WORKSHIFT_TIMES_EXCEL',
            'REPORTS_TASKS_TIME_PER_AREA_VIEW',
            'REPORTS_TASKS_TIME_PER_AREA_EXCEL',
            'REPORTS_AREASHIFTS_VIEW',
            'REPORTS_AREASHIFTS_EXCEL',
            'REPORTS_BOXES_VIEW',
            'REPORTS_PALLETS_VIEW',
            'REPORTS_PALLETS_VIEW_EXCEL',
            'REPORTS_PALLETS_INFO_VIEW',
            'REPORTS_PALLETS_INFO_EXCEL',
            'REPORTS_BATCHES_INFO_VIEW',
            'REPORTS_BATCHES_INFO_EXCEL',
            'REPORTS_PRODPARTS_VIEW',
        ]),
    kpis: () => IsInRuleset(['KPIS_VIEW', 'KPIS_CONTROL_TOTAL']),
    messages: () => IsInRuleset(['MESSAGES_PERSONAL', 'MESSAGES_CREATE', 'MESSAGES_VIEW']),
    logs: () => IsInRuleset(['INCIDENCE_LOG_VIEW', 'ERROR_LOG_VIEW']),

    // usersAndGrants: () => true,
};

export const ModulesGrantsSet = {
    settings: () => IsInRuleset(['INCIDENCE_LOG_VIEW', 'ERROR_LOG_VIEW', 'SYSTEM']),
    connectedFactory: () =>
        IsInRuleset([
            'AREAS_TOTAL',
            'AREAS_VIEW',
            'CENTERS_TOTAL',
            'CENTERS_VIEW',
            'DOWNTIMECAUSES_TOTAL',
            'DOWNTIMECATEGORIES_VIEW',
            'DOWNTIMECATEGORIES_TOTAL',
            'DOWNTIMECATEGORIES_VIEW',
            'DEVICES_TOTAL',
            'DEVICES_VIEW',
            'BARGUNBASES_TOTAL',
            'BARGUNBASES_VIEW',
            'BARREADERS_TOTAL',
            'BARREADERS_VIEW',
            'PTABLES_TOTAL',
            'PTABLES_VIEW',
        ]),
    employees: () =>
        IsInRuleset([
            'EMPLOYEES_TOTAL',
            'EMPLOYEES_VIEW',
            'EMPLOYEES_VIEW_EXCEL',
            'CARDS_TOTAL',
            'TASKS_TOTAL',
            'TASKS_VIEW',
            'AREAS_TOTAL',
            'AREAS_VIEW',
            'JOBTYPES_TOTAL',
            'JOBTYPES_VIEW',
            'REPORTS_EMPLOYEES_WORKING_VIEW',
            'REPORTS_REGISTERED_TASKS_VIEW',
            'REPORTS_REGISTERED_TASKS_EXCEL',
            'REPORTS_TASKS_BY_EMPLOYEE_VIEW',
            'REPORTS_TASKS_BY_EMPLOYEE_EXCEL',
            'REPORTS_WORKSHIFT_TIMES_VIEW',
            'REPORTS_WORKSHIFT_TIMES_EXCEL',
            'REPORTS_TASKS_TIME_PER_AREA_VIEW',
            'REPORTS_TASKS_TIME_PER_AREA_EXCEL',
            'REPORTS_AREASHIFTS_VIEW',
            'REPORTS_AREASHIFTS_EXCEL',
        ]),
    costManagement: () => IsInRuleset(['ADMIN']),
    traceability: () => IsInRuleset(['PALLETS_INFORMATION_AND_REGISTRY']),
    planning: () => true,
    visualFactory: () => IsInRuleset('BLUEPRINT_VIEW'),
    messages: () => IsInRuleset(['MESSAGES_PERSONAL', 'MESSAGES_CREATE', 'MESSAGES_VIEW']),
    kpis: () => IsInRuleset(['KPIS_VIEW', 'KPIS_CONTROL_TOTAL']),
};
