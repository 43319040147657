import * as React from 'react';
import {GroupedGrantsets, IsInRuleset, ModulesGrantsSet, RULESET} from '../../global/authorization/grantSet';
import List from '@material-ui/core/List';
import {SecondaryListItem} from './styled/SecondaryListItem';
import {MenuListItem} from './styled/MenuListItem';
import {WrapMenuList} from './styled/Wrap';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {PrimaryListItem} from './styled/PrimaryListItem';
import {ListItemIcon, Typography} from '@material-ui/core/es';
import {LaneSelectActions, LaneSelectSelectors} from 'src/app/laneSelect/store';
import {Collapse, Paper} from '@material-ui/core';
import {DynamicMenuListState, MenuListState, ModuleKey, ModulesListType} from './store/types';
import {push} from 'connected-react-router';
import {DynamicMenuListAction, MenuListAction} from './store/actions';
import {connect} from 'react-redux';
import {AppStoreState} from '../../store';
import {ParametersSelectors} from '../../utils/Parameters/selectors';
import {DynamicMenuListSelectors, MenuListSelectors, ModulesListSelectors} from './store/selectors';
// ICONOS
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import LabelImportant from "@material-ui/icons/LabelImportant";
import Map from "@material-ui/icons/Map";
import PalletsIcon from "@material-ui/icons/Dashboard";
import MultilaneChart from "@material-ui/icons/MultilineChart";
import People from "@material-ui/icons/People";
import Settings from "@material-ui/icons/Settings";
import SettingsApp from "@material-ui/icons/SettingsApplications";
import Info from "@material-ui/icons/Info";
import BarChartIcon from "@material-ui/icons/BarChart";
import KeyIcon from "@material-ui/icons/VpnKey";
import EmailIcon from "@material-ui/icons/Email";
import ErrorIcon from "@material-ui/icons/Error";
import Security from "@material-ui/icons/Security";
import NavigateNext from "@material-ui/icons/NavigateNext";
import Router from "@material-ui/icons/Router";
import SupervisorAccount from "@material-ui/icons/SupervisorAccount";
import Timeline from "@material-ui/icons/Timeline";
import Home from "@material-ui/icons/Home";
import ChatBubble from "@material-ui/icons/ChatBubble";
import TableChart from "@material-ui/icons/TableChart";
import MonetizationOn from "@material-ui/icons/MonetizationOn";
import {DynamicMenuListItem} from "./styled/DynamicMenuListItem";
import { AreaControlServices } from '../administration/areaControl/store/services';
import {fetchWithFeedback} from 'src/utils/fetcherValidate';


type State = {
    actualOpenModule : ModuleKey;
    selectedMenuItem : string | undefined;
    [ModuleKey.system]: boolean;
    [ModuleKey.settings]: boolean;
    [ModuleKey.visualFactory]: boolean;
    [ModuleKey.plannig]: boolean;
    [ModuleKey.messages]: boolean;
    [ModuleKey.traceability]: boolean;
    [ModuleKey.employees]: boolean;
    [ModuleKey.costsManagement]: boolean;
    [ModuleKey.kpis]: boolean;
    [ModuleKey.connectedFactory]: boolean;
    selectedDynamicModule : string;
    openDirectAccessAreas: boolean;
    directAccessareasList: any[];
};

const initialState : Readonly<State> = {
    actualOpenModule : ModuleKey.none,
    selectedMenuItem : undefined,
    [ModuleKey.system]: false,
    [ModuleKey.settings]: false,
    [ModuleKey.visualFactory]: false,
    [ModuleKey.plannig]: false,
    [ModuleKey.messages]: false,
    [ModuleKey.traceability]: false,
    [ModuleKey.employees]: false,
    [ModuleKey.costsManagement]: false,
    [ModuleKey.kpis]: false,
    [ModuleKey.connectedFactory]: false,
    selectedDynamicModule: '',
    openDirectAccessAreas: false,
    directAccessareasList: []
};

type SProps = {
    lane: ReturnType<typeof LaneSelectSelectors.getLane>;
    nonProductiveTimeManagement: boolean;
    menuType: string;
    menuListState: MenuListState;
    dynamicMenuListState:DynamicMenuListState;
    modules: ModulesListType;
};

type DProps = {
    push(loc: string): void;
    storeMenuListState(state: MenuListState): void;
    storeDynamicMenuListState(state: DynamicMenuListState) : void;
    selectLane(
        lane: string,
        name: string,
        dumpBarcode: string,
        autoPalletise: boolean,
    ): void;
};

type Props = {
    handleRoute: () => void;
};

type MenuListProps = Props & SProps & DProps;

export class MenuListComponent extends React.Component<MenuListProps, State> {
    // constructor(props : MenuListProps) {
    //     super(props);
    //     console.log('CONSTRUIDO');
    //     this.state = initialState;
    //
    // }

    readonly state = initialState;

    componentDidMount() {
        console.log('MOUNT');
        if (this.props.menuListState) {
            this.setState({
                selectedMenuItem: this.props.menuListState.selectedItem,
            });
            this.handleClickModule(this.props.menuListState.openModule);
        }

        if (this.props.dynamicMenuListState) {
            this.setState({
                selectedMenuItem: this.props.dynamicMenuListState.selectedItem,
            });
            this.handleClickDynamicModule(this.props.dynamicMenuListState.openModule);
        }
    }

    // componentWillUnmount() {
    //     this.props.storeMenuListState({
    //         openModule: ModuleKey.none,
    //         selectedItem: undefined,
    //     });
    //
    //     this.props.storeDynamicMenuListState({
    //         openModule: '',
    //         selectedItem: undefined,
    //     });
    // }

    private handleClickModule(module: ModuleKey) {
        const actualOpenModule = this.state.actualOpenModule;

        if (actualOpenModule === module) {
            this.setModuleState(module, false);
            this.setState({
                actualOpenModule: ModuleKey.none,
            });
        } else {
            this.setModuleState(actualOpenModule, false);
            this.setModuleState(module, true);

            this.setState({
                actualOpenModule: module,
            });
        }

        this.props.storeMenuListState({
            openModule: module,
            selectedItem: this.props.menuListState.selectedItem,
        });
    }

    private handleClickDynamicModule(moduleId: string) {

        if (this.state.selectedDynamicModule === moduleId) {
            this.setState({
                selectedDynamicModule: '',
            });
        } else {
            this.setState({
                selectedDynamicModule: moduleId,
            });
        }
    }

    private handleClickDirectAccessAreas = async (open: boolean) => {
        const directAccessareasList = await fetchWithFeedback(AreaControlServices.getAllMyCurrentAreas(), {
            accessor: 'areas',
        });
        this.setState({directAccessareasList});

        this.setState({
            openDirectAccessAreas: open,
        });
    }

    private setModuleState(module: ModuleKey, state: boolean) {

        switch(module){
            case ModuleKey.system:
                this.setState({
                    [ModuleKey.system] : state
                })
                break;
            case ModuleKey.settings:
                this.setState({
                    [ModuleKey.settings]: state,
                });
                break;
            case ModuleKey.connectedFactory:
                this.setState({
                    [ModuleKey.connectedFactory]: state,
                });
                break;
            case ModuleKey.employees:
                this.setState({
                    [ModuleKey.employees]: state,
                });
                break;
            case ModuleKey.traceability:
                this.setState({
                    [ModuleKey.traceability]: state,
                });
                break;
            case ModuleKey.messages:
                this.setState({
                    [ModuleKey.messages]: state,
                });
                break;
            case ModuleKey.plannig:
                this.setState({
                    [ModuleKey.plannig]: state,
                });
                break;
            case ModuleKey.costsManagement:
                this.setState({
                    [ModuleKey.costsManagement]: state,
                });
                break;
            case ModuleKey.kpis:
                this.setState({
                    [ModuleKey.kpis]: state,
                });
                break;
            case ModuleKey.visualFactory:
                this.setState({
                    [ModuleKey.visualFactory]: state,
                });
                break;
        }
    }

    private getModuleIcon = (moduleId : string) => {
        switch(moduleId) {
            case 'module_settings':
                return (<Settings/>);
            case 'module_connected_factory':
                return (<Router/>);
            case 'module_employees':
                return (<SupervisorAccount/>);
            case 'module_cost_management':
                return (<MonetizationOn/>);
            case 'module_traceability':
                return (<Timeline/>);
            case 'module_messages':
                return (<ChatBubble/>);
            case 'module_planning':
                return (<TableChart/>);
            case 'module_kpis':
                return (<BarChartIcon/>);
            case 'module_visual_factory':
                return (<Map/>);
            default:
                return (<Settings/>);
        }
    }

    private handleGoHome = () => {
        console.log('INICIO');
        this.props.storeMenuListState({
            openModule: ModuleKey.none,
            selectedItem: undefined,
        });
        this.props.storeDynamicMenuListState({
            openModule:'',
            selectedItem: undefined,
        });
        this.props.push('/');
    };

    private classicMenuList = () => {
        const handleRoute = this.props.handleRoute;

        return (
            <WrapMenuList className="main_menu">
                {IsInRuleset('SHOW_MENU') && (
                    <List>
                        {/*INICIO*/}
                        <ListItem>
                            <ListItemText
                                style={{cursor: 'pointer'}}
                                primary={<PrimaryListItem icon={<LabelImportant />} text="Inicio" href="/" onClick={() => handleRoute()} />}
                                onClick={() => handleRoute()}
                            />
                        </ListItem>
                        {/*PLANOS*/}
                        {IsInRuleset('BLUEPRINT_VIEW') && (
                            <ListItem>
                                <ListItemText
                                    style={{cursor: 'pointer'}}
                                    primary={
                                        <a href="/blueprints" style={{display: 'flex', cursor: 'pointer'}}>
                                            <ListItemIcon>
                                                <Map />
                                            </ListItemIcon>
                                            <Typography style={{alignSelf: 'center'}}>Planos</Typography>
                                        </a>
                                        // <PrimaryListItem icon={<Map />} text="Planos" href="/blueprints" />
                                    }
                                    onClick={() => handleRoute()}
                                />
                            </ListItem>
                        )}
                        {/*DATOS DE PALE*/}
                        {IsInRuleset('PALLETS_INFORMATION_AND_REGISTRY') ? (
                            <ListItem>
                                <ListItemText
                                    primary={<PrimaryListItem icon={<PalletsIcon />} text={'Datos de palé'} href="/pallet-info" onClick={() => handleRoute()} />}
                                    style={{cursor: 'pointer'}}
                                />
                            </ListItem>
                        ) : null}
                        {/*SELECCIONAR LINEA*/}
                        {GroupedGrantsets.laneControl() && (
                            <ListItem>
                                <ListItemText
                                    primary={<PrimaryListItem text="Seleccionar línea" icon={<MultilaneChart />} href="/selectlane" onClick={() => handleRoute()} />}
                                    style={{cursor: 'pointer'}}
                                    secondary={
                                        this.props.lane &&
                                        this.props.lane.laneId && (
                                            <List>
                                                <SecondaryListItem
                                                    icon={<NavigateNext />}
                                                    text={`${this.props.lane.laneName}`}
                                                    href="/lanemanager/lane"
                                                    onClick={() => handleRoute()}
                                                />
                                            </List>
                                        )
                                    }
                                />
                            </ListItem>
                        )}
                        {/*SECCION DE PRESENCIA*/}
                        {GroupedGrantsets.administrationPresence() && (
                            <React.Fragment>
                                <ListItem>
                                    <ListItemText
                                        primary={<PrimaryListItem text="Sección de presencia" icon={<People />} href="/administration" onClick={() => handleRoute()} />}
                                    />
                                </ListItem>
                                <List className="menu_secondary">
                                    {IsInRuleset(['EMPLOYEES_TOTAL', 'EMPLOYEES_VIEW', 'EMPLOYEES_VIEW_EXCEL']) && (
                                        <SecondaryListItem icon={<NavigateNext />} text="Gestión de empleados" href="/administration/employees" onClick={() => handleRoute()} />
                                    )}
                                    {IsInRuleset('CARDS_TOTAL') && (
                                        <SecondaryListItem icon={<NavigateNext />} text="Gestión de tarjetas" href="/administration/cards" onClick={() => handleRoute()} />
                                    )}
                                    {IsInRuleset(['TASKS_TOTAL', 'TASKS_VIEW']) && (
                                        <SecondaryListItem icon={<NavigateNext />} text="Gestión de tareas" href="/administration/tasks" onClick={() => handleRoute()} />
                                    )}
                                    {IsInRuleset(['AREAS_TOTAL', 'AREAS_VIEW']) && (
                                        <SecondaryListItem icon={<NavigateNext />} text="Gestión de areas" href="/administration/areas" onClick={() => handleRoute()} />
                                    )}
                                    {IsInRuleset(['CENTERS_TOTAL', 'CENTERS_VIEW']) && (
                                        <SecondaryListItem icon={<NavigateNext />} text="Gestión de centros" href="/administration/centers" onClick={() => handleRoute()} />
                                    )}
                                    {IsInRuleset(['DOWNTIMECAUSES_TOTAL', 'DOWNTIMECATEGORIES_VIEW']) && (
                                        <SecondaryListItem
                                            icon={<NavigateNext />}
                                            text="Gestión de causas de las paradas"
                                            href="/administration/causes"
                                            onClick={() => handleRoute()}
                                        />
                                    )}
                                    {IsInRuleset(['DOWNTIMECATEGORIES_TOTAL', 'DOWNTIMECATEGORIES_VIEW']) && (
                                        <SecondaryListItem
                                            icon={<NavigateNext />}
                                            text="Gestión de categorías de las paradas"
                                            href="/administration/categories"
                                            onClick={() => handleRoute()}
                                        />
                                    )}
                                    {IsInRuleset(['AREAS_TOTAL', 'AREAS_VIEW']) && (
                                        <SecondaryListItem
                                            icon={<NavigateNext />}
                                            text="Gestión de los grupos de tareas"
                                            href="/administration/groups"
                                            onClick={() => handleRoute()}
                                        />
                                    )}
                                    {IsInRuleset(['JOBTYPES_TOTAL', 'JOBTYPES_VIEW']) && (
                                        <SecondaryListItem
                                            icon={<NavigateNext />}
                                            text="Gestión de los roles de trabajadores"
                                            href="/administration/jobtypes"
                                            onClick={() => handleRoute()}
                                        />
                                    )}
                                </List>
                            </React.Fragment>
                        )}
                        {/*SECCION DE AJUSTES*/}
                        {GroupedGrantsets.administrationDevices() && (
                            <React.Fragment>
                                <ListItem>
                                    <ListItemText
                                        primary={<PrimaryListItem text="Sección de ajustes" icon={<Settings />} href="/administration" onClick={() => handleRoute()} />}
                                    />
                                </ListItem>
                                <List className="menu_secondary">
                                    {IsInRuleset(['DEVICES_TOTAL', 'DEVICES_VIEW']) && (
                                        <SecondaryListItem icon={<NavigateNext />} text="Gestión de dispositivos" href="/administration/devices" onClick={() => handleRoute()} />
                                    )}
                                    {IsInRuleset(['BARGUNBASES_TOTAL', 'BARGUNBASES_VIEW']) && (
                                        <SecondaryListItem
                                            icon={<NavigateNext />}
                                            text="Gestión de bases de pistolas"
                                            href="/administration/barguns"
                                            onClick={() => handleRoute()}
                                        />
                                    )}
                                    {IsInRuleset(['BARREADERS_TOTAL', 'BARREADERS_VIEW']) && (
                                        <SecondaryListItem
                                            icon={<NavigateNext />}
                                            text="Gestión de lectores fijos"
                                            href="/administration/bar-readers"
                                            onClick={() => handleRoute()}
                                        />
                                    )}
                                    {IsInRuleset(['PTABLES_TOTAL', 'PTABLES_VIEW']) && (
                                        <SecondaryListItem
                                            icon={<NavigateNext />}
                                            text="Gestión de mesas de paletizado"
                                            href="/administration/ptables"
                                            onClick={() => handleRoute()}
                                        />
                                    )}
                                    {IsInRuleset() && this.props.nonProductiveTimeManagement && (
                                        <SecondaryListItem
                                            icon={<NavigateNext />}
                                            text="Gestión de  tiempos no productivos"
                                            href="/administration/non-productive-times"
                                            onClick={() => handleRoute()}
                                        />
                                    )}
                                    {IsInRuleset([]) && (
                                        <SecondaryListItem
                                            icon={<NavigateNext />}
                                            text="Gestión de parámetros del sistema"
                                            href="/administration/parameters"
                                            onClick={() => handleRoute()}
                                        />
                                    )}
                                </List>
                            </React.Fragment>
                        )}
                        {/*INFORMACION Y REPORTES*/}
                        {GroupedGrantsets.reports() && (
                            <React.Fragment>
                                <ListItem>
                                    <ListItemText
                                        primary={<PrimaryListItem text="Información y reportes" icon={<Info />} href="/information" onClick={() => handleRoute()} />}
                                        style={{cursor: 'pointer'}}
                                    />
                                </ListItem>
                                <List className="menu_secondary">
                                    {IsInRuleset('REPORTS_EMPLOYEES_WORKING_VIEW') && (
                                        <SecondaryListItem icon={<NavigateNext />} text="Trabajando actualmente" href="/information/working-now" onClick={() => handleRoute()} />
                                    )}
                                    {IsInRuleset(['REPORTS_REGISTERED_TASKS_VIEW', 'REPORTS_REGISTERED_TASKS_EXCEL']) && (
                                        <SecondaryListItem icon={<NavigateNext />} text="Registro de tareas" href="/information/personnel" onClick={() => handleRoute()} />
                                    )}
                                    {IsInRuleset(['REPORTS_TASKS_BY_EMPLOYEE_VIEW', 'REPORTS_TASKS_BY_EMPLOYEE_EXCEL']) && (
                                        <SecondaryListItem icon={<NavigateNext />} text="Tareas por empleado" href="/information/tasks-employee" onClick={() => handleRoute()} />
                                    )}
                                    {IsInRuleset(['REPORTS_WORKSHIFT_TIMES_VIEW', 'REPORTS_WORKSHIFT_TIMES_EXCEL']) && (
                                        <SecondaryListItem icon={<NavigateNext />} text="Tiempos por turno" href="/information/workshift-times" onClick={() => handleRoute()} />
                                    )}
                                    {IsInRuleset() && (
                                        <SecondaryListItem icon={<NavigateNext />} text="Tiempos por area" href="/information/area-times" onClick={() => handleRoute()} />
                                    )}
                                    {IsInRuleset(['REPORTS_TASKS_TIME_PER_AREA_VIEW', 'REPORTS_TASKS_TIME_PER_AREA_EXCEL']) && (
                                        <SecondaryListItem
                                            icon={<NavigateNext />}
                                            text="Tiempos por area y tarea"
                                            href="/information/workshift-task-area"
                                            onClick={() => handleRoute()}
                                        />
                                    )}
                                    {IsInRuleset(['REPORTS_AREASHIFTS_VIEW', 'REPORTS_AREASHIFTS_EXCEL']) && (
                                        <SecondaryListItem icon={<NavigateNext />} text="Todos los turnos" href="/information/areashifts" onClick={() => handleRoute()} />
                                    )}
                                    {IsInRuleset(['REPORTS_PALLETS_VIEW', 'REPORTS_PALLETS_VIEW_EXCEL']) && (
                                        <SecondaryListItem icon={<NavigateNext />} text="Palés registrados" href="/information/pallets" onClick={() => handleRoute()} />
                                    )}
                                    {IsInRuleset('REPORTS_PRODPARTS_VIEW') && (
                                        <SecondaryListItem icon={<NavigateNext />} text="Partes de producción" href="/information/prodparts" onClick={() => handleRoute()} />
                                    )}
                                    {IsInRuleset('REPORTS_BOXES_VIEW') && (
                                        <SecondaryListItem icon={<NavigateNext />} text="Cajas registradas" href="/information/boxes" onClick={() => handleRoute()} />
                                    )}
                                    {IsInRuleset(['REPORTS_PALLETS_INFO_VIEW', 'REPORTS_PALLETS_INFO_EXCEL']) && (
                                        <SecondaryListItem icon={<NavigateNext />} text="Palés Info" href="/information/pallets-info" onClick={() => handleRoute()} />
                                    )}
                                    {IsInRuleset(['REPORTS_BATCHES_INFO_VIEW', 'REPORTS_BATCHES_INFO_EXCEL']) && (
                                        <SecondaryListItem icon={<NavigateNext />} text="Orígenes Info" href="/information/batches-info" onClick={() => handleRoute()} />
                                    )}
                                </List>
                            </React.Fragment>
                        )}
                        {/*KPIs*/}
                        {GroupedGrantsets.kpis() && (
                            <ListItem>
                                <ListItemText
                                    style={{cursor: 'pointer'}}
                                    primary={
                                        <a href="/information/kpis" style={{display: 'flex', cursor: 'pointer'}}>
                                            <ListItemIcon>
                                                <BarChartIcon />
                                            </ListItemIcon>
                                            <Typography style={{alignSelf: 'center'}}>KPIs</Typography>
                                        </a>
                                        // <PrimaryListItem icon={<Map />} text="Planos" href="/blueprints" />
                                    }
                                    onClick={() => handleRoute()}
                                />
                            </ListItem>
                        )}
                        {/*PERMISOS Y ACCESOS*/}
                        {IsInRuleset() && (
                            <React.Fragment>
                                <ListItem>
                                    <ListItemText
                                        primary={<PrimaryListItem text="Permisos y Accesos" icon={<KeyIcon />} href="/grants" onClick={() => handleRoute()} />}
                                        style={{cursor: 'pointer'}}
                                    />
                                </ListItem>
                                <List className="menu_secondary">
                                    <SecondaryListItem icon={<NavigateNext />} text="Usuarios del sistema" href="/grants/users" onClick={() => handleRoute()} />
                                    <SecondaryListItem icon={<NavigateNext />} text="Grupos de acceso" href="/grants/rulegroups" onClick={() => handleRoute()} />
                                    <SecondaryListItem icon={<NavigateNext />} text="Alcance de los grupos de acceso" href="/grants/selection" onClick={() => handleRoute()} />
                                </List>
                            </React.Fragment>
                        )}
                        {/*MENSAJES*/}
                        {GroupedGrantsets.messages() && (
                            <React.Fragment>
                                <ListItem>
                                    <ListItemText
                                        primary={<PrimaryListItem text="Mensajes" icon={<EmailIcon />} href="/messages" onClick={() => handleRoute()} />}
                                        style={{cursor: 'pointer'}}
                                    />
                                </ListItem>
                                <List className="menu_secondary">
                                    {IsInRuleset('MESSAGES_PERSONAL') && (
                                        <SecondaryListItem icon={<NavigateNext />} text="Bandeja de entrada" href="/messages/box" onClick={() => handleRoute()} />
                                    )}
                                    {IsInRuleset('MESSAGES_CREATE') && (
                                        <SecondaryListItem icon={<NavigateNext />} text="Crear nuevo" href="/messages/create" onClick={() => handleRoute()} />
                                    )}
                                    {IsInRuleset('MESSAGES_VIEW') && (
                                        <SecondaryListItem icon={<NavigateNext />} text="Todos los mensajes" href="/messages/all" onClick={() => handleRoute()} />
                                    )}
                                </List>
                            </React.Fragment>
                        )}
                        {/*LOG DE ERRORES E INCIDENCIAS*/}
                        {GroupedGrantsets.logs() && (
                            <React.Fragment>
                                <ListItem>
                                    <ListItemText
                                        primary={<PrimaryListItem text="Log de errores e incidencias" icon={<ErrorIcon />} href="/logging" onClick={() => handleRoute()} />}
                                        style={{cursor: 'pointer'}}
                                    />
                                </ListItem>
                                <List className="menu_secondary">
                                    {IsInRuleset('INCIDENCE_LOG_VIEW') && (
                                        <SecondaryListItem icon={<NavigateNext />} text="Incidencias" href="/logging/incidences" onClick={() => handleRoute()} />
                                    )}
                                    {RULESET &&
                                        Array.isArray(RULESET.grants) &&
                                        (RULESET.grantsets.indexOf('ERROR_LOG_VIEW') !== -1 || RULESET.grantsets.indexOf('SYSTEM') !== -1) && (
                                            <SecondaryListItem icon={<NavigateNext />} text="Errores" href="/logging/errors" onClick={() => handleRoute()} />
                                        )}
                                </List>
                            </React.Fragment>
                        )}
                        {/*COPIAS DE SEGURIDAD*/}
                        {IsInRuleset() && (
                            <ListItem>
                                <ListItemText
                                    primary={<PrimaryListItem text="Copias de seguridad" icon={<Security />} href="/administration/backups" onClick={() => handleRoute()} />}
                                    style={{cursor: 'pointer'}}
                                />
                            </ListItem>
                        )}
                    </List>
                )}
            </WrapMenuList>
        );
    };

    private menuList = () => {
        const handleRoute = (id: string) => {
            this.props.storeMenuListState({
                openModule: this.state.actualOpenModule,
                selectedItem: id,
            });
            this.props.handleRoute();
        };
        return (
            <WrapMenuList className="main_menu">
                {IsInRuleset('SHOW_MENU') && (
                    <List>
                        {/*LÍNEA ACTIVA*/}
                        {this.props.lane && this.props.lane.laneId && (
                            <ListItem
                                button
                                onClick={() => {
                                    this.props.push('/lanemanager/lane');
                                    //handleRoute();
                                }}
                            >
                                <ListItemText
                                    primary={
                                        <Paper
                                            elevation={3}
                                            style={{
                                                backgroundColor: '#60ff8c',
                                            }}
                                        >
                                            <Typography align="center" variant="h6">
                                                {this.props.lane.laneName}
                                            </Typography>
                                        </Paper>
                                    }
                                />
                            </ListItem>
                        )}
                        {/*INICIO*/}
                        <ListItem button onClick={this.handleGoHome}>
                            <ListItemIcon>
                                <Home />
                            </ListItemIcon>
                            <ListItemText primary="Inicio" />
                        </ListItem>

                        {/*SISTEMA*/}
                        {IsInRuleset('SYSTEM') && (
                            <>
                                <ListItem button
                                          onClick={() => this.handleClickModule(ModuleKey.system)}
                                >
                                    <ListItemIcon>
                                        <SettingsApp />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Sistema"
                                    />
                                    {this.state[ModuleKey.system] ? <ExpandLess /> : <ExpandMore />}
                                </ListItem>
                                <Collapse in={this.state[ModuleKey.system]} timeout="auto">
                                    <List disablePadding>
                                        {IsInRuleset() && (
                                            <>
                                                <MenuListItem
                                                    id='menu_item_system_functionalities'
                                                    text="Funcionalidades del cliente"
                                                    href="/system/functionalities"
                                                    onClick={handleRoute}
                                                />
                                                <MenuListItem
                                                    id='menu_item_system_menu_items'
                                                    text="Opciones de menú del cliente"
                                                    href="/system/menuItems"
                                                    onClick={handleRoute}
                                                />
                                                <MenuListItem
                                                    id="menu_item_system_modules"
                                                    text="Módulos del cliente"
                                                    href="/system/modules"
                                                    onClick={handleRoute}
                                                />
                                            </>
                                        )}
                                    </List>
                                </Collapse>
                            </>
                        )}

                        {/*CONFIGURACION*/}
                        {ModulesGrantsSet.settings() && (
                            <>
                                <ListItem button onClick={() => this.handleClickModule(ModuleKey.settings)}>
                                    <ListItemIcon>
                                        <Settings />
                                    </ListItemIcon>
                                    <ListItemText primary="Configuración" />
                                    {this.state[ModuleKey.settings] ? <ExpandLess /> : <ExpandMore />}
                                </ListItem>
                                <Collapse in={this.state[ModuleKey.settings]} timeout="auto">
                                    <List disablePadding>
                                        {IsInRuleset() && (
                                            <>
                                                <MenuListItem id="menu_item_system_params" text="Parámetros del sistema" href="/administration/parameters" onClick={handleRoute} />
                                                <MenuListItem id="menu_item_system_users" text="Usuarios del sistema" href="/grants/users" onClick={handleRoute} />
                                                <MenuListItem id="menu_item_access_groups" text="Grupos de acceso" href="/grants/rulegroups" onClick={handleRoute} />
                                                <MenuListItem
                                                    id="menu_item_access_groups_grants"
                                                    text="Alcance de los grupos de acceso"
                                                    href="/grants/selection"
                                                    onClick={handleRoute}
                                                />
                                                <MenuListItem id="menu_item_backups" text="Copias de seguridad" href="/administration/backups" onClick={handleRoute} />
                                            </>
                                        )}
                                        {IsInRuleset('INCIDENCE_LOG_VIEW') && (
                                            <MenuListItem id="menu_item_incidences" text="Incidencias" href="/logging/incidences" onClick={handleRoute} />
                                        )}
                                        {RULESET &&
                                            Array.isArray(RULESET.grants) &&
                                            (RULESET.grantsets.indexOf('ERROR_LOG_VIEW') !== -1 || RULESET.grantsets.indexOf('SYSTEM') !== -1) && (
                                                <MenuListItem id="menu_item_errors" text="Errores" href="/logging/errors" onClick={handleRoute} />
                                            )}
                                    </List>
                                </Collapse>
                            </>
                        )}
                        {/*Fábrica conectada*/}
                        {ModulesGrantsSet.connectedFactory() && (
                            <>
                                <ListItem button onClick={() => this.handleClickModule(ModuleKey.connectedFactory)}>
                                    <ListItemIcon>
                                        <Router />
                                    </ListItemIcon>
                                    <ListItemText primary="Fábrica conectada" />
                                    {this.state[ModuleKey.connectedFactory] ? <ExpandLess /> : <ExpandMore />}
                                </ListItem>

                                <Collapse in={this.state[ModuleKey.connectedFactory]} timeout="auto" unmountOnExit>
                                    <List disablePadding>
                                        {IsInRuleset(['AREAS_TOTAL', 'AREAS_VIEW']) && (
                                            <MenuListItem id="menu_item_areas_management" text="Gestión de areas" href="/administration/areas" onClick={handleRoute} />
                                        )}
                                        {IsInRuleset(['CENTERS_TOTAL', 'CENTERS_VIEW']) && (
                                            <MenuListItem id="menu_item_centers_management" text="Gestión de centros" href="/administration/centers" onClick={handleRoute} />
                                        )}
                                        {IsInRuleset(['DOWNTIMECAUSES_TOTAL', 'DOWNTIMECATEGORIES_VIEW']) && (
                                            <MenuListItem
                                                id="menu_item_stop_causes_management"
                                                text="Gestión de causas de las paradas"
                                                href="/administration/causes"
                                                onClick={handleRoute}
                                            />
                                        )}
                                        {IsInRuleset(['DOWNTIMECATEGORIES_TOTAL', 'DOWNTIMECATEGORIES_VIEW']) && (
                                            <MenuListItem
                                                id="menu_item_stop_causes_categories_management"
                                                text="Gestión de categorías de las paradas"
                                                href="/administration/categories"
                                                onClick={handleRoute}
                                            />
                                        )}
                                        {IsInRuleset(['DEVICES_TOTAL', 'DEVICES_VIEW']) && (
                                            <MenuListItem id="menu_item_devices_management" text="Gestión de dispositivos" href="/administration/devices" onClick={handleRoute} />
                                        )}
                                        {IsInRuleset(['BARGUNBASES_TOTAL', 'BARGUNBASES_VIEW']) && (
                                            <MenuListItem
                                                id="menu_item_guns_platforms_management"
                                                text="Gestión de bases de pistolas"
                                                href="/administration/barguns"
                                                onClick={handleRoute}
                                            />
                                        )}
                                        {IsInRuleset(['BARREADERS_TOTAL', 'BARREADERS_VIEW']) && (
                                            <MenuListItem
                                                id="menu_item_fixed_readers_management"
                                                text="Gestión de lectores fijos"
                                                href="/administration/bar-readers"
                                                onClick={handleRoute}
                                            />
                                        )}
                                        {IsInRuleset(['PTABLES_TOTAL', 'PTABLES_VIEW']) && (
                                            <MenuListItem
                                                id="menu_item_palletized_tables_management"
                                                text="Gestión de mesas de paletizado"
                                                href="/administration/ptables"
                                                onClick={handleRoute}
                                            />
                                        )}
                                    </List>
                                </Collapse>
                            </>
                        )}
                        {/*MIS EMPLEADOS*/}
                        {ModulesGrantsSet.employees() && (
                            <>
                                <ListItem button onClick={() => this.handleClickModule(ModuleKey.employees)}>
                                    <ListItemIcon>
                                        <SupervisorAccount />
                                    </ListItemIcon>
                                    <ListItemText primary="Empleados" />
                                    {this.state[ModuleKey.employees] ? <ExpandLess /> : <ExpandMore />}
                                </ListItem>
                                <Collapse in={this.state[ModuleKey.employees]} timeout="auto" unmountOnExit>
                                    <List disablePadding>
                                        {IsInRuleset(['EMPLOYEES_TOTAL', 'EMPLOYEES_VIEW', 'EMPLOYEES_VIEW_EXCEL']) && (
                                            <MenuListItem id="menu_item_employees_management" text="Gestión de empleados" href="/administration/employees" onClick={handleRoute} />
                                        )}
                                        {IsInRuleset('CARDS_TOTAL') && (
                                            <MenuListItem id="menu_item_cards_management" text="Gestión de tarjetas" href="/administration/cards" onClick={handleRoute} />
                                        )}
                                        {IsInRuleset(['TASKS_TOTAL', 'TASKS_VIEW']) && (
                                            <MenuListItem id="menu_item_tasks_management" text="Gestión de tareas" href="/administration/tasks" onClick={handleRoute} />
                                        )}

                                        {IsInRuleset(['AREAS_TOTAL', 'AREAS_VIEW']) && (
                                            <MenuListItem
                                                id="menu_item_tasks_groups_management"
                                                text="Gestión de los grupos de tareas"
                                                href="/administration/groups"
                                                onClick={handleRoute}
                                            />
                                        )}
                                        {IsInRuleset(['JOBTYPES_TOTAL', 'JOBTYPES_VIEW']) && (
                                            <MenuListItem
                                                id="menu_item_employees_roles_management"
                                                text="Gestión de los roles de trabajadores"
                                                href="/administration/jobtypes"
                                                onClick={handleRoute}
                                            />
                                        )}
                                        {IsInRuleset() && this.props.nonProductiveTimeManagement && (
                                            <MenuListItem
                                                id="menu_item_non_productive_times_management"
                                                text="Gestión de tiempos no productivos"
                                                href="/administration/non-productive-times"
                                                onClick={handleRoute}
                                            />
                                        )}
                                        {IsInRuleset('REPORTS_EMPLOYEES_WORKING_VIEW') && (
                                            <MenuListItem
                                                id="menu_item_center_employees_list"
                                                text="Listado de trabajadores en planta"
                                                href="/information/working-now"
                                                onClick={handleRoute}
                                            />
                                        )}
                                        {IsInRuleset(['REPORTS_REGISTERED_TASKS_VIEW', 'REPORTS_REGISTERED_TASKS_EXCEL']) && (
                                            <MenuListItem id="menu_item_actual_tasks_list" text="Listado de tareas actuales" href="/information/personnel" onClick={handleRoute} />
                                        )}
                                        {IsInRuleset(['REPORTS_TASKS_BY_EMPLOYEE_VIEW', 'REPORTS_TASKS_BY_EMPLOYEE_EXCEL']) && (
                                            <MenuListItem id="menu_item_tasks_by_employee" text="Tareas por empleado" href="/information/tasks-employee" onClick={handleRoute} />
                                        )}
                                        {IsInRuleset(['REPORTS_WORKSHIFT_TIMES_VIEW', 'REPORTS_WORKSHIFT_TIMES_EXCEL']) && (
                                            <MenuListItem id="menu_item_times_by_shift" text="Tiempos por turno" href="/information/workshift-times" onClick={handleRoute} />
                                        )}
                                        {IsInRuleset() && (
                                            <MenuListItem id="menu_item_times_by_area" text="Tiempos por area" href="/information/area-times" onClick={handleRoute} />
                                        )}
                                        {IsInRuleset(['REPORTS_TASKS_TIME_PER_AREA_VIEW', 'REPORTS_TASKS_TIME_PER_AREA_EXCEL']) && (
                                            <MenuListItem
                                                id="menu_item_times_by_area_and_task"
                                                text="Tiempos por area y tarea"
                                                href="/information/workshift-task-area"
                                                onClick={handleRoute}
                                            />
                                        )}
                                        {IsInRuleset(['REPORTS_AREASHIFTS_VIEW', 'REPORTS_AREASHIFTS_EXCEL']) && (
                                            <MenuListItem
                                                id="menu_item_shift_employees_query"
                                                text="Consulta de empleados por turnos"
                                                href="/information/areashifts"
                                                onClick={handleRoute}
                                            />
                                        )}
                                        {/*{IsInRuleset() && <MenuListItem id="menu_item_line_sign" text="Fichaje en línea" href="/lanemanager/workshifts" onClick={handleRoute} />}*/}
                                        {/*{IsInRuleset() && (*/}
                                        {/*    <MenuListItem*/}
                                        {/*        text="Fichaje en centro"*/}
                                        {/*    />*/}
                                        {/*)}*/}
                                        {/*{IsInRuleset() && (*/}
                                        {/*    <MenuListItem*/}
                                        {/*        text="Cierre automático de tareas nocturnas"*/}git
                                        {/*    />*/}
                                        {/*)}*/}
                                    </List>
                                </Collapse>
                            </>
                        )}
                        {/*CONTROL DE COSTES*/}
                        {ModulesGrantsSet.costManagement() && (
                            <>
                                <ListItem button onClick={() => this.handleClickModule(ModuleKey.costsManagement)}>
                                    <ListItemIcon>
                                        <MonetizationOn />
                                    </ListItemIcon>
                                    <ListItemText primary="Control de costes" />
                                    {this.state[ModuleKey.costsManagement] ? <ExpandLess /> : <ExpandMore />}
                                </ListItem>
                                <Collapse in={this.state[ModuleKey.costsManagement]} timeout="auto" unmountOnExit>
                                    <List disablePadding>
                                        {/*{IsInRuleset() && (*/}
                                        {/*    <MenuListItem*/}
                                        {/*        text="Cálculo de costes de producción"*/}
                                        {/*    />*/}
                                        {/*)}*/}
                                        {/*{IsInRuleset() && (*/}
                                        {/*    <MenuListItem*/}
                                        {/*        text="Cálculo de costes de volcado directos"*/}
                                        {/*    />*/}
                                        {/*)}*/}
                                        {/*{IsInRuleset() && (*/}
                                        {/*    <MenuListItem*/}
                                        {/*        text="Cálculo de costes de empleados"*/}
                                        {/*    />*/}
                                        {/*)}*/}
                                    </List>
                                </Collapse>
                            </>
                        )}
                        {/*TRAZABILIDAD*/}
                        {ModulesGrantsSet.traceability && (
                            <>
                                <ListItem button onClick={() => this.handleClickModule(ModuleKey.traceability)}>
                                    <ListItemIcon>
                                        <Timeline />
                                    </ListItemIcon>
                                    <ListItemText primary="Trazabilidad" />
                                    {this.state[ModuleKey.traceability] ? <ExpandLess /> : <ExpandMore />}
                                </ListItem>

                                <Collapse in={this.state[ModuleKey.traceability]} timeout="auto" unmountOnExit>
                                    <List disablePadding>
                                        {IsInRuleset('PALLETS_INFORMATION_AND_REGISTRY') && (
                                            <MenuListItem id="menu_item_pale_data" text={'Datos de palé'} href="/pallet-info" onClick={handleRoute} />
                                        )}
                                        {/*{IsInRuleset() && (*/}
                                        {/*    <MenuListItem*/}
                                        {/*        text="Trazabilidad por pedido"*/}
                                        {/*    />*/}
                                        {/*)}*/}
                                        {/*{IsInRuleset() && (*/}
                                        {/*    <MenuListItem*/}
                                        {/*        text="Trazabilidad desde por origen"*/}
                                        {/*    />*/}
                                        {/*)}*/}
                                        {/*{IsInRuleset() && (*/}
                                        {/*    <MenuListItem*/}
                                        {/*        text="Información de orígenes"*/}
                                        {/*    />*/}
                                        {/*)}*/}
                                    </List>
                                </Collapse>
                            </>
                        )}
                        {/*COMUNICACIONES Y AVISOS*/}
                        {ModulesGrantsSet.messages() && (
                            <>
                                <ListItem button onClick={() => this.handleClickModule(ModuleKey.messages)}>
                                    <ListItemIcon>
                                        <ChatBubble />
                                    </ListItemIcon>
                                    <ListItemText primary="Comunicaciones y avisos" />
                                    {this.state[ModuleKey.messages] ? <ExpandLess /> : <ExpandMore />}
                                </ListItem>
                                <Collapse in={this.state[ModuleKey.messages]} timeout="auto" unmountOnExit>
                                    <List disablePadding>
                                        {IsInRuleset('MESSAGES_PERSONAL') && (
                                            <MenuListItem id="menu_item_inbox" text="Bandeja de entrada" href="/messages/box" onClick={handleRoute} />
                                        )}
                                        {IsInRuleset('MESSAGES_CREATE') && (
                                            <MenuListItem id="menu_item_new_communication" text="Crear nueva comunicación" href="/messages/create" onClick={handleRoute} />
                                        )}
                                        {IsInRuleset('MESSAGES_VIEW') && (
                                            <MenuListItem id="menu_item_comunication_history" text="Historial de comunicaciones" href="/messages/all" onClick={handleRoute} />
                                        )}
                                        {/*{IsInRuleset('MESSAGES_VIEW') && (*/}
                                        {/*    <MenuListItem*/}
                                        {/*        text="Alertas"*/}
                                        {/*    />*/}
                                        {/*)}*/}
                                    </List>
                                </Collapse>
                            </>
                        )}
                        {/*PRODUCCIÓN Y PLANIFICACION*/}
                        {ModulesGrantsSet.planning() && (
                            <>
                                <ListItem button onClick={() => this.handleClickModule(ModuleKey.plannig)}>
                                    <ListItemIcon>
                                        <TableChart />
                                    </ListItemIcon>
                                    <ListItemText primary="Producción y planificación" />
                                    {this.state[ModuleKey.plannig] ? <ExpandLess /> : <ExpandMore />}
                                </ListItem>
                                <Collapse in={this.state[ModuleKey.plannig]} timeout="auto" unmountOnExit>
                                    <List disablePadding>
                                        {GroupedGrantsets.laneControl() && (
                                            <>
                                                <MenuListItem
                                                    id="menu_item_select_lane"
                                                    text={this.props.lane.laneName ? 'Control de la línea' : 'Seleccionar línea'}
                                                    href="/selectlane"
                                                    onClick={handleRoute}
                                                />
                                                {this.props.lane && this.props.lane.laneId && (
                                                    <div style={{marginLeft: 16}}>
                                                        <MenuListItem
                                                            id="menu_item_actual_lane"
                                                            text={`${this.props.lane.laneName}`}
                                                            href="/lanemanager/lane"
                                                            onClick={handleRoute}
                                                        />
                                                    </div>
                                                )}
                                            </>
                                        )}
                                        {/*{IsInRuleset('MESSAGES_VIEW') && (*/}
                                        {/*    <MenuListItem*/}
                                        {/*        text="Gestión de mesas de paletizado"*/}
                                        {/*    />*/}
                                        {/*)}*/}
                                        {/*{IsInRuleset('MESSAGES_VIEW') && (*/}
                                        {/*    <MenuListItem*/}
                                        {/*        text="..."*/}
                                        {/*    />*/}
                                        {/*)}*/}

                                        {IsInRuleset(['REPORTS_BATCHES_INFO_VIEW', 'REPORTS_BATCHES_INFO_EXCEL']) && (
                                            <MenuListItem
                                                id="menu_item_departure_query"
                                                text="Consulta ampliada de orígenes"
                                                href="/information/batches-info"
                                                onClick={handleRoute}
                                            />
                                        )}
                                        {IsInRuleset(['REPORTS_PALLETS_VIEW', 'REPORTS_PALLETS_VIEW_EXCEL']) && (
                                            <MenuListItem
                                                id="menu_item_registered_pallets_query"
                                                text="Consulta de palés registrados"
                                                href="/information/pallets"
                                                onClick={handleRoute}
                                            />
                                        )}
                                        {IsInRuleset(['REPORTS_PALLETS_INFO_VIEW', 'REPORTS_PALLETS_INFO_EXCEL']) && (
                                            <MenuListItem id="menu_item_pallets_query" text="Consulta ampliada de palés" href="/information/pallets-info" onClick={handleRoute} />
                                        )}
                                        {IsInRuleset('REPORTS_PRODPARTS_VIEW') && (
                                            <MenuListItem
                                                id="menu_item_production_parts_query"
                                                text="Consulta de partes de producción"
                                                href="/information/prodparts"
                                                onClick={handleRoute}
                                            />
                                        )}
                                        {IsInRuleset('REPORTS_BOXES_VIEW') && (
                                            <MenuListItem
                                                id="menu_item_registered_boxes_query"
                                                text="Consulata de cajas registradas"
                                                href="/information/boxes"
                                                onClick={handleRoute}
                                            />
                                        )}
                                    </List>
                                </Collapse>
                            </>
                        )}
                        {/*KPIS*/}
                        {ModulesGrantsSet.kpis() && (
                            <>
                                <ListItem button onClick={() => this.handleClickModule(ModuleKey.kpis)}>
                                    <ListItemIcon>
                                        <BarChartIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="KPIs" />
                                    {this.state[ModuleKey.kpis] ? <ExpandLess /> : <ExpandMore />}
                                </ListItem>
                                <Collapse in={this.state[ModuleKey.kpis]} timeout="auto" unmountOnExit>
                                    <List disablePadding>
                                        <MenuListItem id="menu_item_kpis" text="KPIs" href="/information/kpis" onClick={handleRoute} />
                                        {/*<MenuListItem*/}
                                        {/*    text="Resumen KPIs clave"*/}
                                        {/*/>*/}
                                        {/*<MenuListItem*/}
                                        {/*    text="Exportar KPIs clave"*/}
                                        {/*/>*/}
                                    </List>
                                </Collapse>
                            </>
                        )}
                        {/*FÁBRICA VISUAL*/}
                        {ModulesGrantsSet.visualFactory() && (
                            <>
                                <ListItem button onClick={() => this.handleClickModule(ModuleKey.visualFactory)}>
                                    <ListItemIcon>
                                        <Map />
                                    </ListItemIcon>
                                    <ListItemText primary="Fábrica visual" />
                                    {this.state[ModuleKey.visualFactory] ? <ExpandLess /> : <ExpandMore />}
                                </ListItem>

                                <Collapse in={this.state[ModuleKey.visualFactory]} timeout="auto" unmountOnExit>
                                    <List disablePadding>
                                        <MenuListItem id="menu_item_blueprints" text="Planos" href="/blueprints" onClick={handleRoute} />
                                    </List>
                                </Collapse>
                            </>
                        )}
                    </List>
                )}
            </WrapMenuList>
        );
    };

    private dynamicMenuList = () => {
        const handleRoute = (id: string) => {
            this.props.storeDynamicMenuListState({
                openModule: this.state.selectedDynamicModule,
                selectedItem: id,
            });
            this.props.handleRoute();
        };

        return (
            <WrapMenuList className="main_menu">
                <List>
                    {/*LÍNEA ACTIVA*/}
                    {this.props.lane && this.props.lane.laneId && (
                        <>
                            <ListItem
                                
                            >
                                <ListItemText
                                    key="active_line"
                                    style={{cursor: 'pointer'}}
                                    onClick={() => {
                                        this.props.push('/lanemanager/lane');
                                        this.props.storeDynamicMenuListState({
                                            openModule:'',
                                            selectedItem: undefined,
                                        });
                                    }}
                                    primary={
                                        <Paper
                                            elevation={3}
                                            style={{
                                                backgroundColor: '#60ff8c',
                                            }}
                                        >
                                            <Typography align="center" variant="h6">
                                                {this.props.lane.laneName}
                                            </Typography>
                                        </Paper>
                                    }
                                />
                                {this.state.openDirectAccessAreas ? <ExpandLess style={{cursor: 'pointer'}} onClick={() => this.handleClickDirectAccessAreas(false)}/> : 
                                    <ExpandMore style={{cursor: 'pointer'}} onClick={() => this.handleClickDirectAccessAreas(true)} />}
                            </ListItem>
                            <Collapse in={this.state.openDirectAccessAreas} timeout="auto">
                                <List>
                                    {this.state.directAccessareasList && Object.values(this.state.directAccessareasList).map(directAccessArea => (
                                        <ListItem button onClick={() => {
                                                    this.props.selectLane(
                                                        directAccessArea.id,
                                                        directAccessArea.name,
                                                        directAccessArea.dump_barcode,
                                                        directAccessArea.auto_palletise,
                                                    );
                                                    this.props.push('/lanemanager');
                                                    this.props.handleRoute();
                                            }}>
                                            <ListItemText primary={'> ' + directAccessArea.name} />
                                        </ListItem>
                                    ))}
                                </List>
                            </Collapse>
                        </>
                    )}
                    {/*INICIO*/}
                    <ListItem button onClick={this.handleGoHome}
                              key="module_home"
                    >
                        <ListItemIcon>
                            <Home />
                        </ListItemIcon>
                        <ListItemText primary="Inicio" />
                    </ListItem>
                    {/*SISTEMA*/}
                    {IsInRuleset('SYSTEM') && (
                        <>
                            <ListItem button
                                      key="module_system"
                                      onClick={() => this.handleClickDynamicModule('module_system')}
                            >
                                <ListItemIcon>
                                    <SettingsApp />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Sistema"
                                />
                                {this.state.selectedDynamicModule === 'module_system' ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse in={this.state.selectedDynamicModule === 'module_system'} timeout="auto">
                                <List disablePadding>
                                    {IsInRuleset() && (
                                        <>
                                            <DynamicMenuListItem
                                                key='menu_item_system_functionalities'
                                                id='menu_item_system_functionalities'
                                                text="Funcionalidades del cliente"
                                                href="/system/functionalities"
                                                onClick={handleRoute}
                                            />
                                            <DynamicMenuListItem
                                                key='menu_item_system_menu_items'
                                                id='menu_item_system_menu_items'
                                                text="Opciones de menú del cliente"
                                                href="/system/menuItems"
                                                onClick={handleRoute}
                                            />
                                            <DynamicMenuListItem
                                                id="menu_item_system_modules"
                                                text="Módulos del cliente"
                                                href="/system/modules"
                                                onClick={handleRoute}
                                            />
                                            <DynamicMenuListItem
                                                id="menu_item_system_errors"
                                                text="Errores"
                                                href="/logging/errors"
                                                onClick={handleRoute}
                                            />
                                        </>
                                    )}
                                </List>
                            </Collapse>
                        </>
                    )}

                    {this.props.modules && Object.values(this.props.modules).map(module => (
                        <>
                            <ListItem button
                                      key={module.id}
                                      onClick={() => this.handleClickDynamicModule(module.id)}
                            >
                                <ListItemIcon>
                                    {this.getModuleIcon(module.id)}
                                </ListItemIcon>
                                <ListItemText
                                    primary={module.name}
                                />
                                {this.state.selectedDynamicModule === module.id ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse in={this.state.selectedDynamicModule === module.id} timeout="auto">
                                <List disablePadding>
                                    <>
                                    {
                                        module.items && Object.values(module.items).map(item => (
                                            <DynamicMenuListItem
                                                key={item.id}
                                                id={item.id}
                                                text={item.name}
                                                href={item.link}
                                                onClick={handleRoute}
                                            />
                                        ))
                                    }
                                    </>
                                </List>
                            </Collapse>
                        </>
                    ))}
                </List>
            </WrapMenuList>
        );
    };



    public render() {
        switch(this.props.menuType) {
            case '0':
                return this.menuList();
            case '1':
                return this.classicMenuList();
            case '2':
                return this.dynamicMenuList();
            default:
                return this.menuList();
        }
    }
}


const mapStateToProps = (state : AppStoreState) => {

    console.log('it', DynamicMenuListSelectors.getState(state));
    return {
        lane: LaneSelectSelectors.getLane(state),
        nonProductiveTimeManagement: ParametersSelectors.getNonProductiveTimeManagement(state) === '1',
        menuType: ParametersSelectors.getIsClassicMenu(state),
        menuListState: MenuListSelectors.getState(state),
        dynamicMenuListState: DynamicMenuListSelectors.getState(state),
        modules: ModulesListSelectors.getState(state)
    };
};

/*const selectLane = (
  lane: string,
  name: string,
  dumpBarcode: string,
  autoPalletise: boolean,
) => {
    LaneSelectActions.select(lane, name, dumpBarcode, autoPalletise);
}*/

const mapDispatchToProps = (dispatch: any) => {
    return {
        push: (e: any) => dispatch(push(e)),
        storeMenuListState: (state: MenuListState) => dispatch(MenuListAction(state)),
        storeDynamicMenuListState: (state: DynamicMenuListState) => dispatch(DynamicMenuListAction(state)),
        selectLane: (lane: string,
                name: string,
                dumpBarcode: string,
                autoPalletise: boolean,) => dispatch(LaneSelectActions.select(lane, name, dumpBarcode, autoPalletise))
    };
};

export const MenuList = connect<SProps, DProps, Props, AppStoreState>(mapStateToProps, mapDispatchToProps)(MenuListComponent);
