import {
  AreaShiftResponse,
  AreaShiftSingleResponse,
} from 'src/app/lanemanager/areashift/store/areashift.types';
import {PaginatedServiceArgs} from 'src/types';
import {guardedFetcher} from 'src/utils/guardedFetcher';

const prefix = 'crud/areashifts';

export const AreaShiftServices = {
  get: ({params}: PaginatedServiceArgs) =>
    guardedFetcher.get<PaginateResponse<AreaShiftResponse>>(prefix, {params}),
  getOfLane: ({params, args}: PaginatedServiceArgs) =>
    guardedFetcher.get<PaginateResponse<AreaShiftResponse>>(
      `${prefix}/area/${args && args.length ? args[0] : 'NO_HAY_LINEA'}`,
      {params},
    ),
  current: (areaId: string) =>
    guardedFetcher.get<AreaShiftSingleResponse>(`${prefix}/area/${areaId}/current`),
  create: (item: {area_id: string; employee_id: string}) =>
    guardedFetcher.post(prefix, item),
  finish: (areaShifId: string) => guardedFetcher.post(`${prefix}/${areaShifId}/finish`),
};
