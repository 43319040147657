import React, {FunctionComponent} from 'react';
import ReactTable, {RowInfo} from 'react-table';
import {BatchesServices} from 'src/app/lanemanager/batches/store/services';
import {Batch} from 'src/app/lanemanager/batches/store/types';
import {PaginatedTableComponent} from 'src/shared/components/PaginatedTableComponent';
import {ColumnType} from 'src/types';
import {connect} from 'react-redux';
import {LaneSelectSelectors} from 'src/app/laneSelect/store';
import {AppStoreState} from 'src/store';
import {customFormatNumber} from 'src/shared/functions/FormatHelpers';

type SProps = {
    lane: ReturnType<typeof LaneSelectSelectors.getLane>;
};

export const BatchesBase: FunctionComponent<SProps> = props => {
    const columns: ColumnType<Batch> = [
        {
            Header: 'Código origen',
            headerClassName: 'header_cell_string',
            filterable: true,
            sortable: true,
            accessor: 'erp_batch_code',
            className: 'cell_string',
        },
        {
            Header: 'Fecha',
            headerClassName: 'header_cell_string',
            filterable: true,
            daterange: true,
            width: 230,
            sortable: true,
            dateTimeFormat: true,
            accessor: 'arrival_at',
            className: 'cell_string',
        },
        {
            Header: 'Fecha Volcado',
            headerClassName: 'header_cell_string',
            filterable: true,
            daterange: true,
            width: 230,
            sortable: true,
            dateTimeFormat: true,
            accessor: 'dumped_at',
            className: 'cell_string',
        },
        {
            Header: 'Producto',
            headerClassName: 'header_cell_string',
            accessor: 'merchandise_name',
            className: 'cell_string',
        },
        {
            Header: 'Agricultor',
            headerClassName: 'header_cell_string',
            accessor: 'provider_name',
            className: 'cell_string',
        },
        {
            Header: 'Kg Entrada',
            sortable: false,
            maxWidth: 100,
            width: 100,
            headerClassName: 'header_cell_number',
            Cell: (item: any) => <>{customFormatNumber(item.original.kg_total_logisticunit)}</>,
            className: 'cell_number',
        },
        {
            Header: 'Kg Volcados',
            sortable: false,
            maxWidth: 100,
            width: 100,
            headerClassName: 'header_cell_number',
            Cell: (item: any) => <>{customFormatNumber(item.original.kg_total_dump)}</>,
            className: 'cell_number',
        },
        {
            Header: 'Kg Totales',
            sortable: false,
            maxWidth: 100,
            width: 100,
            headerClassName: 'header_cell_number',
            //Cell: (item: any) => <>{customFormatNumber(item.original.kg_total_boxes)}</>,
            Cell: (item: any) => <>{customFormatNumber(item.original.kg_total_boxes, true)}</>,
            className: 'cell_number',
        },
        {
            Header: 'Pal',
            headerClassName: 'header_cell_string',
            accessor: 'pallets',
            minWidth: 70,
            maxWidth: 70,
            width: 70,
            expander: true,
            style: {
                cursor: 'pointer',
                fontSize: 25,
                padding: '0',
                textAlign: 'center',
                userSelect: 'none',
            },
        },
    ];

    return (
        <PaginatedTableComponent
            service={BatchesServices.getAllPaginated}
            serviceArgs={props.lane.laneId}
            columnFormat={columns}
            defaultPageSize={20}
            loading={true}
            propsToOwnTable={{
                SubComponent: (info: RowInfo) => {
                    const data = info.original;
                    return (
                        <>
                            <ReactTable
                                data={data.pallets}
                                showPagination={false}
                                minRows={0}
                                pageSize={data.pallets.length}
                                resizable={false}
                                getNoDataProps={() => {
                                    if (!data.pallets.length) return {style: {display: 'none'}};
                                    return {};
                                }}
                                sortable={false}
                                columns={[
                                    {
                                        Header: 'Palets',
                                        headerClassName: 'subheader_title',
                                        columns: [
                                            {
                                                Header: 'CÓDIGO PALÉ',
                                                headerClassName: 'subheader_cell_string',
                                                accessor: 'barcode',
                                                className: 'subcell_string',
                                            },
                                            {
                                                Header: 'MESA',
                                                headerClassName: 'subheader_cell_string',
                                                accessor: 'ptable_name',
                                                className: 'subcell_string',
                                            },
                                            {
                                                Header: 'PARTE PRODUCCION',
                                                headerClassName: 'subheader_cell_string',
                                                accessor: 'erp_prodpart_code',
                                                className: 'subcell_string',
                                            },
                                            {
                                                Header: 'PEDIDO',
                                                headerClassName: 'subheader_cell_string',
                                                accessor: 'erp_order_code',
                                                className: 'subcell_string',
                                            },
                                            {
                                                Header: 'PRODUCTO',
                                                headerClassName: 'subheader_cell_string',
                                                accessor: 'merchandise_name',
                                                className: 'subcell_string',
                                            },
                                            {
                                                Header: 'CLIENTE',
                                                headerClassName: 'subheader_cell_string',
                                                accessor: 'customer_name',
                                                className: 'subcell_string',
                                            },
                                            {
                                                Header: 'CAJAS',
                                                headerClassName: 'subheader_cell_number',
                                                accessor: 'boxes_count',
                                                Cell: item => (
                                                    <>
                                                        {customFormatNumber(item.original.boxes_count)}/{customFormatNumber(item.original.boxes_total)}
                                                    </>
                                                ),
                                                className: 'subcell_number',
                                            },
                                            {
                                                Header: 'KG TOTALES',
                                                headerClassName: 'subheader_cell_number',
                                                accessor: 'kg_total',
                                                Cell: item => <>{customFormatNumber(item.original.kg_total, true)}</>,
                                                className: 'subcell_number',
                                            },
                                            {
                                                Header: '', //hueco para cuadrar la columna de kg de los palés con los kg del origen
                                                headerClassName: 'subheader_cell_string',
                                                accessor: 'white_space',
                                                minWidth: 70,
                                                maxWidth: 70,
                                                width: 70,
                                                className: 'subcell_string',
                                            },
                                        ],
                                    },
                                ]}
                            />
                        </>
                    );
                },
            }}
        />
    );
};

export const Batches = connect<SProps, {}, {}, AppStoreState>(state => ({
    lane: LaneSelectSelectors.getLane(state),
}))(BatchesBase);
