import * as React from 'react';
import {push} from 'connected-react-router';
import styled from 'styled-components';
import {StatusBar} from 'src/app/statusbar/StatusBar';
import {connect} from 'react-redux';
import {history, AppStoreState} from 'src/store';
import {Card, CardActionArea, CardContent, CardMedia, Grid, Typography, Button, Tooltip} from '@material-ui/core';
import IconMap from '@material-ui/icons/Map';
import {Blueprint} from 'src/app/map/Blueprint';
import {createStyles, withStyles} from '@material-ui/core/styles';
import {Link} from 'react-router-dom';
import {getIsLoading, getMaps} from './store/reduce';
import {Map} from './store/types';
import {fetchMaps, fetchMap} from './store/action';
import {fetchAlertInactive, fetchKPIs} from './store/services';
import {Spinner} from '../global/spinner';
import {TableModal} from 'src/shared/components/Modal';
import {customFormatNumber} from 'src/shared/functions/FormatHelpers';
import {IsInRuleset} from 'src/global/authorization/grantSet';
import {findInitialParameter} from 'src/global/initalParameters/initialParameters';
import {ParameterCPN} from 'src/types/app_types';

type State = {
    availableMaps: Map[];
    alertsInactiveTime: any[];
    bluePrintLoaded: string[];
    blueprint?: Map;
    openIndirectEmployeesModal: boolean;
    centeredBlueprint: boolean;
    KPIs?: any;
};
const initialState: Readonly<State> = {
    availableMaps: [],
    alertsInactiveTime: [],
    bluePrintLoaded: [],
    blueprint: undefined,
    openIndirectEmployeesModal: false,
    centeredBlueprint: false,
    KPIs: undefined,
};

const styles = () =>
    createStyles({
        itemValue: {
            float: 'right',
        },
        itemHead: {
            color: 'grey',
        },
        kpiTittle: {
            marginTop: '24px',
            color: 'black',
            fontWeight: 'lighter',
        },
        kpiList: {
            margin: '6px 0 0 0',
            paddingLeft: '20px',
        },
        loadingKPIs: {
            textAlign: 'center',
            color: 'red',
        },
        itemGrid: {
            margin: '15px auto',
            maxWidth: 340,
            borderStyle: 'solid',
            borderColor: 'lightgray',
            borderWidth: 'thin',
            padding: '16px !important',
        },
        card: {},
        media: {
            height: 180,
        },
        a: {
            textDecoration: 'none',
        },
    });

type ComponentProps = StateProps & DispatchProps & Props;

export class MapsBase extends React.Component<ComponentProps, State> {
    fetchMapsinterval: any;
    fetchAlertsinterval: any;
    readonly state = initialState;

    componentDidMount() {
        this.fetchKPIs();

        this.props.fetchMaps().then(() => {
            const maps = Object.values(this.props.maps);
            this.setState({availableMaps: maps});
        });

        this.fetchMapsinterval = setInterval(() => {
            this.fetchTheMap();
        }, 10000);

        this.fetchAlertsinterval = setInterval(() => {
            this.fetchAlertsInactiveTime();
        }, 30000);
    }

    componentWillUnmount() {
        clearInterval(this.fetchMapsinterval);
        clearInterval(this.fetchAlertsinterval);
    }

    fetchKPIs() {
        if (this.haveKPIsGrants()) {
            fetchKPIs().then((res: any) => {
                const KPIs = Object.values(res.data);
                this.setState({KPIs: KPIs});
            });
        }
    }

    fetchTheMap() {
        console.log('FETCH_THE_MAP');
        if (this.props.match.params.blueprint && !this.props.mapsIsLoading) {
            this.props.fetchMap(this.props.match.params.blueprint).then(() => {
                this.setState({availableMaps: Object.values(this.props.maps)});
                if (!this.state.bluePrintLoaded.includes(this.props.match.params.blueprint)) {
                    this.setState({centeredBlueprint: false});
                    this.setState({bluePrintLoaded: this.state.bluePrintLoaded.concat(this.props.match.params.blueprint)});
                }
            });
        }
    }

    private haveKPIsGrants = () => {
        return IsInRuleset(['BLUEPRINT_KPIS', 'ADMIN']);
    };

    private fetchAlertsInactiveTime = async () => {
        const blueprint = this.state.availableMaps.filter(map => map.id === this.props.match.params.blueprint)[0];

        if (IsInRuleset(['ALERTS_PERSONAL_ADMIN', 'ADMIN'])) {
            if (blueprint && blueprint.centerId) {
                fetchAlertInactive(blueprint.centerId).then((res: any) => {
                    this.setState({alertsInactiveTime: res.data.activitiesAlerts});
                });
            }
        }
    };

    private handleCloseIndirectEmployeesModal = () => this.setState({openIndirectEmployeesModal: false});

    private renderKPIs(indexAvailableMaps: any) {
        if (!this.haveKPIsGrants()) return null;

        const classes = this.props.classes;
        const KPIs = this.state.KPIs;
        const header = (
            <Typography className={classes.kpiTittle} variant="h6">
                <b>KPIs:</b>
            </Typography>
        );

        if (KPIs !== undefined && KPIs[indexAvailableMaps]) {
            return (
                <>
                    {header}
                    <Typography variant="body1">
                        <ul className={classes.kpiList}>
                            <li>
                                <b className={classes.itemHead}>Número de líneas:</b>
                                <span className={classes.itemValue}>{KPIs[indexAvailableMaps].linesCount}</span>
                            </li>
                            <li>
                                <b className={classes.itemHead}>Kilos trabajados:</b>
                                <span className={classes.itemValue}>{KPIs[indexAvailableMaps].workedKg}</span>
                            </li>
                            <li>
                                <b className={classes.itemHead}>Rendimiento (Kg/H):</b>
                                <span className={classes.itemValue}>{KPIs[indexAvailableMaps].perfomance}</span>
                            </li>
                            <li>
                                <b className={classes.itemHead}>Coste total (€/Kg):</b>
                                <span className={classes.itemValue}>{KPIs[indexAvailableMaps].totalCost}</span>
                            </li>
                            <li>
                                <b className={classes.itemHead}>Coste indirecto (€/Kg):</b>
                                <span className={classes.itemValue}>{KPIs[indexAvailableMaps].indirectCost}</span>
                            </li>
                        </ul>
                    </Typography>
                </>
            );
        } else {
            return (
                <>
                    {header}
                    <Typography variant="h6" className={classes.loadingKPIs}>
                        ...Obteniendo datos...
                    </Typography>
                </>
            );
        }
    }

    render() {
        const {availableMaps, alertsInactiveTime /*KPIs*/} = this.state;
        const {classes, match} = this.props;
        const blueprint = availableMaps.filter(map => map.id === match.params.blueprint)[0];
        const numIndirectEmployees = blueprint && blueprint.centerInfo && blueprint.centerInfo.indirectEmployees ? ' (' + blueprint.centerInfo.indirectEmployees.length + ')' : '';

        return (
            <div style={{height: '100%'}}>
                <TableModal title={'Empleados Indirectos' + numIndirectEmployees} open={this.state.openIndirectEmployeesModal} handleClose={this.handleCloseIndirectEmployeesModal}>
                    {blueprint &&
                        blueprint.centerInfo &&
                        blueprint.centerInfo.indirectEmployees &&
                        blueprint.centerInfo.indirectEmployees.map((indirectEmployee: {name: string; task: string; firstTask: boolean}, indexIndirectEmployees) => (
                            <div className="modal_list" key={'key_indirectEmployees' + indexIndirectEmployees} style={{margin: '10px 5px'}}>
                                {indirectEmployee.firstTask && <span className="title_task">{indirectEmployee.task}</span>}
                                <div className="row_list" key={'indirectEmployees_' + indexIndirectEmployees}>
                                    <span className="info_employees__label">{indirectEmployee.name} </span>
                                </div>
                            </div>
                        ))}
                </TableModal>
                <StatusBar title={'Planos'} />
                <WrappingDiv>
                    {!blueprint && (
                        <ContainerDiv style={{height: 'calc(100% - 52px)', padding: '5px', position: 'relative'}}>
                            <Spinner isLoading={this.props.mapsIsLoading} />
                            <Grid container={true} spacing={8} justify="space-evenly" alignItems="flex-start" style={{height: '100%', overflow: 'auto'}}>
                                {availableMaps.map((map, indexAvailableMaps) => (
                                    <Grid className={classes.itemGrid} key={'availableMaps_' + indexAvailableMaps} item={true} xs={12} sm={6} md={4}>
                                        <Link className={classes.a} to={'/blueprints/' + map.id} href={'/blueprints/' + map.id} onClick={() => this.fetchTheMap()}>
                                            <Card className={classes.card}>
                                                <CardActionArea>
                                                    <CardMedia className={classes.media} image={map.blueprintThumb} title={map.label} />
                                                    <CardContent>
                                                        <Typography variant="h5" component="h2">
                                                            {map.label}
                                                        </Typography>
                                                    </CardContent>
                                                </CardActionArea>
                                            </Card>
                                        </Link>
                                        {this.renderKPIs(indexAvailableMaps)}
                                    </Grid>
                                ))}
                            </Grid>
                        </ContainerDiv>
                    )}
                    {blueprint && (
                        <div style={{height: 'calc(100% - 52px)', padding: '5px', overflow: 'hidden', position: 'relative'}}>
                            <Blueprint
                                id={blueprint.id}
                                history={history}
                                blueprintImg={blueprint.blueprintImg}
                                points={blueprint.points}
                                zoomsOfDetailsLevels={blueprint.config.zoomsOfDetailsLevels}
                                titleLevel={blueprint.config.titleLevel}
                                config={blueprint.config}
                                centeredBlueprint={this.state.centeredBlueprint}
                                handleChangePosition={() => {
                                    this.setState({centeredBlueprint: true});
                                }}
                                alertsInactiveTime={alertsInactiveTime}
                            />
                            <Spinner isLoading={!this.state.bluePrintLoaded.includes(blueprint.id)} />
                        </div>
                    )}
                    <div
                        className="global_details"
                        style={{
                            backgroundColor: `#${findInitialParameter(ParameterCPN.FRONT_APP_COLOR)?.value || ''}`,
                            height: '42px',
                        }}
                    >
                        <div
                            className={'container'}
                            style={{
                                color: 'white',
                                //maxWidth: '1140px',
                                margin: '0 auto',
                                padding: '5px 10px',
                                lineHeight: '30px',
                            }}
                        >
                            {blueprint && (
                                <React.Fragment>
                                    <Button color="inherit" href="/blueprints" size={'small'} style={{marginRight: '54px'}}>
                                        <IconMap /> Planos
                                    </Button>
                                    <div style={{display: 'inline-block', margin: '0 5px'}}>
                                        <span style={{textTransform: 'uppercase', fontWeight: 500}}>{blueprint.label} | </span>
                                    </div>
                                    {blueprint.details.map((detail: {titleGroup: string; data: any[]}, indexDetails) => (
                                        <div key={'key_details' + indexDetails} style={{display: 'inline-block', margin: '0 5px'}}>
                                            <span key={'key_details_titleGroup' + indexDetails} style={{fontWeight: 500}}>
                                                {detail.titleGroup}:{' '}
                                            </span>
                                            {detail.data &&
                                                detail.data.map((dataValue: {prefix: string; value: number; suffix: string; optional: string}, indexData) => (
                                                    <>
                                                        {dataValue.optional === 'indirectEmployeesModal' ? (
                                                            <Tooltip title={'Lista de empleados indirectos'} placement="top">
                                                                <span
                                                                    key={'key_details_no_clickable' + indexData}
                                                                    style={{cursor: 'pointer'}}
                                                                    onClick={() => {
                                                                        this.setState({openIndirectEmployeesModal: true});
                                                                    }}
                                                                >
                                                                    <span>
                                                                        {dataValue.prefix}
                                                                        {customFormatNumber(dataValue.value)}
                                                                        {dataValue.suffix}
                                                                    </span>
                                                                </span>
                                                            </Tooltip>
                                                        ) : (
                                                            <span key={'key_details_clickable' + indexData}>
                                                                <span>
                                                                    {dataValue.prefix}
                                                                    {customFormatNumber(dataValue.value)}
                                                                    {dataValue.suffix}
                                                                </span>
                                                            </span>
                                                        )}
                                                    </>
                                                ))}
                                        </div>
                                    ))}
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                </WrappingDiv>
            </div>
        );
    }
}

type Props = {
    match: any;
    classes: any;
};
type StateProps = {
    maps: Map[];
    mapsIsLoading: boolean;
};
type DispatchProps = {
    push(path: string): void;
    fetchMaps(): any;
    fetchMap(mapId: string): any;
    // fetchKPIs(): BlueprintKPIs;
};

const mapStateToProps = (state: AppStoreState) => {
    return {
        maps: getMaps(state.maps),
        mapsIsLoading: getIsLoading(state.maps),
    };
};
const mapDispatchToProps = () => (dispatch: any) => ({
    push: (e: any) => dispatch(push(e)),
    fetchMaps: () => dispatch(fetchMaps()),
    fetchMap: (mapId: string) => dispatch(fetchMap(mapId)),
    // fetchKPIs: () => dispatch(fetchKPIs()),
});

export const Maps = connect<StateProps, DispatchProps, Props, AppStoreState>(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MapsBase));

//TODO: ¿ajustar los estilos de la capa contenedora?
// - no debería ser min-height??
const WrappingDiv = styled.div`
    height: calc(100% - 40px);
    padding: 0;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    color: rgba(50, 50, 50, 1);
    position: relative;
`;
const ContainerDiv = styled.div`
    max-width: 1140px;
    margin: 0 auto;
`;
