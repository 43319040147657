import React from 'react';
import styled from 'styled-components';

import {WrapForm} from 'src/app/administration/export/styled/Wrap';
import {CustomButton} from 'src/shared/style/CustomButton';
import {notifyValidator} from 'src/utils/notifyValidator';
import {CustomTextField} from 'src/shared/style/CustomTextField';
import {fetchWithFeedback} from 'src/utils/fetcherValidate';

import {PalletDefinitionCstmField} from './store/palletDefinitionCstmFieldsControl.types';
import {FormControlLabel, Checkbox} from '@material-ui/core';
import {PalletDefinitionCstmFieldsControlServices} from './store/palletDefinitionCstmFieldsControl.services';

type State = Readonly<{
    id: string;
    name: string;
    description: string;
    code_erp: string;
    id_erp: number;
    active: boolean;
    order_item: number;
}>;

const initialState: Readonly<State> = {
    id: '',
    name: '',
    description: '',
    code_erp: '',
    id_erp: 0,
    active: false,
    order_item: 0,
};

type Props = {
    palletDefinitionCstmField?: PalletDefinitionCstmField;
    handleClose(): void;
    updatePalletDefinitionCstmField(): void;
};

export class PalletDefinitionCstmFieldForm extends React.Component<Props, State> {
    readonly state = initialState;

    componentDidMount() {
        if (this.props.palletDefinitionCstmField) {
            const {id, name, description, code_erp, id_erp, active, order_item} = this.props.palletDefinitionCstmField;
            this.setState({
                id,
                name,
                description,
                code_erp,
                id_erp,
                active,
                order_item,
            });
        } else this.setState(initialState);
    }

    private handleActiveCheckbox = () => {
        this.setState({active: !this.state.active});
    };

    render() {
        const {id, name, description, code_erp, id_erp, active, order_item} = this.state;
        return (
            <WrapForm>
                <br />
                <div>
                    <div>
                        <CustomTextField
                            margin={'0 0 12px 0'}
                            label={'Descripción'}
                            variant="outlined"
                            width={'100%'}
                            onChange={this.handleInputs('description')}
                            value={description}
                        />
                    </div>
                    <div>
                        <CustomTextField margin={'0 0 12px 0'} label={'Nombre del campo'} variant="outlined" width={'70%'} onChange={this.handleInputs('name')} value={name} />
                    </div>
                    <div>
                        <CustomTextField margin={'0 0 16px 0'} label={'Código en ERP'} variant="outlined" width={'40%'} onChange={this.handleInputs('code_erp')} value={code_erp} />
                    </div>
                    <div>
                        <CustomTextField margin={'0 0 0 0'} label={'ID en ERP'} variant="outlined" width={'40%'} onChange={this.handleInputs('id_erp')} value={id_erp} />
                    </div>
                    <div>
                        <FormControlLabel style={{width: 290}} control={<Checkbox checked={active} onChange={this.handleActiveCheckbox} value="1" />} label="Activo" />
                    </div>
                    <div>
                        <CustomTextField margin={'0 0 24px 0'} label={'Orden'} variant="outlined" width={'40%'} onChange={this.handleInputs('order_item')} value={order_item} />
                    </div>
                </div>

                <CustomButton onClick={this.handleSend} width={'100%'} margin="unset" padding={'5px 0'}>
                    {id ? 'Guardar' : 'Crear'} campo
                </CustomButton>
            </WrapForm>
        );
    }

    private handleSend = async () => {
        const {id, name, description, code_erp, id_erp, active, order_item} = this.state;
        const validator = notifyValidator(this);
        if (id) {
            const res = await fetchWithFeedback(
                PalletDefinitionCstmFieldsControlServices.update(id, {
                    name,
                    description,
                    code_erp,
                    id_erp,
                    active,
                    order_item,
                }),
                {showMessage: true, returnConfirmation: true, notifyValidator: validator},
            );
            if (res) {
                this.props.updatePalletDefinitionCstmField();
                this.props.handleClose();
            }
        } else {
            const res = await fetchWithFeedback(
                PalletDefinitionCstmFieldsControlServices.create({
                    name,
                    description,
                    code_erp,
                    id_erp,
                    active,
                    order_item,
                }),
                {showMessage: true, returnConfirmation: true, notifyValidator: validator},
            );
            if (res) {
                this.setState(initialState);
                this.props.updatePalletDefinitionCstmField();
                this.props.handleClose();
            }
        }
    };

    private handleInputs: HandleNamedChange<State> = name => e => {
        this.setState({[name]: e.target.value} as Pick<State, keyof State>);
    };
}

export const LeftPart = styled.div`
    width: 55%;
`;
export const RightPart = styled.div`
    width: 47%;
    display: flex;
    flex-direction: column;
`;
export const DataDiv = styled.div`
    display: flex;
`;
export const PhotoDiv = styled.div`
    display: flex;
    flex-direction: column;
`;
