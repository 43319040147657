import format from 'date-fns/format';
import es from 'date-fns/locale/es';
import * as React from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';

import {LaneSelectSelectors} from 'src/app/laneSelect/store';
import {LoginSelectors} from 'src/app/login/store/login.selectors';
import {AreaShiftServices} from 'src/app/lanemanager/areashift/store/areashift.services';
import {AreaShiftUnit} from 'src/app/lanemanager/areashift/store/areashift.types';
import {FormControlLabel, Switch, Typography} from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {PaginatedTableComponent} from 'src/shared/components/PaginatedTableComponent';
import {CustomButton} from 'src/shared/style';
import {IsInRuleset} from 'src/global/authorization/grantSet';
import {AppStoreState} from 'src/store';
import {ColumnType} from 'src/types';
import {fetchWithFeedback} from 'src/utils/fetcherValidate';
import {notify} from 'src/utils/Notification';
import { AreaControlServices } from 'src/app/administration/areaControl/store/services';
import { ShiftsMapService } from 'src/shared/components/services';
//import { AreasControlServices } from 'src/app/administration/areasControl/store/areasControl.services';

type SProps = {
    lane: ReturnType<typeof LaneSelectSelectors.getLane>;
    userData: ReturnType<typeof LoginSelectors.getUserData>;
};
type State = Readonly<{
    open: boolean;
    currentAreaShift?: AreaShiftUnit;
    autoVinculated: boolean;
    shiftsMap : Map<string, string>;
}>;

const initialState: State = {
    open: false,
    autoVinculated: false,
    shiftsMap: new Map<string, string>()
};

// const Table = TableComponent<CausesControlResponse>();

export class AreaShiftsBase extends React.Component<SProps, State> {
    readonly state = initialState;
    componentDidMount() {
        this.fetchShiftsMap();
        this.fetchCurrent();
    }
    private tableHook: () => void = () => ({});
    private getTableHook = (fn: () => void) => (this.tableHook = fn);
    private useTableHook = () => {
        if (typeof this.tableHook === 'function') this.tableHook();
    };

    private updateData = () => {
        this.useTableHook();
        this.fetchCurrent();
    };

    private fetchShiftsMap = async () => {
        const shifts : Array<{
            id:string;
            name:string;
        }> = await fetchWithFeedback(ShiftsMapService(), {accessor: 'shifts'});
        shifts.forEach(v => this.state.shiftsMap.set(v.id, v.name));
    }

    private fetchCurrent = async () => {
        if (this.props.lane) {
            const currentAreaShift = await fetchWithFeedback(AreaShiftServices.current(this.props.lane.laneId), {accessor: 'areaShift'});
            if (currentAreaShift) {
                this.setState({currentAreaShift});
                this.setState({autoVinculated: currentAreaShift.auto_vinculated});
            }
        }
    };

    private finishShift = async () => {
        const {currentAreaShift} = this.state;
        if (currentAreaShift) {
            const res = await fetchWithFeedback(AreaShiftServices.finish(currentAreaShift.id));
            if (res) {
                this.setState(() => ({currentAreaShift: undefined, open: false}), this.useTableHook);
            }
        }
    };

    private createShift = async () => {
        if (!this.props.userData.employeeId) return notify({message: 'Su usuario no tiene empleado asociado.', status: 'error'});
        const {currentAreaShift} = this.state;
        const {lane, userData} = this.props;
        if (!currentAreaShift && lane && userData) {
            const res = await fetchWithFeedback(
                AreaShiftServices.create({
                    area_id: lane.laneId,
                    employee_id: '' + userData.employeeId,
                }),
            );
            if (res) this.updateData();
        } else {
            notify({
                message: 'Finalice el turno actual para iniciar un nuevo turno.',
                status: 'warning',
            });
        }
    };

private handleAutoVinculated = (event: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = event.target.checked;
        this.setState({autoVinculated: isChecked});
         const res = fetchWithFeedback(AreaControlServices.toggleAutoVinculated(this.props.lane.laneId,isChecked));
         if (res) {
             this.fetchCurrent();
         }
    }

    private toggleExpand = () => this.setState({open: !this.state.open});

    private columns: ColumnType<AreaShiftUnit> = [
        {
            Header: 'Jornada',
            headerClassName: 'header_cell_string',
            accessor: 'workshift',
            dateFormat: true,
            filterable: false,
            className: 'cell_string',
        },
        {
            Header: 'Turno',
            headerClassName: 'header_cell_string',
            Cell: item => <>{this.state.shiftsMap.get(item.original.area_shift)}</>,
            accessor: 'areashift',
            className: 'cell_string',
        },
        {
            Header: 'Estado de turno',
            headerClassName: 'header_cell_string',
            Cell: item => <>{item.original.end_date ? 'CERRADA' : 'ABIERTA'}</>,
            accessor: 'end_date',
            className: 'cell_string',
        },
        {
            Header: 'H.Inicio',
            headerClassName: 'header_cell_string',
            accessor: 'start_date',
            dateTimeFormat: true,
            filterable: false,
            sortable: false,
            // date: true,
            className: 'cell_string',
        },
        {
            Header: 'H.Fin',
            headerClassName: 'header_cell_string',
            accessor: 'end_date',
            dateTimeFormat: true,
            filterable: false,
            sortable: true,
            // date: true,
            className: 'cell_string',
        },
        {
            Header: 'Emp.Nombre',
            headerClassName: 'header_cell_string',
            accessor: 'employee_name',
            filterable: true,
            sortable: false,
            className: 'cell_string',
        },
        {
            Header: 'Emp.Apellidos',
            headerClassName: 'header_cell_string',
            accessor: 'employee_surname',
            filterable: true,
            sortable: false,
            className: 'cell_string',
        },
        {
            Header: 'Emp.Codigo',
            headerClassName: 'header_cell_string',
            accessor: 'employee_code',
            filterable: true,
            sortable: false,
            className: 'cell_string',
        },
    ];

    render() {
        const TOTAL_ACCESS = IsInRuleset(['LANEMANAGER_COMMON_TOTAL', 'LANEMANAGER_AREASHIFTS_TOTAL']);
        const VIEW_ACCESS = IsInRuleset(['LANEMANAGER_AREASHIFTS_VIEW', 'LANEMANAGER_COMMON_VIEW']);
        const {open, currentAreaShift} = this.state;
        const {lane} = this.props;
        return (
            (VIEW_ACCESS || TOTAL_ACCESS) && (
                <TableWrap>
                    <ExpansionWrap>
                        <ExpansionPanel disabled={!currentAreaShift} expanded={open} onChange={TOTAL_ACCESS ? this.toggleExpand : undefined}>
                            <ExpansionPanelSummary expandIcon={TOTAL_ACCESS && <ExpandMoreIcon />}>
                                <Typography variant="h6">
                                    {!currentAreaShift && 'No hay turno activo'}
                                    {currentAreaShift &&
                                        'Turno actual: ' +
                                            format(new Date(currentAreaShift.start_date), 'DD/MM/YYYY HH:mm', {
                                                locale: es,
                                            })}
                                    { currentAreaShift?.rfid && currentAreaShift?.auto_vinculated ? ' ( Auto vinculado activo )' : '' }
                                </Typography>
                            </ExpansionPanelSummary>
                            {currentAreaShift && (
                                <ExpansionPanelDetails>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                { currentAreaShift?.rfid ? (
                                                <TableCell align="center">{<div style={divStyleChecks}>

                                                        <FormControlLabel
                                                            style={{width: 200, height: 20, marginTop: 0}}
                                                            control={<Switch
                                                                checked={this.state.autoVinculated}
                                                                onChange={this.handleAutoVinculated}
                                                            />}
                                                            label="Auto vinculación"
                                                        />
                                                </div>}</TableCell>) : ''}
                                                <TableCell align="center">{currentAreaShift.employee_name}</TableCell>
                                                <TableCell align="center">{currentAreaShift.employee_surname}</TableCell>
                                                <TableCell align="center">
                                                    {format(new Date(currentAreaShift.start_date), 'DD/MM/YYYY HH:mm', {
                                                        locale: es,
                                                    })}
                                                </TableCell>
                                                <TableCell align="center" style={{width: 130}}>
                                                    <CustomButton onClick={this.finishShift} width={'130px'} height={'40px'}>
                                                        Finalizar
                                                    </CustomButton>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </ExpansionPanelDetails>
                            )}
                        </ExpansionPanel>
                    </ExpansionWrap>
                    <TableComponentWrap>
                        <PaginatedTableComponent
                            defaultPageSize={20}
                            columnFormat={this.columns}
                            service={AreaShiftServices.getOfLane}
                            serviceArgs={[lane.laneId]}
                            loading={true}
                            getTableFetchHook={this.getTableHook}
                            createHook={TOTAL_ACCESS ? this.createShift : undefined}
                        />
                    </TableComponentWrap>
                </TableWrap>
            )
        );
    }
}

export const AreaShifts = connect<SProps, {}, {}, AppStoreState>(state => ({
    userData: LoginSelectors.getUserData(state),
    lane: LaneSelectSelectors.getLane(state),
}))(AreaShiftsBase);

const TableWrap = styled.div`
    width: calc(100% - 15px);
    height: 100%;
    margin: auto;
    margin-top: 0;
`;

const divStyleChecks = {
    padding: '5px',
    margin: '5px',
    height: '20px',
    paddingLeft: '30px',
    backgroundColor: 'white',
};

const ExpansionWrap = styled.div`
    /* width: 99.4%; */
    margin: 0 5px 5px 5px;
`;

const TableComponentWrap = styled.div`
    height: calc(100% - 60px);
`;
