// import {fetcher} from 'src/global/fetcher';
import {guardedFetcher} from 'src/utils/guardedFetcher';
import {PalletInfoUnit, PalletSimpleInfoUnit} from './palletInformation.types';

// import {guardedFetcher} from 'src/utils/guardedFetcher';

const prefix = 'production/info';
export const PalletInformationServices = {
    getInfo: (palletBarcode: string) => guardedFetcher.get<{pallet: PalletInfoUnit}>(`${prefix}/detailed-pallet/${palletBarcode}`),
    getSimpleInfo: (palletBarcode: string) => guardedFetcher.get<{pallet: PalletSimpleInfoUnit}>(`${prefix}/simple-pallet/${palletBarcode}`),
    sendInfoPallet: (palletId: string, cstmFields: any, realWeight: number | undefined) =>
        guardedFetcher.patch(`production/control/send-info-pallet/${palletId}`, {
            cstmFields,
            realWeight,
        }),
    addLogisticUnitToPallet: ({logisticunit_barcode, palletId}: {logisticunit_barcode: string; palletId: string}) =>
        guardedFetcher.patch(`production/control/detailed-pallet/${palletId}`, {
            logisticunit_barcode,
        }),
    createPallet: ({logisticunit_barcode, palletId}: {logisticunit_barcode: string; palletId: string}) =>
        guardedFetcher.patch(`production/control/detailed-pallet/${palletId}`, {
            logisticunit_barcode,
        })
};
