import * as React from 'react';
import {connect} from 'react-redux';
import {AppStoreState} from 'src/store';
import {OCard} from 'src/shared/style';
import styled from 'styled-components';
import {format} from 'date-fns';
import es from 'date-fns/locale/es';
import {FullSizeModal} from 'src/shared/components/FullSizeModal';
import {ResumeDataCard} from './ResumeDataCard';
import {ChartsServices} from 'src/app/lanemanager/charts/store/services';
import {Spinner} from 'src/app/global/spinner';
//import {CustomDialog} from 'src/shared/components/CustomDialog';

export interface Props {
    open: boolean;
    initialWorkshift: Date;
    finalWorkshift: Date;
    areaId: string;
    areaName: string;
    hideResumeDataModal: () => void;
}

const initialState = {
    areaName: '',
    areaId: '',
    currentWorkshift: '',
    areaShiftsInfoList: [],
    dataIsLoading: true,
};

// const Table = TableComponent<LaneSelectResponse>();
export class ResumeDataModalBase extends React.Component<Props> {
    state = initialState;

    componentWillReceiveProps(nextProps: any) {
        if (nextProps.open === true) {
            this.showData(nextProps);
        }
    }

    private hideModalTable = () => {
        this.props.hideResumeDataModal();
    };

    showData(props: any) {
        let areaShiftsInfoList: any[] = [];
        this.setState({areaShiftsInfoList});
        this.setState({dataIsLoading: true});
        /*
        let areaShiftsInfoList = [
            {
                isTotal: false,
                lineManagerName: 'S Casado',
                batchesNumber: '10',
                dumpsKg: '80000',
                durationAreashift: '90',
                kgByHour: '12000',
                startAreashift: '2020-05-20 06:00:00',
                endAreashift: '2020-05-20 15:00:00',
                eurosByKgDirectCost: '0.035',
                employeesNumberDirect: '35',
                totalBoxes: '14500',
                boxesInPallets: '12500',
                boxesWithoutPallets: '2000',
                palletsNumber: '120',
                kgInPallets: '70000',
            },
            {
                isTotal: false,
                lineManagerName: 'MC SIMON',
                batchesNumber: '15',
                dumpsKg: '85000',
                durationAreashift: '9',
                startAreashift: '2020-05-20 16:00:00',
                endAreashift: '2020-05-21 01:00:00',
                kgByHour: '13000',
                eurosByKgDirectCost: '0.035',
                employeesNumberDirect: '37',
                totalBoxes: '15000',
                boxesInPallets: '14500',
                boxesWithoutPallets: '500',
                palletsNumber: '125',
                kgInPallets: '75000',
            },
            {
                isTotal: true,
                lineManagerName: 'TOTAL',
                batchesNumber: '10',
                dumpsKg: '80000',
                durationAreashift: '9',
                kgByHour: '12000',
                eurosByKgDirectCost: '0.035',
                employeesNumberDirect: '35',
                totalBoxes: '14500',
                boxesInPallets: '12500',
                boxesWithoutPallets: '2000',
                palletsNumber: '120',
                kgInPallets: '70000',
            },
        ];
*/

        const initialWorkshiftString = format(new Date(props.initialWorkshift), 'YYYY-MM-DD', {
            locale: es,
        });
        const finalWorkshiftString = format(new Date(props.finalWorkshift), 'YYYY-MM-DD', {
            locale: es,
        });

        ChartsServices.getResumeDataByAreaAndWorkshift(props.areaId, initialWorkshiftString, finalWorkshiftString)
            .then((res: any) => {
            console.log(res);
            if (res && res.data) {
                areaShiftsInfoList = [];
                for (const index in res.data) {
                    if (res.data[index]) {
                        const areaShiftInfo = {
                            isTotal: res.data[index].isTotal,
                            lineManagerName: res.data[index].lineManagerName,
                            batchesNumber: res.data[index].batchesNumber,
                            dumpsKg: res.data[index].dumpsKg,
                            durationAreashift: res.data[index].durationAreashift,
                            startAreashift: res.data[index].startAreashift,
                            endAreashift: res.data[index].endAreashift,
                            kgByHour: res.data[index].kgByHour,
                            eurosByKgDirectCost: res.data[index].eurosByKgDirectCost,
                            employeesNumberDirect: res.data[index].employeesNumberDirect,
                            totalBoxes: res.data[index].totalBoxes,
                            boxesInPallets: res.data[index].boxesInPallets,
                            boxesWithoutPallets: res.data[index].boxesWithoutPallets,
                            palletsNumber: res.data[index].palletsNumber,
                            kgInPallets: res.data[index].kgInPallets,
                        };

                        areaShiftsInfoList.push(areaShiftInfo);
                    }
                }
                this.setState({areaShiftsInfoList});

                // this.setState({dataIsLoading: false});
            }
        }).finally(() => {
            this.setState({dataIsLoading: false});
        });

        /*
    const {employeeTaskSelect} = props;

    EmployeeTaskServices.getByWorkshiftAndEmployee(
      employeeTaskSelect.workshift,
      employeeTaskSelect.employeeId,
    ).then((res: any) => {
      const activitiesByWorkshiftAndEmployee: EmployeeTask[] = [];
      let durationMinInWorkshift = 0;
      let centerName = '';

      if (res && res.data) {
        for (const index in res.data) {
          if (res.data[index]) {
            const durationMin = Math.round(res.data[index].duration / 60);
            durationMinInWorkshift += durationMin;
            centerName = res.data[index].center_name;

            const dataActivity: EmployeeTask = {
              employeeTaskId: res.data[index].employee_task_id,
              employeeId: res.data[index].employee_id,
              centerName: res.data[index].center_name,
              centerId: res.data[index].center_id,
              areaName: res.data[index].area_name,
              areaId: res.data[index].area_id,
              taskName: res.data[index].task_name,
              taskId: res.data[index].task_id,
              taskDirect: this.getValuesDirect(Number(res.data[index].direct)),
              createdAt: this.getDateFormat(res.data[index].created_at),
              finishedAt: this.getDateFormat(res.data[index].finished_at),
              createdAtDay: '2020-04-13', //res.data[index].created_at,
              finishedAtDay: '2020-04-13', //res.data[index].finished_at,
              duration: durationMin,
              automaticModify: parseInt(res.data[index].automatic_modify, 10),
              manuallyModify: parseInt(res.data[index].manually_modify, 10),
            };

            activitiesByWorkshiftAndEmployee.push(dataActivity);
          }
        }
      }
      this.setState({activities: activitiesByWorkshiftAndEmployee});
      this.setState({
        durationInHours:
          Math.round((durationMinInWorkshift / 60 + Number.EPSILON) * 100) / 100,
      });
      this.setState({centerName: centerName});
    });*/
    }

    getDateFormat(dateString: string) {
        if (dateString === null) {
            return '';
        }
        return format(new Date(dateString), 'HH:mm:ss', {locale: es});
    }

    render() {
        let dateString = this.props.initialWorkshift ? format(this.props.initialWorkshift, 'DD/MM/YYYY', {
            locale: es,
        }) : '';

        if (dateString && this.props.finalWorkshift) {
            const endDateString = format(this.props.finalWorkshift, 'DD/MM/YYYY', {locale: es});
            if (dateString !== endDateString) {
                dateString += ' - ' + endDateString;
            }
        }
        return (
            <>
                <FullSizeModal title={'Resumen'} open={this.props.open} handleClose={this.hideModalTable} withMargin={true}>
                    <WrappingDiv>
                        <OCard width={'100%'} contentHeight={'50px'} contentPadding={'5px'} margin={'auto'} marginBottom={'1em'}>
                            <TitleEmployeeStyle>
                                <div
                                    style={{
                                        flexGrow: 7,
                                    }}
                                >
                                    <WrappingP>
                                        <h2>
                                            {this.props.areaName}
                                            {' ( '}{dateString}{' )'}
                                        </h2>
                                    </WrappingP>
                                </div>
                            </TitleEmployeeStyle>
                        </OCard>
                        <OCard width={'100%'} contentHeight={'100%'} contentPadding={'5px'} margin={'auto'} marginBottom={'1em'}>
                            <Spinner isLoading={this.state.dataIsLoading} />
                            {this.state.areaShiftsInfoList.length === 0 && <p>Calculando datos</p>}
                            {this.state.areaShiftsInfoList &&
                                this.state.areaShiftsInfoList.map((infoCard: any) => <ResumeDataCard key={infoCard.lineManagerName} infoCard={infoCard} />)}
                        </OCard>
                        {/* <OCard width={'100%'} contentHeight={'100%'} contentPadding={'5px'} margin={'auto'} marginBottom={'1em'}>
                                   <WrappingP>
                                       <b>Listado de fichajes {this.state.centerName !== '' ? ' - ' + this.state.centerName : ''}</b>
                                   </WrappingP>
                                   <hr />
                                   <Table aria-label="simple table">
                                       <TableHead>
                                           <TableRow>
                                               <TableCell>Tarea</TableCell>
                                               <TableCell align="center">Inicio</TableCell>
                                               <TableCell align="center">Fin</TableCell>
                                               <TableCell align="right">Min</TableCell>
                                               <TableCell>{}</TableCell>
                                           </TableRow>
                                       </TableHead>
                                       <TableBody>
                                           {this.state.activities.map((row: EmployeeTask) => (
                                               <TableRow key={row.employeeTaskId}>
                                                   <TableCell
                                                       component="th"
                                                       scope="row"
                                                       style={{
                                                           color: row.manuallyModify === 1 ? '#4245FB' : row.automaticModify === 1 ? '#FBBB42' : '',
                                                       }}
                                                   >
                                                       <p>
                                                           <b>{row.taskName}</b> ({row.taskDirect})
                                                       </p>
                                                       <p>{row.areaName}</p>
                                                   </TableCell>
                                                   <TableCell align="center">{row.createdAt}</TableCell>
                                                   <TableCell align="center">{row.finishedAt}</TableCell>
                                                   <TableCell align="right">{row.duration}</TableCell>
                                                   <TableCell>
                                                       <CustomButton
                                                           width={'unset'}
                                                           minWidth={'unset'}
                                                           height={'unset'}
                                                           //padding={'0 5px 9px 5px'}
                                                           margin={'2px 2px 2px 2px'}
                                                           bgcolor={'#c44'}
                                                           onClick={() => {
                                                               this.setState({
                                                                   employeeTaskIdSelected: row.employeeTaskId,
                                                               });
                                                               this.setState({taskIdSelected: row.taskId});
                                                               this.setState({createdAtSelected: row.createdAt});
                                                               this.setState({finishedAtSelected: row.finishedAt});
                                                               this.setState({
                                                                   createdAtDaySelected: row.createdAtDay,
                                                               });
                                                               this.setState({
                                                                   finishedAtDaySelected: row.finishedAtDay,
                                                               });
                                                               this.setState({centerIdSelected: row.centerId});
                                                               this.setState({areaIdSelected: row.areaId});
                                                               this.setState({showModalEdit: true});
                                                           }}
                                                       >
                                                           <Tooltip title={'Editar marcaje'} placement="top">
                                                               <EditIcon />
                                                           </Tooltip>
                                                       </CustomButton>
                                                       <CustomButton
                                                           width={'unset'}
                                                           minWidth={'unset'}
                                                           height={'unset'}
                                                           //padding={'0 5px 9px 5px'}
                                                           margin={'2px 2px 2px 2px'}
                                                           bgcolor={'#c44'}
                                                           onClick={() => {
                                                               this.setState({showModalDelete: true});
                                                               this.setState({
                                                                   employeeTaskIdSelected: row.employeeTaskId,
                                                               });
                                                           }}
                                                       >
                                                           <Tooltip title={'Eliminar marcaje'} placement="top">
                                                               <DeleteIcon />
                                                           </Tooltip>
                                                       </CustomButton>
                                                   </TableCell>
                                               </TableRow>
                                           ))}
                                       </TableBody>
                                       <CustomButton
                                           width={'unset'}
                                           minWidth={'unset'}
                                           height={'unset'}
                                           //padding={'0 5px 9px 5px'}
                                           margin={'2px 2px 2px 2px'}
                                           bgcolor={'#c44'}
                                           onClick={() => this.setState({showModalEdit: true})}
                                       >
                                           <Tooltip title={'Crear marcaje'} placement="top">
                                               <AddIcon />
                                           </Tooltip>
                                       </CustomButton>
                                   </Table>
                               </OCard> */}
                    </WrappingDiv>
                </FullSizeModal>
            </>
        );
    }
}

const WrappingDiv = styled.div`
    width: calc(99%);
    margin: auto;
    margin-top: 8px;
    height: calc(99%);
    padding: 0px;
    margin-bottom: 5px;
`;

const WrappingP = styled.p`
    text-align: center;
    font-size: 12px;
`;

const TitleEmployeeStyle = styled.div`
    display: flex;
    align-items: stretch;
    //background-color: #f1f1f1;
`;

//const selectArea = (workShift: string, areaId: string, areaName: string) => EmployeeTaskSelectActions.select(workShift, areaId, areaName);

// const clearLaneDump = () => ;
export const ResumeDataModal = connect<{}, {}, {}, AppStoreState>(state => state)(ResumeDataModalBase);
/*
export const ResumeDataModal = connect<SProps, DProps, {}, AppStoreState>(state => ({employeeTaskSelect: EmployeeTaskSelectSelectors.getEmployeeTask(state)}), {
    selectArea,
})(ResumeDataModalBase);
*/
