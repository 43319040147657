import * as React from 'react';
import styled from 'styled-components';
import Table from '@material-ui/core/Table';
import Tooltip from '@material-ui/core/Tooltip';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Paper from '@material-ui/core/Paper';
import {withStyles} from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';

export const CustomPaper = withStyles({
  root: {
    height: '100%',
    width: '100%',
    padding: 0,
  },
})(Paper);

export interface CustomCell {
  children?: any;
  width?: string;
  color?: string;
  padding?: string;
  classes?: any;
}
export const CustomCell = withStyles({
  cell: {
    fontSize: '1em',
    paddingLeft: '10px',
    paddingRight: '10px',
    textAlign: 'center',
  },
})((props: CustomCell) => (
  <TableCell
    className={props.classes.cell}
    style={{
      width: props.width || undefined,
      maxWidth: props.width || undefined,
      backgroundColor: props.color || undefined,
      padding: props.padding || undefined,
    }}>
    {props.children!}
  </TableCell>
));

const tableStyles = {
  table: {
    width: '100%',
    height: '100%',
  },
};
export const CustomTable = withStyles(tableStyles)((props: any) => (
  <Table className={props.classes.table}>{props.children}</Table>
));

const rowStyles = {
  row: {
    height: '72px',
    fontSize: '0.9em',
  },
};

export const CustomRow = withStyles(rowStyles)((props: any) => (
  <TableRow className={props.classes.row}>{props.children}</TableRow>
));

export const ChipsWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`;

const buttonStyles = {
  button: {
    minWidth: 36,
    minHeight: 36,
    borderRadius: 10,
    padding: 5,
    backgroundColor: '#009688',
    display: 'flex',
  },
};
export const CustomButtonProdPartsInfo = withStyles(buttonStyles)((props: any) => (
  <Button
    className={props.classes.button}
    onClick={props.action}
    disabled={props.disabled}>
    <AddIcon />
  </Button>
));

const WrapModalCell = styled.div`
  display: table-cell;
  vertical-align: middle;
`;

const WrapModalContent = styled.div`
  width: 900px;
  height: 500px;
  /* display: table-cell; */
  /* vertical-align: middle; */
  margin-left: auto;
  margin-right: auto;
`;

const cardStyles = {
  card: {
    height: '100%',
    width: '100%',
  },
};

const CustomCard = withStyles(cardStyles)((props: any) => (
  <Card className={props.classes.card}>
    <CardContent>{props.children}</CardContent>
  </Card>
));
const modalStyles = {
  root: {
    backgroundColor: '#3333',
    display: 'table',
    width: '100%',
    height: '100%',
  },
};
export const CustomModal = withStyles(modalStyles)((props: any) => (
  <Modal className={props.classes.root} open={props.open} onBackdropClick={props.toggle}>
    <WrapModalCell>
      <WrapModalContent>
        <CustomCard>{props.children}</CustomCard>
      </WrapModalContent>
    </WrapModalCell>
  </Modal>
));

const chipStyles = {
  chip: {
    marginLeft: 4,
    marginRight: 4,
  },
};
export const ChipWrap = withStyles(chipStyles)((props: any) => {
  if (props.state === 'ACTIVA')
    return (
      <Tooltip title="No puede desasignar una mesa activa.">
        <Chip
          label={props.name}
          className={props.classes.chip}
          style={{backgroundColor: props.color}}
        />
      </Tooltip>
    );
  else
    return (
      <Chip
        label={props.name}
        className={props.classes.chip}
        style={{backgroundColor: props.color}}
        onDelete={props.onDelete}
      />
    );
});

export const ChipsDivWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export const ModalHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const textFieldStyles = {
  text: {
    height: 45,
    marginTop: 0,
  },
};

export const CustomTextField = withStyles(textFieldStyles)((props: any) => (
  <TextField
    type="search"
    className={props.classes.text}
    margin="dense"
    variant="outlined"
    label={props.label}
    placeholder={'Texto de búsqueda...'}
    InputLabelProps={{
      shrink: true,
    }}
    onChange={props.handleChange}
  />
));

export const SearchWrap = styled.div`
  height: 45px;
  margin-bottom: 3px;
  display: flex;
`;

export const ButtonSearchMenuWrap = styled.div`
  display: flex;
  margin-left: 30px;
`;
