import {PaginatedServiceArgs} from 'src/types';
import {guardedFetcher} from 'src/utils/guardedFetcher';

import {PalletsQueue, PalletsResponse} from './types';
import {palletsValidate} from './validators/PalletsResponse';

export const PalletsServices = {
    getAll: async () => {
        try {
            const validate = await palletsValidate;
            const {data, status} = await guardedFetcher.get<PalletsResponse>('production/info/pallets');

            if (!validate(data)) {
                console.log('no he validado>> ', validate.errors);
            }

            if (status === 200) return data;
            else {
                console.log('no he validado>> ', validate.errors);
                return undefined;
            }
        } catch (error) {
            return undefined;
        }
    },

    getAllPaginated: ({params, args}: PaginatedServiceArgs) =>
        guardedFetcher.get<PaginateResponse<PalletsResponse>>('production/info/pallets', {
            params: args ? {...params, filtered: {area_id: args, ...params!.filtered}} : {...params},
        }),
    update: (ptableId: string, palletId: string, boxes: number, datetime: string) =>
        guardedFetcher.post(`production/control/ptable/${ptableId}/pallet/${palletId}/addboxes`, {boxes, datetime}),
    remove: (ptableId: string, palletId: string, boxes: number, datetime: string) =>
        guardedFetcher.post(`production/control/ptable/${ptableId}/pallet/${palletId}/removeboxes`, {boxes, datetime}),
    finish: (palletId: string) => guardedFetcher.post(`production/control/pallet/${palletId}/finish`),
    transferBoxes: (batchId: string | undefined, previusPalletId: string | undefined, nextPalletId: string | undefined, boxes: number | undefined) =>
        guardedFetcher.post(`production/control/ptableprevius/${previusPalletId}/ptablenext/${nextPalletId}/batch/${batchId}/transfer-boxes`, {boxes}),
    vinculatePallet: (barcodePallet: string, ptableId: string | undefined) => guardedFetcher.post(`production/control/ptable/${ptableId}/pallet/${barcodePallet}/start-web`),
    vinculatePalletFromProdpart: (barcodePallet: string, prodpartCode: string | undefined, areaId: string) =>
        guardedFetcher.post(`production/control/pallet/${barcodePallet}/start-pallet`,{prodpartCode, areaId}),

    enqueuePallets: (queue: PalletsQueue, ) =>
        guardedFetcher.post(`production/control/ptable/enqueuePallets`,{queue}),
};
