import * as React from 'react';
import {TableModal} from 'src/shared/components/Modal';
import {Typography} from '@material-ui/core';
import {CustomButton, CustomTextField} from 'src/shared/style';
import {BarcodeScanner} from '../../../../../shared/components/BarcodeScanner';
import {Advertisement} from '../../../../../shared/components/Advertisement';
import {validateBarcode} from '../../../../../shared/functions/BarcodeValidator';
import {NotifyActionType} from '../../../../../utils/Notification/action-types';
import {findSystemParameter} from 'src/global/systemParameters/systemParameters';
import {ParameterCPN} from 'src/types/app_types';

export interface Props {
    open: boolean;
    hide: () => void;
    handleBarcodeReaded: (barcode : string, requestId : string) => void;
    requestId: string;
}

type State = {
    open: boolean;
    barcodePallet: string;
    openBarcodeScanner: boolean;
    showAd: boolean;
};

const initialState: State = {
    open: false,
    barcodePallet: '',
    openBarcodeScanner: false,
    showAd: false,
};

export class GetPalletModal extends React.Component<Props, State> {
    readonly state = initialState;
    private barcodeScanner = React.createRef<BarcodeScanner>();
    private adMessage = '';


    componentDidMount() {
        console.log('MOUNT');
        this.setState(initialState);
    }

    private showAd = (message: string) => {
        this.adMessage = message;
        this.setState({
            showAd: true,
        });
    };

    private hideAd = () => {
        this.setState({
            showAd: false,
        });
    };

    private hideModalTable = () => {
        this.props.hide();
        this.setState({
            barcodePallet: '',
        });
    };

    private hideBarcodeScanner = () => {
        this.setState({
            openBarcodeScanner: false,
        });
    };

    private handleChangeBarcodePallet = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (e.target.name === 'barcode_pallet') {
            const newValue = (e.target.value as unknown) as string;

            if (newValue.length > 13) return;

            this.setState({
                barcodePallet: newValue,
            });

            if (newValue.length === 13) {
                //const barcodePrefixes = [`${findSystemParameter(ParameterCPN.PALLET_BARCODE_PREFIX)?.value || ''}`,`${findSystemParameter(ParameterCPN.LOGISTIC_UNIT_BARCODE_PREFIX)?.value || ''}`];
                const isValid = validateBarcode(newValue);
                // const isValid = barcodePrefixes ? true : false;

                if (isValid) {
                    this.props.handleBarcodeReaded(newValue, this.props.requestId);
                    this.setState({
                        barcodePallet: '',
                    });
                } else {
                    this.showAd('El código de barras introducido no tiene el formato correcto');
                }
            }
        }
    };

    handleCodeReaded = (code: string) => {
        this.setState({
            barcodePallet: code,
        });

        const barcodePrefixes = [`${findSystemParameter(ParameterCPN.PALLET_BARCODE_PREFIX)?.value || ''}`];
        const isValid = validateBarcode(code, barcodePrefixes);

        if (isValid) {
            this.props.handleBarcodeReaded(code, this.props.requestId);
            this.setState({
                barcodePallet: '',
            });
        } else {
            this.showAd('El código de barras escaneado no tiene el formato correcto');
        }
    };

    scanBarcode = () => {
        if (navigator.mediaDevices) {
            if (this.barcodeScanner.current) {
                this.barcodeScanner.current.init();
                this.setState({openBarcodeScanner: true});
            }
        } else {
            this.showAd('No se puede acceder a la cámara. Se necesita entrar por https y permitir el acceso a la cámara. Gracias.');
        }
    };

    render() {
        return (
            <>
                <TableModal title={'Seleccionar palé'} open={this.props.open} handleClose={this.hideModalTable}>
                    <Typography
                        component="div"
                        style={{
                            marginTop: 10,
                        }}
                    >
                        <>
                            <CustomTextField
                                margin={'0 auto'}
                                width={'100%'}
                                label="Código de barras palé"
                                //placeholder={`${findSystemParameter(ParameterCPN.PALLET_BARCODE_PREFIX)?.value || ''}...`}
                                error={false}
                                value={this.state.barcodePallet}
                                name="barcode_pallet"
                                variant={'outlined'}
                                type="string"
                                onChange={this.handleChangeBarcodePallet}
                                autoFocus={true}
                            />
                        </>
                    </Typography>

                    <Typography
                        component="div"
                        style={{
                            marginTop: 10,
                        }}
                    >
                        <>
                            <CustomButton onClick={this.scanBarcode} padding={'3px'}>
                                {'Escanear código'}
                            </CustomButton>
                            <br />
                        </>
                    </Typography>
                </TableModal>

                <BarcodeScanner open={this.state.openBarcodeScanner} hide={this.hideBarcodeScanner} onReadedCode={this.handleCodeReaded} ref={this.barcodeScanner} />

                <Advertisement open={this.state.showAd} hide={this.hideAd} message={this.adMessage} type={NotifyActionType.error} />
            </>
        );
    }
}
