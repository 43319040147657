//import axios, {AxiosPromise} from 'axios';
import axios from 'axios';
import format from 'date-fns/format';
import es from 'date-fns/locale/es';
import invoke from 'lodash/invoke';
import React, {Component} from 'react';
import DatePicker, {DatePickerProps} from 'react-date-picker';
import {connect} from 'react-redux';
import ReactTable, {FilterRender} from 'react-table';
import {Dispatch} from 'redux';
import styled from 'styled-components';

import {withStyles, Button, CircularProgress, FilledInput, InputLabel, MenuItem, Select, Typography} from '@material-ui/core';

import Tooltip from '@material-ui/core/Tooltip';
import ClearAll from '@material-ui/icons/ClearAll';
import CloudUpload from '@material-ui/icons/CloudUpload';
import GetApp from '@material-ui/icons/GetApp';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import PlaylistAdd from '@material-ui/icons/PlaylistAdd';
import Refresh from '@material-ui/icons/Refresh';
import {OCard, StyledDatePicker, WrapFullContainerNowrap} from 'src/shared/style';
import {ColumnType} from 'src/types';
import {fetchHelperNotPaginated} from 'src/utils/fetcherValidate';
import {notify} from 'src/utils/Notification';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';

type Props<T, K extends keyof T = any> = {
    columnFormat: ColumnType;
    //service(...args: any[]): AxiosPromise<T>;
    service(...args: any[]): Promise<T>;
    defaultPageSize: number;
    showSizeOptions?: boolean;
    loading?: boolean;
    propsToOwnTable?: {[k: string]: any};
    autorefresh?: boolean;
    autorefreshDelay?: number;
    noPaginationComponent?: boolean;
    excel?: boolean;
    pdf?: boolean;
    upload?: boolean;
    options?: boolean;
    accessor?: K;
    getTableFetchHook?(fn: (...args: any[]) => void): void;
    defaultSorted?: any;
    createHook?(): void;
    dataHook?(data: any): void;
    customFilterFunction?(data: K extends undefined ? T : T[keyof T]): Array<T | T[keyof T]>;
    serviceArgs?: any[];
    additionalButtons?: Array<{
        icon: JSX.Element;
        tooltipText: string;
        onClick(): void;
    }>;
};
type DProps = {
    dispatch: Dispatch;
};
type State = {
    entries: any;
    filtered: Array<{id: string; value: string | boolean}>;
    date: {[k: string]: Date | Date[] | undefined};
    dropdownFilter: {[k: string]: any};
    uploading: boolean;
    expanded: {[k: string]: any};
};

const initialState: State = {
    entries: [],
    filtered: [],
    date: {},
    dropdownFilter: {},
    uploading: false,
    expanded: {},
};

type ComponentProps<T> = Props<T> & DProps;

export class Table<T> extends Component<ComponentProps<T>, State> {
    static defaultProps = {options: true};
    readonly state = initialState;
    private injectedColumns: ColumnType = [];
    private timer: any;
    private serviceUrl: string | undefined = '';
    private inputRef = React.createRef<HTMLInputElement>();

    componentDidMount() {
        this.injectedColumns = [];

        this.injectDatePickers();
        this.injectDateFormats();
        this.injectDropdownFilters();
        this.injectCenterFormats();
        this.fetchData();
        if (typeof this.props.getTableFetchHook === 'function') this.props.getTableFetchHook(this.fetchData);
        if (this.props.autorefresh)
            this.timer = setInterval(() => {
                this.fetchData();
            }, this.props.autorefreshDelay || 5000);
    }
    componentWillUnmount() {
        if (this.props.autorefresh) clearInterval(this.timer);
        this.setState({
            entries: [],
            filtered: [],
            date: {},
            dropdownFilter: {},
            uploading: false,
            expanded: {},
        });
        this.injectedColumns = [];
    }

    render() {
        const {
            defaultPageSize,
            showSizeOptions,
            loading,
            excel,
            pdf,
            propsToOwnTable,
            createHook,
            defaultSorted,
            options,
            additionalButtons,
            autorefresh,
            noPaginationComponent,
            upload,
        } = this.props;
        const {filtered, entries, uploading, expanded} = this.state;
        return (
            <WrapFullContainerNowrap>
                <OCard width={'100%'} height={'100%'} contentHeight={'100%'} contentPadding={0}>
                    <Typography component="div" style={{height: '100%', margin: 'auto'}}>
                        {options && (
                            <OptionsDiv>
                                {!autorefresh && (
                                    <Tooltip title="Recargar" placement="bottom">
                                        <Button
                                            color="primary"
                                            style={{zIndex: 20, cursor: 'pointer', padding: 0}}
                                            onClick={() => {
                                                notify({
                                                    message: 'Se estan actualizado los datos',
                                                    status: 'notify',
                                                    timeout: 2000,
                                                });
                                                this.fetchData();
                                            }}
                                        >
                                            <Refresh />
                                        </Button>
                                    </Tooltip>
                                )}
                                <Tooltip title="Limpiar filtros" placement="bottom">
                                    <Button
                                        color="secondary"
                                        style={{zIndex: 20, cursor: 'pointer', padding: 0}}
                                        onClick={() => this.setState(() => ({filtered: [], date: {}}), this.fetchData)}
                                    >
                                        <ClearAll />
                                    </Button>
                                </Tooltip>
                                {excel && (
                                    <Tooltip title="Descargar Excel" placement="bottom">
                                        <Button
                                            color="secondary"
                                            style={{zIndex: 20, cursor: 'pointer', padding: 0}}
                                            onClick={() => (this.serviceUrl ? window.open(this.serviceUrl + '/excel') : undefined)}
                                        >
                                            <GetApp />
                                        </Button>
                                    </Tooltip>
                                )}
                                {pdf && (
                                    <Tooltip title="Descargar PDF" placement="bottom">
                                        <Button
                                            color="secondary"
                                            style={{zIndex: 20, cursor: 'pointer', padding: 0}}
                                            onClick={() => (this.serviceUrl ? window.open(this.serviceUrl + '/pdf') : undefined)}
                                        >
                                            <PictureAsPdfIcon />
                                        </Button>
                                    </Tooltip>
                                )}
                                {upload && (
                                    <>
                                        <Tooltip title="Subir Excel con datos" placement="bottom">
                                            <Button color="secondary" style={{padding: 0}}>
                                                {!uploading ? (
                                                    <label
                                                        htmlFor="excelInput"
                                                        style={{
                                                            display: 'flex',
                                                            zIndex: 20,
                                                            cursor: 'pointer',
                                                            padding: 0,
                                                            margin: 0,
                                                            height: '100%',
                                                            width: '100%',
                                                        }}
                                                    >
                                                        <CloudUpload style={{margin: 'auto'}} />
                                                    </label>
                                                ) : (
                                                    <div style={{display: 'flex'}}>
                                                        <CircularProgress color="secondary" style={{height: 24, width: 24, margin: 'auto'}} />
                                                    </div>
                                                )}
                                            </Button>
                                        </Tooltip>
                                        <input
                                            style={{visibility: 'hidden', width: 0}}
                                            ref={this.inputRef}
                                            name="excel"
                                            id="excelInput"
                                            type="file"
                                            onChange={async e => {
                                                e.preventDefault();
                                                const etf = e.target.files;
                                                if (etf !== null && etf.length) {
                                                    this.setState({uploading: true});
                                                    const data = new FormData();
                                                    data.append('file', etf[0], etf[0].name);
                                                    try {
                                                        await axios.post(this.serviceUrl + '/upload-excel', data, {
                                                            onUploadProgress: ProgressEvent => {
                                                                if (ProgressEvent.loaded === ProgressEvent.total) {
                                                                    notify({
                                                                        message: 'Se ha subido su archivo',
                                                                        status: 'success',
                                                                    });
                                                                    this.setState({uploading: false});
                                                                }
                                                            },
                                                        });
                                                    } catch (error) {
                                                        console.error(error);
                                                        this.setState({uploading: false});
                                                        notify({
                                                            message: 'Ha surgido un error con la subida de su archivo',
                                                            status: 'error',
                                                        });
                                                    }
                                                }
                                            }}
                                        />
                                    </>
                                )}
                                {typeof createHook === 'function' && (
                                    <Tooltip title="Crear nuevo" placement="bottom">
                                        <Button color="secondary" style={{zIndex: 20, cursor: 'pointer', padding: 0}} onClick={createHook}>
                                            <PlaylistAdd />
                                        </Button>
                                    </Tooltip>
                                )}
                                <div style={{flexGrow: 1}} />
                                {invoke(additionalButtons, 'map', (data: {icon: JSX.Element; tooltipText: string; onClick(): void}, index: number) => (
                                    <Tooltip title={data.tooltipText} placement="bottom">
                                        <Button color="secondary" key={index} style={{zIndex: 20, cursor: 'pointer', padding: 0}} onClick={data.onClick}>
                                            {data.icon}
                                        </Button>
                                    </Tooltip>
                                ))}
                            </OptionsDiv>
                        )}
                        <ReactTable
                            filtered={filtered}
                            style={{
                                height: `calc(100% - ${options ? '36px' : '0px'})`,
                                width: '100%',
                                margin: 'auto',
                            }}
                            defaultFilterMethod={(filter, row) => {
                                const id = filter.pivotId || filter.id;
                                return row[id] !== undefined
                                    ? !!String(row[id])
                                          .toLowerCase()
                                          .match(('' + filter.value).toLowerCase())
                                    : true;
                            }}
                            onFilteredChange={filter => this.setState({filtered: filter})}
                            data={entries || []}
                            resizable={false}
                            sortable={true}
                            // minRows={defaultPageSize || 20}
                            className="-striped -highlight"
                            columns={this.injectedColumns.length ? this.injectedColumns : []}
                            previousText={'Anterior'}
                            nextText={'Siguiente'}
                            loadingText={'Obteniendo datos...'}
                            noDataText={'No hay entradas'}
                            pageText={'Página'}
                            ofText={'de'}
                            rowsText={'líneas'}
                            defaultSorted={defaultSorted}
                            showPageSizeOptions={showSizeOptions}
                            defaultPageSize={defaultPageSize || 20}
                            pageSizeOptions={this.addDefaultPagesize()}
                            loading={loading && entries.length < 1}
                            PaginationComponent={noPaginationComponent ? () => null : undefined}
                            getTheadFilterThProps={() => {
                                return {
                                    style: {
                                        overflow: 'inherit',
                                    },
                                };
                            }}
                            expanded={expanded}
                            onExpandedChange={(newExpanded, index) => {
                                if (newExpanded[index[0]] === false) {
                                    newExpanded = {};
                                } else {
                                    Object.keys(newExpanded).forEach(k => {
                                        newExpanded[k] = +k === index[0] ? {} : false;
                                    });
                                }
                                this.setState({expanded: newExpanded});
                            }}
                            {...propsToOwnTable}
                        />
                    </Typography>
                </OCard>
            </WrapFullContainerNowrap>
        );
    }

    private injectCenterFormats() {
        if (!this.props.columnFormat) return;
        const columns = this.injectedColumns.length ? this.injectedColumns : this.props.columnFormat;
        this.injectedColumns = columns.map(ic => {
            if (ic.centerCell) ic.style = {textAlign: 'center', whiteSpace: 'normal', alignSelf: 'center'};
            ic.headerStyle = {textTransform: 'uppercase'};
            return ic;
        });
    }

    private addDefaultPagesize = () => {
        const arr = [5, 10, 20, 25, 50, 100];
        if (this.props.defaultPageSize) {
            const exist = arr.find(it => it === this.props.defaultPageSize);
            if (exist) return arr;
            const index = arr.findIndex(it => it > this.props.defaultPageSize);
            if (index > -1) arr.splice(index, 0, this.props.defaultPageSize);
        }
        return arr;
    };

    private fetchData = async () => {
        const {customFilterFunction, serviceArgs} = this.props;
        const res = await fetchHelperNotPaginated(this.props.service(serviceArgs), {
            returnUrl: this.props.excel || this.props.pdf,
            accessor: this.props.accessor,
        });
        if (res && (Array.isArray(res) || Array.isArray(res.data))) {
            this.setState({
                entries: typeof customFilterFunction === 'function' ? customFilterFunction(res.data) : res.data,
            });
            this.serviceUrl = res.url;
            typeof this.props.dataHook === 'function' && this.props.dataHook(res.data);
        }
    };

    private injectDatePickers() {
        if (!this.props.columnFormat) return;
        this.injectedColumns = this.props.columnFormat.map(cf => {
            if (cf.date) {
                cf.filterable = true;
                cf.Filter = this.datePickerFilter(cf.accessor as string, DatePicker);
            }
            if (cf.daterange) {
                cf.filterable = true;
                cf.Filter = this.datePickerFilter(cf.accessor as string, DateRangePicker);
            }
            return cf;
        });
    }

    private injectDropdownFilters() {
        if (!this.props.columnFormat) return;
        const columns = this.injectedColumns.length ? this.injectedColumns : this.props.columnFormat;
        this.injectedColumns = columns.map(ic => {
            if (ic.dropdownFilter) {
                ic.filterable = true;
                ic.Filter = this.dropdownFilter(ic.accessor as string, ic.dropdownFilterData || [{id: '1', name: 'SE_NECESITAN_DATOS_PARA_EL_COMPONENTE'}]);
                this.setState({
                    dropdownFilter: {
                        ...this.state.dropdownFilter,
                        [ic.accessor as string]: ic.dropdownFilterData ? '' : '1',
                    },
                });
            }
            return ic;
        });
    }

    private injectDateFormats() {
        if (!this.props.columnFormat) return;
        const columns = this.injectedColumns.length ? this.injectedColumns : this.props.columnFormat;
        this.injectedColumns = columns.map(ic => {
            if (ic.dateFormat)
                ic.Cell = item => (
                    <>
                        {item.original &&
                            item.original[ic.accessor as string] &&
                            format(item.original[ic.accessor as string], 'DD/MM/YYYY', {
                                locale: es,
                            })}
                    </>
                );
            if (ic.dateTimeFormat)
                ic.Cell = item => (
                    <>
                        {item.original &&
                            item.original[ic.accessor as string] &&
                            format(item.original[ic.accessor as string], 'DD/MM/YYYY HH:mm:ss', {
                                locale: es,
                            })}
                    </>
                );
            return ic;
        });
    }

    private datePickerFilter = (name: string, Picker: (props: DatePickerProps) => JSX.Element): FilterRender => () => {
        return (
            <StyledDatePicker>
                <Picker
                    value={this.state.date[name]}
                    onChange={d => {
                        let transformed = '';
                        const data = d !== null ? d : undefined;
                        this.setState({date: {...this.state.date, [name]: data}});
                        // we determine if the date is a range<Array> or a single date and transform for sending
                        if (data && !Array.isArray(data)) transformed = format(data, 'DD/MM/YYYY', {locale: es});
                        if (data && Array.isArray(data))
                            // we create the date range `${date}-${date}`
                            transformed = data.reduce((acc, curr) => (acc ? acc + '-' + format(curr, 'DD/MM/YYYY', {locale: es}) : format(curr, 'DD/MM/YYYY', {locale: es})), '');
                        const find = this.state.filtered.find(item => item.id === name);
                        let addedOrMutated: State['filtered'] = [];
                        if (data && find) {
                            addedOrMutated = this.state.filtered.map(f => {
                                if (f.id === name) f.value = transformed;
                                return f;
                            });
                        } else if (data && !find) {
                            addedOrMutated = this.state.filtered.concat({
                                id: name,
                                value: transformed,
                            });
                        } else {
                            const index = this.state.filtered.findIndex(item => item.id === name);
                            if (index > -1) {
                                addedOrMutated = this.state.filtered;
                                addedOrMutated.splice(index, 1);
                            }
                        }
                        this.setState({filtered: addedOrMutated});
                        this.fetchData();
                    }}
                />
            </StyledDatePicker>
        );
    };

    private dropdownFilter = (name: string, values: Array<{id: string | boolean; name: string; [k: string]: any}>): FilterRender => () => {
        return (
            <DropdownForTable
                data={values}
                nullEntry={true}
                value={this.state.dropdownFilter[name] || ''}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    const {value} = e.target;
                    const indexOf = this.state.filtered.findIndex(f => f.id === name);
                    let modified = this.state.filtered;
                    const extractFromValues = values.find(it => it.id === value);
                    if (indexOf > -1)
                        modified.splice(indexOf, 1, {
                            id: name,
                            value,
                        });
                    else if (indexOf < 0 && extractFromValues)
                        modified.push({
                            id: name,
                            value: extractFromValues.id,
                        });
                    if (!value) modified = modified.filter(it => it.id !== name);
                    this.setState({
                        dropdownFilter: {...this.state.dropdownFilter, [name]: value},
                        filtered: modified,
                    });
                    this.fetchData();
                }}
            />
        );
    };
}

export const TableComponent = connect(null, dispatch => ({dispatch}))(Table);

const OptionsDiv = styled.div`
    height: 36px;
    flex-direction: row-reverse;
    display: flex;
`;

const DropdownForTable = withStyles({
    select: {
        padding: '2px 32px 1px 6px',
    },
    root: {
        height: 28,
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
    },
})((props: {data: any[]} & {[k: string]: any}) => {
    const unique = `dropdown-${!!props.title ? props.title.toLowerCase() : Math.ceil(Math.random() * 10000)}`;
    return (
        // <WrapCustomDropdown width={props.containerWidth} margin={props.margin}>
        <>
            <InputLabel htmlFor={unique}>{props.title}</InputLabel>
            <Select
                className={props.classes.root}
                classes={{select: props.classes.select}}
                value={props.value}
                onChange={props.onChange}
                error={!!props.error}
                style={{height: props.height}}
                input={
                    <FilledInput
                        // style={{padding: '20px 32px 12px 12px'}}
                        name={props.dropdownName}
                        value={props.value}
                        id={unique}
                    />
                }
            >
                {props.nullEntry && (
                    <MenuItem value={undefined}>
                        <em />
                    </MenuItem>
                )}
                {props.data &&
                    props.data.map((item, i) => (
                        <MenuItem key={i} value={item.id}>
                            {item.name}
                        </MenuItem>
                    ))}
            </Select>
        </>
    );
});
