import * as React from 'react';
//import {CustomDialog} from 'src/shared/components/CustomDialog';
import {TableModal} from 'src/shared/components/Modal';
import {WrapCreateButton, CustomButton, CustomTextField} from 'src/shared/style';
import styled from 'styled-components';
//import {CustomTextField} from 'src/app/lanemanager/export/components/styled';
import {DumpsServices} from './store/services';
import {notify} from 'src/utils/Notification';

type ModifyProps = {
    open: boolean;
    idDumpToModify: string;
    currentNumBoxes: number;
    barcode: string;
    handleClose: () => void;
    handleRefresh: () => void;
};

const initialState = {
    numBoxes: 100,
};

type State = Readonly<{
    numBoxes: number;
}>;

export class ModifyDumpModal extends React.Component<ModifyProps, State> {
    readonly state = initialState;

    componentWillReceiveProps(nextProps: any) {
        this.setState({numBoxes: nextProps.currentNumBoxes});
    }

    private modifyDump = (idDumpToModify: string) => {
        DumpsServices.modifyDump(idDumpToModify, this.state.numBoxes).then(res => {
            if (res.status === 409 && res.data.message === 'BOXES_INTRODUCED_IS_SUPERIOR_THAN_CURRENT_DUMPED_BOXES') {
                notify({message: 'El nuevo número de cajas debe ser inferior al que tenía el volcado.', status: 'error'});
            } else if (res.status === 404) {
                notify({message: 'Ha ocurrido un error.', status: 'error'});
            }
            this.props.handleRefresh();
        });
    };

    private handleChangeBoxes = (e: React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement | HTMLInputElement>) => {
        e.persist();
        this.setState(() => ({numBoxes: e.target.value} as any));
    };

    public render() {
        return (
            <>
                <TableModal title={'Modificación cajas en volcado'} open={this.props.open} handleClose={() => this.props.handleClose()}>
                    <BoxField>
                        {'Código de barras de la UL: '} {this.props.barcode}
                    </BoxField>
                    <BoxField>
                        {'Cajas actuales: '} {this.props.currentNumBoxes}
                    </BoxField>
                    <BoxField>
                        <CustomTextField
                            margin={'0 auto'}
                            width={'100%'}
                            label="Número cajas"
                            error={false}
                            value={this.state.numBoxes}
                            name="boxes_count"
                            variant={'outlined'}
                            type="number"
                            onChange={this.handleChangeBoxes}
                        />
                        {/* <BoxFieldCell>{this.props.currentNumBoxes}</BoxFieldCell> */}
                    </BoxField>
                    <WrapCreateButton>
                        <CustomButton
                            bgcolor={'#00D4AE'}
                            margin={'auto'}
                            onClick={() => {
                                this.modifyDump(this.props.idDumpToModify);
                                this.props.handleClose();
                            }}
                        >
                            Modificar número de cajas
                        </CustomButton>
                    </WrapCreateButton>
                </TableModal>
            </>
        );
    }
}

const BoxField = styled.div`
    width: 100%;
    @media (max-width: 1366px) {
        height: 39px;
    }
    height: 59px;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    display: table;
`;

// const BoxFieldCell = styled.div`
//     text-align: center;
//     display: table-cell;
//     vertical-align: middle;
//     font-size: 2rem;
//     @media (max-width: 1366px) {
//         font-size: 1.35rem;
//     }
//     font-weight: 500;
// `;
