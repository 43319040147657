import format from 'date-fns/format';
import es from 'date-fns/locale/es';
import React from 'react';
import clsx from 'clsx';
import {connect, MapDispatchToProps, MapStateToProps} from 'react-redux';
import {Action} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import {LaneSelectSelectors} from 'src/app/laneSelect/store/laneSelect.selectors';
import {LoginSelectors} from 'src/app/login/store/login.selectors';
import {AreaShiftServices} from 'src/app/lanemanager/areashift/store/areashift.services';
import {AreaShiftUnit} from 'src/app/lanemanager/areashift/store/areashift.types';
import {LaneDumpActions} from 'src/app/lanemanager/lane/store/actions';
import {AreaSelectors} from 'src/app/lanemanager/lane/store/selectors';
import {currentDumpInstance} from 'src/app/lanemanager/lane/store/storage';
import {Dump} from 'src/app/lanemanager/lane/store/types';
import {Button, Tooltip, Typography, List, ListItemText, ListItem, DialogActions, DialogContent} from '@material-ui/core';

import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import {createStyles, MuiThemeProvider, withStyles, createMuiTheme} from '@material-ui/core/styles';
import {red, green} from '@material-ui/core/colors';
import CircularProgress from '@material-ui/core/CircularProgress';

import {IsInRuleset} from 'src/global/authorization/grantSet';
import {AppStoreState} from 'src/store/interface';
import {fetchWithFeedback} from 'src/utils/fetcherValidate';
import {getObjectFromStorage} from 'src/utils/localforage';

import {DetailedInfo} from './DetailedInfo';
import {Wrap, WrapDumpData, WrapDumpID} from './Wrap';
import {BatchServices} from 'src/app/lanemanager/lane/store/services';
import {notify} from 'src/utils/Notification';
import {getCurrentDump} from 'src/app/lanemanager/lane/store/thunks';

import {DowntimeServices} from 'src/app/lanemanager/downtime/store/services';
import {LaneDowntime} from 'src/app/lanemanager/downtime/store/types';
import {CustomDialog} from 'src/shared/components/CustomDialog';
//import throttle from 'lodash/throttle';
import {ParametersSelectors} from 'src/utils/Parameters/selectors';
import {customFormatNumber} from 'src/shared/functions/FormatHelpers';

const styles = () =>
    createStyles({
        root: {
            display: 'flex',
            alignItems: 'center',
        },
        wrapper: {
            //margin: theme.spacing(1),
            position: 'relative',
        },
        buttonSuccess: {
            backgroundColor: green[500],
            '&:hover': {
                backgroundColor: green[700],
            },
        },
        fabProgress: {
            color: green[500],
            position: 'absolute',
            top: -6,
            left: -6,
            zIndex: 1,
        },
        buttonProgress: {
            color: green[500],
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
        },
    });

/**** COMPONENT */

type StyledProps = {
    classes: {[k: string]: string};
};

export type SProps = {
    fetchState: ReturnType<typeof AreaSelectors.getCurrentDumpFetchState>;
    employeesNum: number;
    lane: ReturnType<typeof LaneSelectSelectors.getLane>;
    userData: ReturnType<typeof LoginSelectors.getUserData>;
    nonProductiveTimeManagement: ReturnType<typeof ParametersSelectors.getNonProductiveTimeManagement>;
};

type DProps = {
    setWaiting: () => void;
    getCurrentDump: () => void;
};

type State = {
    openModal: boolean;
    loading: boolean;
    loadingAreashift: boolean;
    success: boolean;
    dumpData: Dump;
    currentAreaShift?: AreaShiftUnit;
    validAreashiftOperation: boolean;
    currentDowntime?: LaneDowntime;
    validBreakTime: boolean;
    openAlert: boolean;
    isCreatingDowntime: boolean;
    isStoppingDowntime: boolean;
    showActiveTablesAlert: boolean;
    currentLaneId: string; 
};

const initialState: State = {
    openModal: false,
    loading: false,
    loadingAreashift: false,
    success: false,
    dumpData: {} as Dump,
    validAreashiftOperation: false,
    validBreakTime: false,
    openAlert: false,
    isCreatingDowntime: false,
    isStoppingDowntime: false,
    showActiveTablesAlert: false,
    currentLaneId: ''
};

export class DumpInfo extends React.Component<StyledProps & SProps & DProps, State> {
    //classes = useStyles(this);
    //private throttled = throttle((fn: () => any) => fn(), 3000);

    buttonClassname = clsx({
        //[classes.buttonSuccess]: this.state.success,
    });
    readonly state = initialState;

    private timer: any;

    private themeButtom = createMuiTheme({
        palette: {
            secondary: red,
            primary: green,
        },
    });

    componentDidMount() {
        this.fetchCurrent();
        this.timer = setInterval(() => {
            this.fetchCurrent();
        }, 15000);
    }

    componentDidUpdate() {
        if(this.state.currentLaneId !== this.props.lane.laneId){
            this.fetchCurrent();
        }
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    private fetchCurrent = async () => {
        if (this.props.lane) {
            if(this.state.currentLaneId !== this.props.lane.laneId){
                this.setState({loadingAreashift: true});
                this.setState({currentLaneId: this.props.lane.laneId});

            }
            const currentAreaShift = await fetchWithFeedback(AreaShiftServices.current(this.props.lane.laneId), {accessor: 'areaShift'});
            //if (currentAreaShift) {
                this.setState({currentAreaShift});
            //}
            this.setState({loadingAreashift: false});


            const currentDowntime = await fetchWithFeedback(DowntimeServices.current(this.props.lane.laneId));
            if (currentDowntime) {
                const breakdown: Date = new Date(currentDowntime.started_at);
                const alerta = this.isMoreThanMinutes(breakdown, 30);
                if (alerta && !this.state.openAlert) this.toggleAlert();
                this.setState({currentDowntime});
            } else {
                this.setState({currentDowntime: undefined, validBreakTime: false});
            }
        }
    };

    private isMoreThanMinutes(origin: Date, minutes: number): boolean {
        const timeToAlert: number = minutes * 60 * 1000;
        const now: Date = new Date();
        const diff = now.valueOf() - origin.valueOf();
        return diff < timeToAlert ? false : true;
    }

    private finishDump = async () => {
        if (!this.state.loading) {
            this.setState({loading: true});
            const {lane} = this.props;
            if (lane && lane.laneId) {
                await fetchWithFeedback(BatchServices.finishCurrentBachInArea(lane.laneId), {
                    showMessage: true,
                });
                this.toggleModal();
                this.props.getCurrentDump();
            } else {
                notify({
                    message: 'No puede acabar un volcado en un area que no existe.',
                    status: 'error',
                });
            }
            //quitar spinner
            this.setState({loading: false});
        }
    };

    private toggleModal = () => this.setState({openModal: !this.state.openModal});

    private finishShift = async () => {
        const {currentAreaShift} = this.state;

        if (currentAreaShift) {
            const res = await fetchWithFeedback(AreaShiftServices.finish(currentAreaShift.id));
            if (res) {
                this.setState({currentAreaShift: undefined, validAreashiftOperation: false});
            }
        }
    };

    private preFinishShift = async () => {
        const {currentAreaShift, validAreashiftOperation} = this.state;

        if (currentAreaShift && !validAreashiftOperation) {
            this.setState({validAreashiftOperation: true});
        }
    };

    private checkActiveDump = () => {
        const {dumpData} = this.state;
        if (dumpData && dumpData.state && dumpData.state !== 'finished') {
            this.setState({
                showActiveTablesAlert: true,
            });
        } else {
            this.preFinishShift();
        }
    };

    private createShift = async () => {
        const {currentAreaShift, validAreashiftOperation} = this.state;
        const {lane, userData} = this.props;
        if (!currentAreaShift && lane && userData && validAreashiftOperation) {
            const res = await fetchWithFeedback(
                AreaShiftServices.create({
                    area_id: lane.laneId,
                    employee_id: '' + userData.employeeId,
                }),
            );
            if (res) this.fetchCurrent();
            this.setState({validAreashiftOperation: false});
        } else if (!currentAreaShift && lane && userData && !validAreashiftOperation) {
            this.setState({validAreashiftOperation: true});
        }
    };

    /*  botón desayuno  start */

    private toggleAlert = () => {
        this.setState({openAlert: !this.state.openAlert});
    };

    private finishBreakTimeFromAlert = () => {
        this.toggleAlert();
        this.setState({validBreakTime: true}, () => {
            this.finishBreakTime();
        });
    };

    private finishBreakTime = async () => {
        if (!this.state.isStoppingDowntime) {
            this.setState({isStoppingDowntime: true});
            const {currentDowntime, validBreakTime} = this.state;

            if (currentDowntime && validBreakTime) {
                const res = await fetchWithFeedback(DowntimeServices.continue(this.props.lane.laneId, currentDowntime.id));
                if (res) {
                    this.setState({currentDowntime: undefined, validBreakTime: false});
                }
            } else if (currentDowntime && !validBreakTime) {
                this.setState({validBreakTime: true});
            }
            this.setState({isStoppingDowntime: false});
        }
    };

    private startBreakTime = async () => {
        if (!this.state.isCreatingDowntime) {
            this.setState({isCreatingDowntime: true});
            const {currentDowntime, validBreakTime} = this.state;
            const {lane, userData} = this.props;
            if (!currentDowntime && lane && userData && validBreakTime) {
                const res = await fetchWithFeedback(
                    DowntimeServices.newBreakDowntime(lane.laneId, {
                        cause_id: '',
                        description: '',
                        employee_id: userData.employeeId,
                    }),
                    {
                        showMessage: true,
                        successMessage: 'Se ha creado la parada',
                        errorMessage: 'Error al crear la parada. Revisar las condiciones.',
                    },
                );
                if (res) this.fetchCurrent();
                this.setState({validBreakTime: false});
            } else if (!currentDowntime && !validBreakTime) {
                this.setState({validBreakTime: true});
            }
            this.setState({isCreatingDowntime: false});
        }
    };

    /*  botón desayuno  end */

    private retrieveFromDB = async () => {
        const dumpData = await getObjectFromStorage<Dump>(currentDumpInstance);
        this.props.setWaiting();
        this.setState({dumpData});
        // this.forceUpdate();
    };

    render() {
        const TOTAL_ACCESS = IsInRuleset(['LANEMANAGER_COMMON_TOTAL', 'LANEMANAGER_STATE_TOTAL', 'LANEMANAGER_PRODPARTS_TOTAL']);

        const {classes, fetchState, employeesNum} = this.props;
        const {dumpData, currentAreaShift, validAreashiftOperation, validBreakTime, currentDowntime} = this.state;
        if (!dumpData && (fetchState === 'waiting' || fetchState === 'fail')) {
            return <Wrap>No hay volcado actual...</Wrap>;
        }
        
        //const dateString = '2020-10-24 10:00:00'
        //const dateTest = format(new Date(dateString), 'HH:mm:ss');

        fetchState !== 'waiting' && this.retrieveFromDB();
        if (dumpData) {
            return (
                <>
                    {this.state.showActiveTablesAlert && (
                        <CustomDialog
                            open={this.state.showActiveTablesAlert}
                            title="Confirmación"
                            contentText={'Hay kilogramos volcados sin paletizar. ¿Desea cerrar el turno?'}
                            handle={() => {
                                this.finishShift();
                                this.setState({showActiveTablesAlert: false});
                            }}
                            handleClose={() => {
                                this.setState({showActiveTablesAlert: false});
                            }}
                        />
                    )}

                    <Wrap>
                        <WrapDumpID>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '33%',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography component="span" style={{textTransform: 'uppercase', fontWeight: 500}}>
                                    Empleados
                                </Typography>
                                <Typography component="span" style={{fontSize: 18, textTransform: 'uppercase', fontWeight: 500}}>
                                    {customFormatNumber(employeesNum) || ' '}
                                </Typography>
                            </div>
                            <Button
                                color={'primary'}
                                variant="contained"
                                disabled={this.state.loadingAreashift}
                                onClick={TOTAL_ACCESS ? () => this.toggleModal() : undefined}
                                style={{
                                    width: '35%',
                                    marginLeft: 5,
                                    height: 50,
                                    marginTop: 'auto',
                                    marginBottom: 4,
                                    padding: 3,
                                }}
                            >
                                <span style={{fontSize: 13, lineHeight: '16px'}}>Finalizar origen</span>
                            </Button>
                            {/*  botón desayuno  start */}
                            <Tooltip title={TOTAL_ACCESS ? ' Control parada/desayuno' : undefined} placement="top">
                                <MuiThemeProvider theme={this.themeButtom}>
                                    <Button
                                        color={!validBreakTime ? (currentDowntime ? 'secondary' : 'primary') : undefined}
                                        disabled={this.props.nonProductiveTimeManagement === '1' || this.state.loadingAreashift}
                                        variant="contained"
                                        onClick={
                                            TOTAL_ACCESS
                                                ? currentDowntime
                                                    ? () => this.finishBreakTime()
                                                    : () => {
                                                          this.startBreakTime();
                                                      }
                                                : undefined
                                        }
                                        style={{
                                            width: '30%',
                                            marginLeft: 5,
                                            marginRight: -3,
                                            height: 50,
                                            marginTop: 'auto',
                                            marginBottom: 4,
                                            padding: 3,
                                            backgroundColor: validBreakTime ? '#FFBF00' : undefined,
                                        }}
                                    >
                                        {!validBreakTime && !this.state.isCreatingDowntime && !this.state.isStoppingDowntime ? (
                                            currentDowntime ? (
                                                <div
                                                    style={{
                                                        margin: 0,
                                                        padding: 0,
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                    }}
                                                >
                                                    {TOTAL_ACCESS && 'Finalizar'} parada
                                                </div>
                                            ) : (
                                                <div
                                                    style={{
                                                        margin: 0,
                                                        padding: 0,
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                    }}
                                                >
                                                    <span>Iniciar descanso</span>
                                                </div>
                                            )
                                        ) : this.state.isCreatingDowntime || this.state.isStoppingDowntime ? (
                                            <CircularProgress color="secondary" style={{height: 24, width: 24}} />
                                        ) : (
                                            <span>Realizar la operación?</span>
                                        )}
                                    </Button>
                                </MuiThemeProvider>
                            </Tooltip>
                            {/*  botón desayuno  end */}

                            <Tooltip title={TOTAL_ACCESS ? (currentAreaShift ? 'Finalizar el turno' : 'Iniciar nuevo turno') : 'Información'} placement="top">
                                <MuiThemeProvider theme={this.themeButtom}>
                                    <Button
                                        color={currentAreaShift ? 'secondary' : 'primary'}
                                        variant="contained"
                                        disabled={this.state.loadingAreashift}
                                        onClick={
                                            TOTAL_ACCESS ? (currentAreaShift ? (!validAreashiftOperation ? this.checkActiveDump : this.finishShift) : this.createShift) : undefined
                                        }
                                        style={{
                                            width: '35%',
                                            marginLeft: 5,
                                            marginRight: -3,
                                            height: 50,
                                            marginTop: 'auto',
                                            marginBottom: 4,
                                            padding: 3,
                                        }}
                                    >
                                        {!validAreashiftOperation ? (
                                            currentAreaShift ? (
                                                <div
                                                    style={{
                                                        margin: 0,
                                                        padding: 0,
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                    }}
                                                >
                                                    {TOTAL_ACCESS && 'Fin'} turno
                                                    <span style={{fontSize: 12}}>
                                                        {format(new Date(currentAreaShift.start_date), 'HH:mm:ss', {
                                                            locale: es,
                                                        })}
                                                    </span>
                                                </div>
                                            ) : (
                                                <div
                                                    style={{
                                                        margin: 0,
                                                        padding: 0,
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                    }}
                                                >
                                                    <span>Iniciar turno</span>
                                                    <span style={{fontSize: 10}}>(no hay activo)</span>{' '}
                                                </div>
                                            )
                                        ) : (
                                            <span>Realizar la operación?</span>
                                        )}
                                    </Button>
                                </MuiThemeProvider>
                            </Tooltip>
                        </WrapDumpID>
                        <WrapDumpData>
                            <DetailedInfo dumpData={dumpData} />
                        </WrapDumpData>
                    </Wrap>
                    <Dialog open={this.state.openModal} onClose={this.toggleModal} aria-labelledby="simple-dialog-title">
                        <DialogTitle id="simple-dialog-title">Origen</DialogTitle>
                        <DialogContent>
                            {this.props.lane.AutoPalletise && <Typography variant="body1">Origen con autopaletizado. Compruebe antes de finalizar</Typography>}
                            <div>
                                <List>
                                    {this.state.dumpData && this.state.dumpData.batches && this.state.dumpData.batches.length ? (
                                        this.state.dumpData.batches.map(batch => (
                                            <ListItem key={batch.erp_batch_code}>
                                                <ListItemText primary={batch.erp_batch_code} />
                                            </ListItem>
                                        ))
                                    ) : (
                                        <Typography variant="body1">No hay origenes actuales.</Typography>
                                    )}
                                </List>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <div className={classes.wrapper}>
                                <Button color="primary" variant="contained" disabled={this.state.loading} onClick={() => this.finishDump()}>
                                    Finalizar origen.
                                </Button>
                                {this.state.loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                            </div>
                        </DialogActions>
                    </Dialog>

                    <CustomDialog
                        open={this.state.openAlert}
                        title="Alerta"
                        contentText="Lleva más de 30 minutos de pausa, ¿desea finalizar la pausa?"
                        handle={() => this.finishBreakTimeFromAlert()}
                        handleClose={() => this.toggleAlert()}
                    />
                </>
            );
        } else return null;
    }
}

const s2p: MapStateToProps<SProps, any, AppStoreState> = state => ({
    fetchState: AreaSelectors.getCurrentDumpFetchState(state),
    employeesNum: AreaSelectors.getEmployeesNumber(state),
    lane: LaneSelectSelectors.getLane(state),
    userData: LoginSelectors.getUserData(state),
    nonProductiveTimeManagement: ParametersSelectors.getNonProductiveTimeManagement(state),
});

const d2p: MapDispatchToProps<DProps, any> = (dispatch: ThunkDispatch<AppStoreState, any, Action>) => ({
    getCurrentDump: () => dispatch(getCurrentDump()),

    setWaiting: () => dispatch(LaneDumpActions.waiting()),
});

export const DumpInfoConnected = connect(s2p, d2p)(withStyles(styles)(DumpInfo));
