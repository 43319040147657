import * as React from 'react';

// ! FIXME: unify all styled components!
import {CustomButton} from 'src/shared/style';
import {WrapCreateButton} from 'src/shared/style/Wrap';
import {notify} from 'src/utils/Notification';
import {BarcodeScanner} from "../../../shared/components/BarcodeScanner";
import {TextField, Typography} from "@material-ui/core";
// import {findSystemParameter} from "../../../global/systemParameters/systemParameters";
// import {ParameterCPN} from "../../../types/app_types";
// import {validateBarcode} from "../../../shared/functions/BarcodeValidator";
import {Advertisement} from "../../../shared/components/Advertisement";
import {NotifyActionType} from "../../../utils/Notification/action-types";
import { TableModal } from 'src/shared/components/Modal';
import { PostDumpsForm } from './PostDumpsForm';
import { IsInRuleset } from 'src/global/authorization/grantSet';

type DumpCreateProps = {
    handleCreate: (barcodeLogisticunit: string) => void;
    handleCreateLater: (
        originCode : string,
        boxCount : number,
        workshiftDate : string,
        workshiftType : string) => void;
};

const initialState = {
    barcodeLogisticunit: '',
    openBarcodeScanner: false,
    showAd: false,
    enableButton: true,
    openPostDumpForm: false
};

type State = Readonly<{
    barcodeLogisticunit: string;
    openBarcodeScanner: boolean;
    showAd: boolean;
    enableButton: boolean;
    openPostDumpForm: boolean;
}>;

export class DumpCreate extends React.Component<DumpCreateProps, State> {
    readonly state = initialState;

    shouldComponentUpdate() {
        return true;
    }

    //Éscaner de códigos de barras
    private barcodeScanner = React.createRef<BarcodeScanner>();
    private adMessage = '';

    private showAd = (message: string) => {
        this.adMessage = message;
        this.setState({
            showAd: true,
        });
    };

    private hideAd = () => {
        this.setState({
            showAd: false,
        });
    };

    private checkBarcode = () => {
        // const code = this.state.barcodeLogisticunit;
        // let isValid = false;

        // if (code.length === 13) {
        //     //const barcodePrefixes = [`${findSystemParameter(ParameterCPN.PALLET_BARCODE_PREFIX)?.value || ''}`,`${findSystemParameter(ParameterCPN.LOGISTIC_UNIT_BARCODE_PREFIX)?.value || ''}`];
        //     isValid = validateBarcode(code);
        //     // const isValid = barcodePrefixes ? true : false;
        //
        //     if (!isValid) {
        //         this.showAd('El código de barras introducido no tiene el formato correcto');
        //     }
        // }
        // this.setState({enableButton: isValid});
    }

    private handleChangeBarcodePallet = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (e.target.name === 'barcode_ul') {
            const newValue = (e.target.value as unknown) as string;

            // if (newValue.length > 13) return;

            this.setState({
                barcodeLogisticunit: newValue,
            }, this.checkBarcode);
        }
    };

    handleCodeReaded = (code: string) => {
        this.setState({
            barcodeLogisticunit: code,
        }, this.checkBarcode);
    };

    scanBarcode = () => {
        if (navigator.mediaDevices) {
            if (this.barcodeScanner.current) {
                this.barcodeScanner.current.init();
                this.setState({openBarcodeScanner: true});
            }
        } else {
            this.showAd('No se puede acceder a la cámara. Se necesita entrar por https y permitir el acceso a la cámara. Gracias.');
        }
    };

    private hideBarcodeScanner = () => {
        this.setState({
            openBarcodeScanner: false,
        });
    };

    public render() {
        return (
            <>
                <div style={{paddingTop: '10px'}}>
                    <TextField
                        fullWidth={true}
                        label="Código"
                        error={false}
                        value={this.state.barcodeLogisticunit}
                        name="barcode_ul"
                        variant={'outlined'}
                        type="string"
                        onChange={this.handleChangeBarcodePallet}
                        onKeyPress={ev => {
                            if (this.state.enableButton && ev.key === 'Enter') {
                                this.handleCreateDump();
                                ev.preventDefault();
                            }
                        }}
                        autoFocus={true}
                    />
                </div>

                <Typography
                    component="div"
                    style={{
                        marginTop: 10,
                    }}
                >
                    <>
                        <CustomButton onClick={this.scanBarcode} padding={'3px'}>
                            {'Escanear código'}
                        </CustomButton>
                        <br />
                    </>
                </Typography>

                <BarcodeScanner open={this.state.openBarcodeScanner} hide={this.hideBarcodeScanner} onReadedCode={this.handleCodeReaded} ref={this.barcodeScanner} />
                <Advertisement open={this.state.showAd} hide={this.hideAd} message={this.adMessage} type={NotifyActionType.error} />

                <WrapCreateButton>
                    <CustomButton
                        bgcolor={'#00D4AE'}
                        margin={'auto'}
                        disabled={!this.state.enableButton}
                        onClick={() => this.handleCreateDump()}>
                        Crear el volcado
                    </CustomButton>
                </WrapCreateButton>

                {IsInRuleset(['CREATE_DUMPS_LATER']) && (
                    <>
                        <WrapCreateButton>
                            <CustomButton
                                bgcolor={'#00E848'}
                                margin={'auto'}
                                disabled={!this.state.enableButton}
                                onClick={() => this.handleOpenPostDump()}>
                                Volcado a posteriori
                            </CustomButton>
                        </WrapCreateButton>

                        <TableModal title={'Crear volcado a posteriori'} open={this.state.openPostDumpForm} handleClose={this.handleClosePostDump}>
                            <PostDumpsForm handleClose={this.handleClosePostDump} update={()=>{}} handleCreateLater={this.props.handleCreateLater} />
                        </TableModal>
                    </>
                )}



            </>
        );
    }

    private handleCreateDump = () => {
        const barcodeLogisticunit = this.state.barcodeLogisticunit;
        if (barcodeLogisticunit) {
            this.setState({enableButton: false});
            this.props.handleCreate(barcodeLogisticunit);
            // this.setState({barcodeLogisticunit: ''});
            // this.forceUpdate();
            setTimeout(() => {
                this.setState({enableButton: true});
            }, 2000)
        } else
            notify({
                message: 'No se puede crear el volcado con los campos en blanco',
                status: 'warning',
            });
    };

    private handleOpenPostDump = () => {
        this.setState({
            openPostDumpForm: true
        });
    }

    private handleClosePostDump = () => {
        this.setState({
            openPostDumpForm: false
        });
    }
}
