import * as React from 'react';
import {connect} from 'react-redux';

import {LanePtableState, LaneBatchesWithBoxesState} from 'src/app/lanemanager/lane/store/types';
import {AreaSelectors} from 'src/app/lanemanager/lane/store/selectors';
import {AppStoreState} from 'src/store/interface';

import {TableModal} from 'src/shared/components/Modal';
import {CustomButton, CustomTextField} from 'src/shared/style';
import {Typography} from '@material-ui/core';
import {getAreaAllPtables} from 'src/app/lanemanager/lane/store/thunks';
import {CustomDialog} from 'src/shared/components/CustomDialog';

export interface Props {
    ptables: LanePtableState[];
    ptablePrevius: LanePtableState | null;
    batchSelected: string | undefined;
    open: boolean;
    boxCount: number | undefined;
    hideTransferBoxes: () => void;
    sendTransferBoxes: (
        batchId: string | undefined,
        nextPalletId: string | undefined,
        previusPalletId: string | undefined,
        boxes: number | undefined,
    ) => void;
}

type State = {
    openAlert: boolean;
    pTablesSelectables: LanePtableState[] | undefined;
    ptableNextSelected: LanePtableState | undefined;
    newBoxCount?: number;
    boxesToTrans: number | string;
    batchWithBoxes?: LaneBatchesWithBoxesState[] | undefined;
};

const initialState: State = {
    openAlert: false,
    pTablesSelectables: undefined,
    ptableNextSelected: undefined,
    boxesToTrans: '',
};

export class TransferBoxes extends React.Component<Props, State> {
    readonly state = initialState;

    componentWillReceiveProps(nextProps: any) {
        let boxesToTrans;
        if (this.state.boxesToTrans === '') {
            boxesToTrans = nextProps.boxCount;
        }
        else if (this.state.boxesToTrans === -2) {
            this.setState({boxesToTrans: ''});
            return;
        }
        else {
            boxesToTrans = Math.min(this.state.boxesToTrans as number, nextProps.boxCount);
        }

        this.setState({newBoxCount: nextProps.boxCount, boxesToTrans: boxesToTrans});
    }

    private hideModalTable = () => {
        this.setState({boxesToTrans: -2}, this.props.hideTransferBoxes);
    };

    private toggleAlert = () => {
        this.setState({openAlert: !this.state.openAlert});
    };

    private getTextInConfirm() {
        const boxCount = this.state.boxesToTrans;
        let namePtableNext = '-';
        let namePtablePrevius = '-';

        if (this.state.ptableNextSelected) {
            namePtableNext = this.state.ptableNextSelected.name;
        }

        if (this.props.ptablePrevius) {
            namePtablePrevius = this.props.ptablePrevius.name;
        }

        return `¿Desea transferir ${boxCount} cajas de la mesa ${namePtablePrevius} a la mesa ${namePtableNext}?`;
    }

    private handleChangeBoxCount = (e: React.ChangeEvent<HTMLTextAreaElement>) => {

        if (e.target.name === 'boxes_count') {

            if (e.target.value === '') {
                this.setState({boxesToTrans: ''});
            }
            else {
                let newIntValue = parseInt(e.target.value);
                if (isNaN(newIntValue)) return;

                const actualValue = this.state.newBoxCount ?? 0;
                if ((newIntValue * 1) > (actualValue * 1)) {
                    newIntValue = actualValue;
                }
                if (newIntValue < 0) {
                    newIntValue = 0;
                }

                this.setState({boxesToTrans: newIntValue});
            }

        }
    };

    render() {
        const {ptables} = this.props;
        if (ptables === undefined || ptables.length === 0)
            return (
                <div style={{padding: 10, paddingLeft: 20, fontSize: 14}}>
                    No hay datos sobre las mesas...
                </div>
            );

        return (
            <>
                <TableModal
                    title={
                        (this.props.ptablePrevius && this.props.ptablePrevius.name) || 'Cerrando'
                    }
                    open={this.props.open}
                    handleClose={this.hideModalTable}>
                    <br />
                    <CustomTextField
                        margin={'0 auto'}
                        width={'100%'}
                        label="Número de cajas a transferir"
                        error={false}
                        value={this.state.boxesToTrans}
                        name="boxes_count"
                        variant={'outlined'}
                        type="number"
                        onChange={this.handleChangeBoxCount}
                    />
                    {(this.props.ptables &&
                        this.props.ptables.map(ptable => {
                            if (
                                ptable &&
                                ptable.pallet &&
                                this.props.ptablePrevius &&
                                ptable.ptable_id !== this.props.ptablePrevius.ptable_id
                            ) {
                                return (
                                    <Typography
                                        component="div"
                                        style={{
                                            marginTop: 10,
                                        }}>
                                        <>
                                            <CustomButton
                                                disabled={this.state.boxesToTrans === '' || this.state.boxesToTrans === 0}
                                                onClick={() => {
                                                    this.setState({ptableNextSelected: ptable});
                                                    this.toggleAlert();
                                                }}
                                                padding={'3px'}>
                                                {ptable.name}
                                            </CustomButton>
                                            <br />
                                        </>
                                    </Typography>
                                );
                            } else return undefined;
                        })) ||
                    'No tiene cajas de orígenes'}
                </TableModal>
                {this.state && this.state.ptableNextSelected && this.props.ptablePrevius && (
                    <CustomDialog
                        open={this.state.openAlert}
                        title="Alerta"
                        contentText={this.getTextInConfirm()}
                        handle={() =>
                            this.props.sendTransferBoxes(
                                this.props.batchSelected,
                                this.state.ptableNextSelected
                                    ? this.state.ptableNextSelected.ptable_id
                                    : undefined,
                                this.props.ptablePrevius ? this.props.ptablePrevius.ptable_id : undefined,
                                (this.state.boxesToTrans ?? 0) as number ,
                            )
                        }
                        handleClose={() => this.toggleAlert()}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = (state: AppStoreState) => ({
    ptables: AreaSelectors.getPtables(state),
    auto_palletise: !!state.laneSelect.AutoPalletise,
});

const mapDispatchToProps = (dispatch: any) => ({
    getPtables: () => dispatch(getAreaAllPtables()),
});

export const TransferBoxesConnected = connect(
    mapStateToProps,
    mapDispatchToProps,
)(TransferBoxes);
