import React from 'react';
import {
    Card,
    CardContent,
    CircularProgress,
    createStyles,
    Grid,
    List,
    //ListItemIcon, ListItemText,
    Tooltip,
    Typography,
    withStyles,
} from '@material-ui/core';
import AddButtom from '@material-ui/icons/AddCircle';
import ClearButton from '@material-ui/icons/ClearAll';
import IconButton from '@material-ui/core/IconButton';
import RemoveButton from '@material-ui/icons/Clear';
import {ListItem} from '@material-ui/core/es';
import {GetPalletModal} from './GetPalletModal';
import {fetchWithFeedback} from 'src/utils/fetcherValidate';
import {NotifyActionType} from 'src/utils/Notification/action-types';
import {Advertisement} from 'src/shared/components/Advertisement';
import {PalletInformationServices} from 'src/app/lanemanager/palletInformation/store/palletInformation.services';
import {PalletsServices} from 'src/app/lanemanager/pallets/store/services';
import {CustomButton} from 'src/shared/style';
import { TableModal } from 'src/shared/components/Modal';
import styled from 'styled-components';
import { ArrowDropUp , ArrowDropDown } from '@material-ui/icons';
import { TransferPalletType } from '../../store/types';

type Props = {
    palletsQueue: TransferPalletType[];
    currentPalletBarcode: string;
    ptableId: string;
    prodpartId: string | undefined;
    classes: any;
    open: boolean;
    handleClose: () => void;
};

type State = {
    palletsQueue: Map<number, TransferPalletType>;
    openGetPallet: boolean;
    showAd: boolean;
    isLoading: boolean;
    loaderMessage: string;
};

const initialState: State = {
    palletsQueue: new Map<number, TransferPalletType>(),
    openGetPallet: false,
    showAd: false,
    isLoading: false,
    loaderMessage: '',
};

export class EnqueuePalletsControlBase extends React.Component<Props, State> {
    readonly GET_PALLET_MSG = 'Obteniendo información del palé...';

    readonly state = initialState;
    private adMessage = '';


    componentDidMount() {
       this.getPalletQueueData();
    }

    getPalletQueueData() {
        const max = this.props.palletsQueue.length;
        for (let i=0; i<max; ++i) {
            this.state.palletsQueue.set(i, this.props.palletsQueue[i]);
        }
    }

    componentWillUnmount() {
        this.state.palletsQueue.clear();
        this.setState(initialState);
    }

    private setLoading = (msg: string) => {
        this.setState({
            isLoading: true,
            loaderMessage: msg,
        });
    };

    private unsetLoading = () => {
        this.setState({
            isLoading: false,
            loaderMessage: '',
        });
    };

    private handleOpenGetPallet = () => {
        this.setState({openGetPallet: true});
    };

    private handleCloseGetPallet = () => {
        this.setState({openGetPallet: false});
    };

    private handlePalletBarcodeReader = (barcode: string) => {
        this.addOriginPallet(barcode);
        this.handleCloseGetPallet();
    };

    private handleUpPallet = (position: number) => {
        if (position > 0) {
            const pPallet = this.state.palletsQueue.get(position - 1);
            const aPallet = this.state.palletsQueue.get(position);
            if (pPallet && aPallet) {
                this.state.palletsQueue.set(position - 1, aPallet);
                this.state.palletsQueue.set(position, pPallet);
            }
            this.forceUpdate();
        }
    };

    private handleDownPallet = (position: number) => {
        const topPosition = this.state.palletsQueue.size - 1;
        if (position < topPosition) {
            const tPallet = this.state.palletsQueue.get(position + 1);
            const aPallet = this.state.palletsQueue.get(position);
            if (tPallet && aPallet) {
                this.state.palletsQueue.set(position + 1, aPallet);
                this.state.palletsQueue.set(position, tPallet);
            }
            this.forceUpdate();
        }
    };

    private addOriginPallet = (barcode: string) => {

        const pallet = this.props.palletsQueue.find(p => p.barcode === barcode);
        if (pallet) {
            const sPallet = Array.from(this.state.palletsQueue.values()).find(p => p.barcode === barcode);

            if (sPallet) this.showAd('El palé ya está en la cola');
            else this.state.palletsQueue.set(this.state.palletsQueue.size, pallet);
        }
        else if (this.props.currentPalletBarcode === barcode) {
            this.showAd('El palé ya está vinculado a la mesa');
        }
        else {
            this.setLoading(this.GET_PALLET_MSG);

            PalletInformationServices.getSimpleInfo(barcode)
                .then(data => {
                    const palletInfo = data.data.pallet;
                    if (palletInfo) {
                        if (palletInfo.boxes_count >= palletInfo.boxes_total) {
                            this.showAd('No se pueden añadir más cajas al palé seleccionado');
                        } else if (this.props.prodpartId && palletInfo.prodpart_id !== this.props.prodpartId) {
                            this.showAd('El palé no pertenece al parte asignado a la mesa');
                        } else if (palletInfo.isEnqueued) {
                            this.showAd('El palé ya ha sido encolado');
                        } else {
                            const transferPallet: TransferPalletType = {
                                id: palletInfo.id,
                                barcode: palletInfo.barcode,
                                boxes_count: palletInfo.boxes_count,
                                boxes_total: palletInfo.boxes_total,
                            };
                            this.state.palletsQueue.set(this.state.palletsQueue.size, transferPallet);
                        }
                    } else {
                        this.showAd('No existe ningún palé con el código de barras introducido');
                    }
                    this.unsetLoading();
                })
                .catch(() => {
                    this.unsetLoading();
                    this.showAd('No se pudo obtener información del pallet');
                });
        }

    };

    private handleUnenqueuePallet = (position: number) => {
        const topPosition = this.state.palletsQueue.size - 1;
        this.state.palletsQueue.delete(position);

        console.log('P,T', position, topPosition);
        if (position < topPosition) {
            for (let i = position; i <= topPosition; ++i) {
                const dPallet = this.state.palletsQueue.get(i + 1);
                if(dPallet) this.state.palletsQueue.set(i, dPallet);
            }
            this.state.palletsQueue.delete(topPosition);
        }
        this.forceUpdate();
    };

    private handleClearPalletsQueue = () => {
        this.state.palletsQueue.clear();
        this.forceUpdate();
    };

    private showAd = (message: string) => {
        this.adMessage = message;
        this.setState({
            showAd: true,
        });
    };

    private hideAd = () => {
        this.setState({
            showAd: false,
        });
    };

    private handleSaveEnqueuedPallets = async() => {

        this.setLoading('Guardando cola de palés...');

        const pallets : Array<{
            id: string;
            position: number;
        }> = [];

        this.state.palletsQueue.forEach((value, key) => pallets.push({id:value.id, position:key}));

        const queue = {
            ptable_id : this.props.ptableId,
            pallets : pallets
        };

        await fetchWithFeedback(PalletsServices.enqueuePallets(queue), {
            returnConfirmation: true,
            errorMessage: 'No se han podido encolar los palés',
            successMessage: 'Los palés se han encolado correctamente',
        })
        .then(() => {
            this.props.palletsQueue.length = 0;
            this.state.palletsQueue.forEach((value, key) => this.props.palletsQueue[key] = value);
        })
        .finally(() => this.unsetLoading());
    };

    private handleClose = () => {
        this.getPalletQueueData();
        this.props.handleClose();
    };

    render() {
        const {classes} = this.props;
        const originPalletList = Array.from(this.state.palletsQueue);

        return (
            <>
                <TableModal open={this.props.open}
                            handleClose={this.handleClose}
                            fullWidth={true}
                            title='Cola de palés'
                            dialogClasses={{
                                paper: classes.fitH,
                            }}
                >
                    <WrapDiv>
                        <Grid container
                              alignItems="flex-start"
                              className={classes.fitH}>
                            <Grid item xs={1} />

                            <Grid item xs={10} className={classes.gridContainer}>
                                <Card className={classes.cardContainer}>

                                    <CardContent className={classes.cardContent}>
                                        <Typography variant="body1" className={classes.cardHeader}>
                                            Palés en cola: <b>{originPalletList.length}</b>
                                        </Typography>


                                        <Card className={classes.palletListContainer} >
                                            <List className={classes.palletList}>
                                                <>
                                                    {originPalletList.map(value => {
                                                        const position = value[0];
                                                        const pallet = value[1];
                                                        return <PalletElement
                                                                key={pallet.barcode}
                                                                pallet={pallet}
                                                                position={position}
                                                                handleUp={this.handleUpPallet}
                                                                handleDown={this.handleDownPallet}
                                                                handleRemove={this.handleUnenqueuePallet}
                                                        />;
                                                    })}
                                                </>
                                            </List>
                                        </Card>


                                        <Grid container justify="flex-end">
                                            <Grid item>
                                                <Tooltip title="Limpiar cola de palés">
                                                    <IconButton onClick={this.handleClearPalletsQueue}>
                                                        <ClearButton color="secondary" fontSize="large" />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item>
                                                <Tooltip title="Añadir palé a la cola">
                                                    <IconButton onClick={this.handleOpenGetPallet}>
                                                        <AddButtom color="primary" fontSize="large" />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>


                                    </CardContent>
                                </Card>
                            </Grid>

                            <Grid item xs={1} />
                        </Grid>

                        <CustomButton onClick={this.handleSaveEnqueuedPallets}
                                      width={'100%'}
                                      bgcolor="green"
                                      className={classes.saveButton}
                                      >
                            Guardar
                        </CustomButton>

                    </WrapDiv>
                </TableModal>

                <GetPalletModal open={this.state.openGetPallet} hide={this.handleCloseGetPallet} requestId="queue" handleBarcodeReaded={this.handlePalletBarcodeReader} />

                <Advertisement open={this.state.showAd} hide={this.hideAd} message={this.adMessage} type={NotifyActionType.error} />

                {this.state.isLoading && (
                    <div className={classes.loaderContainer}>
                        <CircularProgress className={classes.loader} />
                        <Typography>{this.state.loaderMessage}</Typography>
                    </div>
                )}
            </>
        );
    }
}

const WrapDiv = styled.div `
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

const styles = createStyles({
    fitH: {
        height: '100%',
    },
    gridContainer: {
        height: '95%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardContainer: {
        height: '100%!important',
    },
    cardContent: {
        height: '100%!important',
        display: 'flex',
        flexGrow: 0,
        flex: 'max-content',
        flexDirection: 'column',
        alignContent: 'flex-end',
        padding: 0,
    },
    cardHeader: {
        margin: '8px',
    },
    palletListContainer: {
        height: '100%',
        margin: '8px',
        backgroundColor: '#eeeeee',
        border: 'gray',
        flex: 'max-content',
        overflow: 'auto',
    },
    palletListElement: {
        padding: '4px',
        cursor: 'pointer',
    },
    loaderContainer: {
        position: 'fixed',
        backgroundColor: 'rgba(255,255,255,0.85)',
        top: 0,
        left:0,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        zIndex: 9999,
    },
    loader: {
        color: 'blue',
        margin: '16px',
    },
    saveButton: {
        flexGrow: 1
    },
    upDownIcon: {
        padding:0,
        margin:0
    }
});

type PEProps = {
    classes: any;
    pallet: TransferPalletType;
    position: number;
    handleRemove: (position: number) => void;
    handleUp: (position: number) => void;
    handleDown: (position: number) => void;
};

const PalletElement = withStyles(styles)((props: PEProps) => {
    const {classes} = props;

    return (
        <ListItem key={props.pallet.id} style={{paddingTop:0, paddingBottom:0}}>
            <Grid container spacing={8} alignItems="center">
                <Grid item xs={2}>
                    <WrapDiv>
                        <IconButton id={'up-button-' + props.position} className={classes.upDownIcon} onClick={() => props.handleUp(props.position)}>
                            <ArrowDropUp fontSize={'small'}/>
                        </IconButton>
                        <IconButton id={'dw-button-' + props.position} className={classes.upDownIcon} onClick={() => props.handleDown(props.position)}>
                            <ArrowDropDown fontSize={'small'}/>
                        </IconButton>
                    </WrapDiv>
                </Grid>

                <Grid item xs={8}>
                    <Card className={classes.palletListElement}>
                        <Typography variant="subtitle1">
                            <b>{props.pallet.barcode}</b> ({props.pallet.boxes_count}/{props.pallet.boxes_total})
                        </Typography>
                    </Card>
                </Grid>
                <Grid item xs={2}>
                    <IconButton onClick={() => props.handleRemove(props.position)}>
                        <RemoveButton color="secondary" />
                    </IconButton>
                </Grid>
            </Grid>
        </ListItem>
    );
});

export const EnqueuePalletsControl = withStyles(styles)(EnqueuePalletsControlBase);
